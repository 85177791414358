/**
 * Created by GSP on 2/02/2016.
 */
define('__component__$left-menu@default/views/left-menu-view',[ 'jquery',
         'underscore',
         '../views/filters-types',
         '../views/filters'], function($, _, FiltersTypesView, FiltersView) {
  'use strict';
  var LeftMenuView = {
    events: {
      'click #filters-apply-button' : 'applyFilters'
    },

    resizeMenu: function() {
      this.$el.addClass('col s2');
    },

    createFiltersTypesView: function(filters) {
      this.createChildView(FiltersTypesView, {
        el: this.$el.find('.filters-types-items'),
        filters: filters
      });
    },

    createFilterView: function(filters) {
      if (this.filtersView) this.filtersView.remove();
      this.filtersView = this.createChildView(FiltersView, {
        el: $('<div/>'),
        filters: filters,
        filtersType: this.filterType
      });
      this.$el.find('.filter-items').html(this.filtersView.render().el);
    },

    applyFilters: function() {
      if (this.filtersView) {
        this.filtersView.filtersApply();
      }
    },

    applyOptionsFiltersType: function(secId) {
      var filters = _.findWhere(this.allFilters, {secId: secId}),
        $menu = this.$el.find('.left-menu-filters');

      this.filterType = secId;
      this.component.sandbox.emit('applyOptionsFilters', null, secId);
      if (filters) {
        $menu.slideDown(300);
        this.createFilterView(filters.filters);
      } else {
        $menu.slideUp(300);
      }
    }
  };
  return LeftMenuView;
});
