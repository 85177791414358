/**
 * Created by GSP on 24/06/2015.
 */
define('services/caccount-service',[
    'jquery',
    'core/utils/settings'
], function ($, Settings) {
    'use strict';

    var cAccounts = {
      getSingle: function ( ctx ) {
        return $.ajax( {
          url: Settings.url( 'compuzz', 'v2/caccounts/current' ),
          contentType: 'application/json',
          context: ctx,
          success: function ( result ) {
            Settings.currentCAccount = result;
          },
          error: function () {
            console.log( 'Unable to get the caccount' );
          }
        } );
      }
    };

    return cAccounts;
});
