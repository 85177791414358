/**
 * Created by GSP on 17/02/2016.
 */
define('utils/date',[
  'module',
  'moment'
], function (module, moment) {
  'use strict';

  var DateConverter = {
    toMDYFormat: function (inputValue) {
      var format = new RegExp('^(0?[1-9]|[12][0-9]|3[01])/' +
                              '(0?[1-9]|1[012])/((19|20)\\d\\d)$'),
        dateArr,
        formattedDate;

      if (format.test(inputValue)) {
        dateArr = inputValue.split('/');
        formattedDate = dateArr[1] + '/' + dateArr[0] + '/' + dateArr[2];
      }

      return formattedDate ? formattedDate : undefined;
    },

    toLocaleFormat: function (inputValue) {
      var date = new Date(inputValue),
        localeDate = date.toLocaleString();

      return localeDate && localeDate !== 'Invalid Date' ?
             localeDate : undefined;
    },

    // dd/mm/yyyy
    toDateFormatString: function(inputValue) {
      var date = moment(inputValue).format('DD/MM/YYYY');

      return date;
    },

    toMonthDateFormat: function(inputValue) {
      var date = new Date(inputValue),
        getMonth = date.getMonth() + 1,
        day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
        month = getMonth < 10 ? '0' + getMonth : getMonth;

      return day + '/' + month + '/' + date.getFullYear();
    },

    // hh:mm
    toTimeFormatString: function(inputValue) {
      var date = moment(inputValue).format('HH:mm');

      return date;
    },

    getDateFormat: function () {
      var dateFormat = 'dd/mm/yy';

      return dateFormat;
    },

    toMillisecondsFormat: function(inputValue) {
      var date = new Date(this.toMDYFormat(inputValue));
      return date.getTime() + '';
    },

    toHHMM: function(time, format) {
      var timeJson = format === 'seconds' ? moment.duration(time, 'seconds').toJSON() : moment.duration(time).toJSON(),
        pt = timeJson.split('PT'),
        ptH, ptM, h, m;

      if (timeJson.indexOf('H') !== -1) {
        ptH = pt[1].split('H');
        h = ptH[0];
        if (timeJson.indexOf('M') !== -1) {
          ptM = ptH[1].split('M');
          m = ptM[0];
          m = (m < 10) ? '0' + m : m;
        } else {
          m = '00';
        }
      } else {
        h = '0';
        if (timeJson.indexOf('M') !== -1) {
          ptM = pt[1].split('M');
          m = ptM[0];
          m = (m < 10) ? '0' + m : m;
        } else {
          m = '00';
        }
      }
      return h + ':' + m;
    },

    getDayName: function(value) {
      var dayNumber = new Date(value).getDay();

      return dayNumber;
    }
  };

  return DateConverter;
});

