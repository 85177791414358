define('__component__$paging@default/main',[
    'mixins/component',
    'module',
    'underscore',
    'jquery',
    'core/utils/settings',
    './views/paging-view'
], function(componentMixin, module, _, $, Settings, PagingView) {
    'use strict';

    var componentConfig, res;

    componentConfig = {
        pagination: {
            currentPage: 1,
            rowCount: 0,
            pageSize: Settings.get('pageSize')
        },

        templates: 'tpl',
        View: PagingView,

        initialize: function() {
            this.invokeWithCallbacks('render');
            this.sandbox.helpers.loadCssForModule(module);
            this.configure();
            this.setListenerForChangePage();

            this.mediator.on('paging:reset-pages',  _.bind(this.setListenerForChangePage, this));
        },

        setListenerForChangePage: function () {
            this.pagination.currentPage = 1;
        },

        configure: function() {
          var config = this.options.configuration;
            if (config) {
              this.mediator = config.mediator || this.mediator;
              this.pagination.pageSize = config.pageSize || 12;
            }
            this.listenForMediatorEvents();
            this.mediator.emit('paging:page-changed', 1);
        },

        listenForMediatorEvents: function() {
            this.mediator.on('paging:row-count-changed', _.bind(this.onRowCountChanged, this));
        },

        render: function() {
            this.$el.html(this.renderTemplate('tpl'));

            var pageContainer = this.$el.find('.pages');
            pageContainer.append('<a class="more waves-effect waves-teal btn-flat vt-align" data-page="more">'+ _.i18n('common.more') + '</a>');
        },

        onRowCountChanged: function (rowCount) {
            this.pagination.rowCount = rowCount;
            if(rowCount !== 0) {
              this.view.$el.show();
              this.view.updatePages();
            } else {
              this.view.$el.hide();
            }
        }
    };

    res = _.extend({}, componentMixin);
    res = _.extend(res, componentConfig);

    return res;
});
define('__component__$paging@default', ['__component__$paging@default/main'], function (main) { return main; });

