window.App = {};

requirejs.config({
  paths: {
    'config':                     'config',
    'core':                       'core',
    'base':                       'core/base',
    'mixins':                     'core/mixins',
    'settings':                   'core/utils/settings',
    'extensions':                 'core/extensions',
    'services':                   'components/services',
    'utils':                      'components/utils',
    'views':                      'components/views',
    'models':                     'components/models',
    'collections':                'components/collections',
    'bowser':                     'bower_components/bowser/bowser',
    'polyglot':                   'bower_components/polyglot/build/polyglot',
    'jquery':                     'bower_components/jquery/jquery',
    'jqueryUI':                   'bower_components/jquery-ui/jquery-ui',
    'jquery-ui':                  'bower_components/jquery-ui/ui',
    'jqgrid':                     'bower_components/jqgrid/js',
    'jqgrid-plugins':             'bower_components/jqgrid/plugins',
    'jqgrid-grid.postext':        'bower_components/jqgrid/plugins/grid.postext',
    'jquerycookie':               'bower_components/jquery-cookie/jquery.cookie',
    'jquery-validation':          'bower_components/jquery-validation/dist/jquery.validate',
    'slick':                      'bower_components/slick.js/slick/slick',
    'text':                       'bower_components/requirejs-text/text',
    'backbone':                   'bower_components/backbone/backbone',
    'underscore':                 'bower_components/underscore/underscore-min',
    'bootbox':                    'bower_components/bootbox/bootbox',
    'bootstrap':                  'bower_components/bootstrap/dist/js/bootstrap.min',
    'eventemitter':               'bower_components/eventemitter2/lib/eventemitter2',
    'materialize-amdified':       'bower_components/materialize-amdified/materialize.amd',
    'velocity':                   'bower_components/Materialize/js/velocity.min',
    'waves':                      'bower_components/Materialize/js/waves',
    'jquery.hammer':              'bower_components/Materialize/js/jquery.hammer',
    'hammerjs':                   'bower_components/Materialize/js/hammer.min',
    'moment' :                    'bower_components/moment/min/moment-with-locales',
    'waitForImages':              'bower_components/waitForImages/dist/jquery.waitforimages',
    'smooth-scroll':              'bower_components/smooth-scroll/dist/js/smooth-scroll',
    'jqueryCompuzzGallery':       'vendors/compuzz-gallery-plugin/dist/script',
    'photoSwipeGallery':          'vendors/photoswipe/dist/photoswipe.min',
    'photoSwipeUI':               'vendors/photoswipe/dist/photoswipe-ui-default.min',
    'jqueryCompulogoPlugin':      'vendors/compuzz-compulogo-plugin/js/jquery.compulogo',
    'jqueryMagnificPopup':        'vendors/compuzz-compulogo-plugin/js/jquery.magnific-popup',
    'spin':                       'vendors/compuzz-compulogo-plugin/js/spin',
    'jqueryCompuzzGalleryBoot':   'vendors/compuzz-gallery-plugin/scripts/gallery-pluggin',
    'jqgridBoot':                 'vendors/jqgrid-boot/jqgrid-boot',
    'jqgrid-w-addons':            'vendors/jqgrid-boot/jqgrid-w-addons'
  },
  shim:   {
    'jquery':       { exports: '$' },
    'jqueryUI':     { deps:['jquery']},

    'jqueryCompulogoPlugin': { deps:['jquery']},
    'jqueryCompuzzGalleryBoot': {deps:['jqueryUI']},

    'jqgrid/grid.base': { deps: [ 'jqueryUI']},
    'jqgrid/grid.celledit': { deps: [ 'jqueryUI']},
    'jqgrid/grid.common': { deps: [ 'jqueryUI']},
    'jqgrid/grid.filter': { deps: [ 'jqueryUI']},
    'jqgrid/grid.formedit': { deps: [ 'jqueryUI']},
    'jqgrid/grid.grouping': { deps: [ 'jqueryUI']},
    'jqgrid/grid.import': { deps: [ 'jqueryUI']},
    'jqgrid/grid.inlinedit': { deps: [ 'jqueryUI']},
    'jqgrid/grid.jqueryui': { deps: [ 'jqueryUI']},
    'jqgrid/grid.pivot': { deps: [ 'jqueryUI']},
    'jqgrid/grid.subgrid': { deps: [ 'jqueryUI']},
    'jqgrid/grid.treegrid': { deps: [ 'jqueryUI']},
    'jqgrid/jqDnR': { deps: [ 'jqueryUI']},
    'jqgrid/jqModal': { deps: [ 'jqueryUI']},
    'jqgrid/jquery.fmatter': { deps: [ 'jqueryUI']},
    'jqgrid/grid.utils': { deps: [ 'jqueryUI']},
    'jqgrid/addons/ui.multiselect': { deps: [ 'jqueryUI']},
    'jqgridBoot':   { deps: [ 'jqueryUI']},
    'jqgrid-grid.postext':{ deps: [ 'jqgridBoot']},
    'jqgrid-w-addons': { deps: [ 'jqgridBoot']},

    'jquerycookie': { exports: 'jquerycookie', deps: ['jquery'] },
    'jquery-validation': {deps: ['jquery'] },
    'underscore':   { exports : '_' },
    'velocity':     { deps: ['jquery'] },
    'bootstrap':    { deps: ['jquery','jqueryUI'] },
    'materialize-amdified': { deps: ['jquery', 'velocity', 'waves', 'hammerjs', 'jquery.hammer', 'bootstrap', 'jquery-validation', 'smooth-scroll'] },
    'backbone':     { exports: 'Backbone', deps: ['underscore', 'jquery'] },
    'bootbox':      { exports: 'Bootbox', deps: ['underscore', 'jquery'] },

    'polyglot':     { exports: 'Polyglot'},
    'spin':         { exports: 'Spinner'},
    'slick':        { exports: 'slick', deps: ['jquery']},
    'waitForImages': { deps: ['jquery'] }
  },

  packages: [{
               name: '__component__$viewport@default',
               location: 'components/ui/viewport'
             },{
                  name: '__component__$welcome-area@default',
                  location: 'components/ui/welcome-area'
              }, {
               name: '__component__$left-menu@default',
               location: 'components/ui/left-menu'
             },{
               name: '__component__$login-page@default',
               location: 'components/ui/login-page'
             },
              {
                  name: '__component__$register-page@default',
                  location: 'components/ui/register-page'
              },
              {
               name: '__component__$personalize-page@default',
               location: 'components/ui/personalize-page'
             },  {
               name: '__component__$catalogues-page@default',
               location: 'components/ui/catalogues-page'
             },  {
               name: '__component__$delivery-page@default',
               location: 'components/ui/delivery-page'
             },{
                  name: '__component__$cart-success-page@default',
                  location: 'components/ui/cart-success-page'
              }, {
               name: '__component__$orderprocess-page@default',
               location: 'components/ui/orderprocess-page'
             }, {
               name: '__component__$cart-page@default',
               location: 'components/ui/cart-page'
             },{
               name: '__component__$orders-page@default',
               location: 'components/ui/orders-page'
             }, {
               name: '__component__$personalize-table@default',
               location: 'components/ui/personalize-table'
             },{
               name: '__component__$paging@default',
               location: 'components/ui/paging'
             }, {
               name: '__component__$left-menu@default',
               location: 'components/ui/left-menu'
             }]
});

require([
  // packages deps
  'bootstrap',
  'backbone',
  'materialize-amdified',
  // packages
  '__component__$viewport@default',
  '__component__$welcome-area@default',
  '__component__$left-menu@default',
  '__component__$personalize-page@default',
  '__component__$catalogues-page@default',
  '__component__$delivery-page@default',
  '__component__$cart-success-page@default',
  '__component__$orderprocess-page@default',
  '__component__$orders-page@default',
  '__component__$cart-page@default',
  '__component__$personalize-table@default',
  '__component__$login-page@default',
  '__component__$register-page@default',
  '__component__$paging@default',
  '__component__$left-menu@default'
], function () {
  require([
    'bower_components/aura/lib/aura'
  ], function(Aura) {
    Aura({
      sources: { 'default' : 'components/ui' },
      template: { 'components' : 'components/ui'},
      debug: { enable: false }
    }).use('extensions/aura-backbones')
      .use('extensions/aura-templates')
      .use('extensions/aura-components')
      .use('extensions/helpers')
      .use('extensions/router')
      .start();
  });
});

define("app", function(){});

