/**
 * Created by GSP on 29/01/2016.
 */
define('__component__$orders-page@default/views/orders-view',['backbone',
        'underscore',
        'jquery',
        'utils/form',
        'slick',
        'waitForImages'], function(Backbone, _, $, FormUtils){
  'use strict';
  var OrdersView = {
    events: {
      'click .request-b': 'showContactForm',
      'click .back-to-prev-b': 'backToPrev',
      'submit .contact__button-submit': 'onContactRequest',
      'click .change-params-b': 'onEdit',
      'click .image-item': 'selectImageToPreview'
    },

    initGallery: function () {
      this.$el.find( '.slick-gallery' ).on( 'beforeChange', _.bind(function ( event, slick, currentSlide, nextSlide ) {
        this.$el.find( 'span.fa-circle' ).removeClass( 'active' );
        this.$el.find( 'span.fa[data-index="' + nextSlide + '"]' ).addClass( 'active' );
      }, this));
      this.checkOnLoadImages();
      this.preselectSlickImage();
    },

    preselectSlickImage: function () {
      var slickIndex = this.$el.find( '.img-container.slick-active' ).data( 'slick-index' );

      this.$el.find( '.img-container-smaller[data-slick-index="' + slickIndex + '"]' ).click();
    },


    checkOnLoadImages: function () {
      this.$('.lg-slick-img').waitForImages(true).done(_.bind(function() {
        this.$el.find( '.overview-loader' ).hide();
        this.$el.find( '.slick-gallery' ).slick( {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
          easing: 'linear',
          focusOnSelect: true,
          autoplay: false,
          asNavFor: '.slick-menu',
          arrows: true,
          prevArrow: '<button type="button" class="prev-arrovs hide-on-small-and-down navigate-b" aria-label="previous"><i class="material-icons">chevron_left</i></button>',
          nextArrow: '<button type="button" class="next-arrovs hide-on-small-and-down navigate-b" aria-label="next"><i class="material-icons">chevron_right</i></button>'
        } ).removeClass('hide');
        this.$el.find( '.slick-menu' ).slick( {
          slidesToShow: 4,
          slidesToScroll: 1,
          asNavFor: '.slick-gallery',
          focusOnSelect: true,
          variableWidth: true,
          arrows: false
        } ).removeClass('hide');
      }, this));
    },

    selectImageToPreview: function ( e ) {
      var index = $( e.currentTarget ).attr( 'data-index' );

      this.$el.find( 'span.fa-circle' ).removeClass( 'active' );
      this.$el.find( 'span.fa[data-index="' + index + '"]' ).addClass( 'active' );
    },

    showContactForm: function () {
      this.$el.find( '.btns-container' ).hide();
      this.$el.find( '.change-params-b' ).hide();
      this.$el.find( '.gallery-btns-container' ).fadeOut(200);
      this.$el.find( '.preview-container' ).fadeOut(200);
      this.$el.find( '.product-info-container' ).fadeOut(200, _.bind(function(){
        this.$el.find( '.contact-form-container' ).removeClass('hide').fadeIn( 200 );
      }, this));
      this.formValidator();
    },

    backToPrev: function () {
      this.$el.find( '.contact-form-container' ).addClass('hide').fadeOut( 200, _.bind(function () {
        this.$el.find( '.preview-container' ).fadeIn( 200 );
        this.$el.find( '.product-info-container' ).fadeIn( 200 );
        this.$el.find( '.gallery-btns-container' ).show();
        this.$el.find( '.change-params-b' ).show();
        this.$el.find( '.btns-container' ).show();
      }, this));
    },

    /*jshint camelcase: false */
    formValidator: function() {
      var self = this;
      this.$('#contact__form').validate({
        submitHandler: function(){
          var formObj = FormUtils.getObj('contact__form', self.$el);
          self.$el.find('.contact__button-submit').addClass('disabled');
          self.sendContactFormData( '<strong>' + formObj.first_name + ' ' + formObj.last_name + ': ' + formObj.phone +  '</strong>,' + '<br/>' + formObj['textarea-data'], formObj.email );
        },
        rules: {
          first_name: {
            required: true,
            maxlength: 250
          },
          last_name: {
            required: true,
            maxlength: 250
          },
          email: {
            regex:/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            required: true,
            maxlength: 250
          },
          phone: {
            regex:/^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i,
            maxlength: 250
          },
          'textarea-data':{
            required: true,
            maxlength: 250
          }
        },
        messages: {
          first_name: {
            required:  _.i18n( 'common.value.required' ),
            maxlength: _.i18n( 'common.value.too.long' )
          },
          last_name: {
            required: _.i18n( 'common.value.required' ),
            maxlength: _.i18n( 'common.value.too.long' )
          },
          email: {
            required: _.i18n( 'common.value.required' ),
            maxlength: _.i18n( 'common.value.too.long' )
          },
          phone: {
            maxlength: _.i18n( 'common.value.too.long' )
          },
          'textarea-data':{
            required: _.i18n( 'common.value.required' ),
            maxlength: _.i18n( 'common.value.too.long' )
          }
        }
      });
    },

    onContactRequest: function (e) {
      e.preventDefault();
    },

    sendContactFormData: function ( message, to ) {
      this.component.sendContactForm( message, to );
    },

    onEdit: function () {
      this.component.goToOrderProcessPage();
    },

    showSuccessEmailSendModal: function () {
      var self = this,
        $modal = $( '#modal__thanks-for-request' );
      self.$el.find('.contact__button-submit').removeClass('disabled');
      $modal.html( this.component.renderTemplate( 'templates/success-modal-tpl' ) );
      $modal.openModal({
        dismissible: true,
        opacity: 0.5,
        in_duration: 300,
        out_duration: 200,
        ready: function() {
          $modal.find('.modal-send-mail-close' ).off('click' ).on('click', function() {
            self.onSendMailClose();
          });
        }
      });
    },

    onSendMailClose: function () {
      $( '#modal__thanks-for-request').closeModal();
      this.component.goToHomePage();
    }
  };

  return OrdersView;
});
