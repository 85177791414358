/**
 * Created by GSP on 17/02/2016.
 */
define('services/common-service',[
  'jquery',
  'underscore',
  'core/utils/settings'
], function(
  $,
  _,
  Settings
) {
  'use strict';

  var CommonService = {
    getRowCount: function(urlParams) {
      var params = {};

      _.each(urlParams.filters, function (item, key) {
        if (item) {
          params[key] = item;
        }
      });
      return $.ajax({
        url: Settings.url('compuzz', urlParams.rowCountUrl, params)
      });
    }
  };

  return CommonService;
});
