define('__component__$login-page@default/main',[
    'mixins/page-component',
    'backbone',
    'module',
    'underscore',
    './views/login-view',
    'jquery',
    'core/utils/settings',
    'services/session-service',
    'services/caccount-service',
    'services/end-users-service',
    'core/utils/i18n',
    'utils/color'
], function (componentMixin, Backbone, module, _, LoginView, $, Settings, SessionService,CAccountService, EndUsersService,i18n, ColorUtil) {
    'use strict';


    var componentConfig, res;

    componentConfig = {
        pageRoutePath: {
            'login': 'onShowLogin'
        },
        singleView: true,
        module: module,
        templates: 'tpl',
        View: LoginView,

        onShowLogin : function(){
          this.sandbox.helpers.loadCssForModule(this.module);
          this.invokeWithCallbacks('render');
          this.view.formValidator();
          this.checkPasswordFieldFilled();
        },

        checkPasswordFieldFilled: function() {
            //materialize password label doesn't float because field is secure
            var form = this.$el.find('#login-form'),
                label = form.find('label[for="password"]');

            setTimeout(function(){
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf('MSIE ');
                if (form.find('#password').val().length > 0) {
                    label.addClass('active');
                //for chrome
                } else if(msie <= 0 && form.find('#password:-webkit-autofill').length > 0){
                    label.addClass('active');
                }
            }, 100);
        },

        processLogin : function(username, password){
            var data = {username : username, password : password};
            return SessionService.createSessionByCAccountEnduser(Settings.get('cAccountCode'), Settings.get('endUserCode'), this, data)
                .done(function () {
                    //create token from the session
                    SessionService.createBySessionId(Settings.get('sessionId'), this)
                        .done(function(){
                        SessionService.changeLanguage(Settings.get('lang'), this ).done(function() {
                        } ).done(function(){
                          //Retrieve the endUser
                          EndUsersService.getByTokenId(this)
                            .then(function(result) {
                              //Backup the endUser
                              Settings.set('endUser', result);
                              //get the cAccount info
                              CAccountService.getSingle(this ).done(function(result){
                                Settings.set('currentCAccount', result);
                                //Login success, continue the rendering
                                i18n.getApplicationTranslations(true).success( _.bind(this.onAutoLoginSuccess, this));
                              });
                            })
                            .fail(function(xhr, err, msg) {
                              this.sandbox.logger.log(msg);
                            });
                        } ).fail(function(xhr, err, msg) {
                          this.sandbox.logger.log(msg);
                        });
                    } ).fail(function(xhr, err, msg){
                        this.sandbox.logger.log('Error while creating the session', msg);
                    });
                });

        },

        onAutoLoginSuccess : function() {
            ColorUtil.applyUserTheme();
            this.sandbox.emit('viewport:update:header');
            this.sandbox.router.navigate( Settings.get('originalURL') || 'catalogues', {trigger: true, replace: false});
        }

    };

    res = _.extend({}, componentMixin);
    res = _.extend(res, componentConfig);

    return res;
});
define('__component__$login-page@default', ['__component__$login-page@default/main'], function (main) { return main; });

