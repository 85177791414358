define('models/product-item',[
  'underscore',
  'backbone',
  'core/utils/settings'
], function (_, Backbone, Settings) {
  'use strict';

  var ProductItem = Backbone.Model.extend({
    idAttribute: 'secId',

    sync : function(method, model, options) {
      options = options || {};

      switch (method) {
        case 'create':
          options.url = Settings.url('compuzz','products.json');
          options.type = 'POST';
          break;

        case 'update':
          options.url = Settings.url('compuzz','products.json');
          options.type = 'PUT';
          break;

        case 'delete':
          options.url = Settings.url('compuzz','products.json/' + this.attributes.secId);
          options.type = 'DELETE';
          break;

        case 'read':
          if (this.attributes.secId) {
            options.url = Settings.url('compuzz','products.json/' + this.attributes.secId);
          } else {
            options.url =  Settings.url('compuzz','products.json/' + this.attributes.code );
          }
          options.type = 'GET';
          break;
      }


      Backbone.sync(method, model, options);
    }
  });
  return ProductItem;
});


