define('models/product-row-count',[
  'backbone',
  'underscore',
  'core/utils/settings'
], function (Backbone, _, Settings) {
  'use strict';

  var ProductRowCountModel = Backbone.Model.extend({
    url: function (urlParams) {
      var productMarker = urlParams.filters.productMarker || urlParams.filters.productCode,
        language = urlParams.filters.language,
        catalogueSecId = urlParams.filters.catalogueSecId,
        loadProductCodeVariation = urlParams.loadProductCodeVariation,
        filtersQuery = this.getFiltersQuery(urlParams);

      return Settings.url('compuzz', 'products.json/advancedSearch/rowCount', {
          globalSearchField: productMarker,
          loadProductCodeVariation: loadProductCodeVariation,
          lang: language || Settings.get('lang'),
          status: 0,
          catalogSecId: catalogueSecId}) + filtersQuery;
    },
    //left menu options filters
    getFiltersQuery: function(urlParams) {
      var query = '',
        optionsFilters = urlParams.optionsFilters,
        filtersType = urlParams.filtersType;

      //options filters query
      if (typeof optionsFilters === 'object') {
        _.each(optionsFilters, function(filter){
          query += '&lstProductFiltersIds=' + filter.join(',');
        });
      }

      //filters type query
      if (filtersType) {
        query += '&productFilterTypeSecId=' + filtersType;
      }

      return query;
    }
    

  });

  return ProductRowCountModel;
});
