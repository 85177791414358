define('__component__$left-menu@default/views/filters-types',[
    'backbone',
    'underscore',
    'jquery',
    'core/utils/settings',
    'text!../templates/filters-types.html'
], function(Backbone, _, $, settings, template) {
    'use strict';

    var that,
    	FiltersView = Backbone.View.extend({
	    	template: _.template(template),
	    	events: {
	    		'click .single-filter-type': 'selectFilterType'
	    	},
	    	initialize: function (options) {
	    		that = this;
	    		this.options = options;

	    		this.render();
	    	},

	    	render: function() {
	    		var templateData = {
	    			filters: this.options.filters
	    		};

                if (this.options.filters.length === 1) {
                    this.options.parent.sandbox.emit('applyOptionsFiltersType', this.options.filters[0].secId);
                } else {
                    this.$el.html(this.template(templateData));
                }
	    	},

	    	selectFilterType: function(e) {
	    		var target = $(e.currentTarget),
	    			name = target.attr('name'),
	    			secId = target.data('secid'),
	    			$selected = this.$('.selecter-filter-type'),
	    			current = $selected.text();

	    		if (current === name) return;
	    		$selected.html(name);
	    		secId = secId === 'all' ? '' : secId; 
	    		this.options.parent.sandbox.emit('applyOptionsFiltersType', secId);
	    	}

	    });

    return FiltersView;
});
