define('__component__$catalogues-page@default/main',[
  'mixins/page-component',
  'module',
  'jquery',
  'underscore'
], function (pageComponentMixin, module, $, _) {
  'use strict';

  var componentConfig, res;

  componentConfig = {
    pageRoutePath: {
      '': 'personalizeCatalogue1',
      'catalogues(/:catalogueSecId)': 'personalizeCatalogue'
    },

    module: module,
    templates: 'tpl',

    personalizeCatalogue1: function () {
      this.setConfig({catalogueSecId: ''});
      this.invokeWithCallbacks('render');
    },

    personalizeCatalogue: function ( catalogueSecId ) {
      this.setConfig({catalogueSecId: catalogueSecId || ''});
      this.invokeWithCallbacks('render');
    }
  };

  res = _.extend({}, pageComponentMixin);
  res = _.extend(res, componentConfig);

  return res;
});
define('__component__$catalogues-page@default', ['__component__$catalogues-page@default/main'], function (main) { return main; });

