/**
 * Created by GSP on 25/02/2016.
 */
define('__component__$cart-page@default/views/cart-view-table',['jquery',
        'underscore',
        'views/grid-view',
        'core/utils/settings',
        'utils/date',
        'utils/price'], function($, _, GridView, Settings, DateUtils, PriceUtils) {
  'use strict';
  var OrderViewTable =  GridView.extend({

    events: {
      'click .order.delete-link .cell-delete': 'onDeleteOrder',
      'click .order.edit-link .cell-edit': 'onEditOrder'
    },

    initialize: function(options) {
      GridView.prototype.initialize.apply(this, arguments);
      this.parent = options.parent;
      this.render();
    },

    render: function() {
      this.$el.html(this.parent.component.renderTemplate('templates/order-view-table-tpl'));
      var services = this.parent.component.getServices();
      this.renderGrid( {
        url: services.cartService.getCartCollectionUrl(),
        rowCountUrl: services.cartService.getCartCountUrl(),
        rowCountRequest: services.cartService.getRowCount,
        priceRequest: services.pricesService.getUnitPricePerOrder,
        responsive: true,
        dataProcessing: {
          filtering: false,
          sorting: false
        }});
    },

    onRowCountDone: function(data, rowCount) {
      if (rowCount > 0) {
        GridView.prototype.onRowCountDone.apply(this, [data, rowCount]);
      }
      this.trigger('countReceived', rowCount);
    },

    gridOptions: function ( data ) {
      var that = this,
        defaultsOptions = this.gridInitOptions( data ),
        options = {
            colModel: [
            {
              label: ' ',
              name: 'snapshotsUids',
              align: 'left',
              classes: 'order-link image-cell catalog-product-pic',
              formatter: this.pictureUidFormatterTime,
              search: false,
              width: 60
            }, {
              label: _.i18n( 'orderList.orderNumber' ),
              name: 'purchaseOrderId',
              align: 'left',
              classes: 'order-link',
              formatter: this.defaultFormatter,
              search: false,
              index: 'purchaseOrderId',
              key: true,
              width: 140
            }, {
              label: _.i18n( 'orderList.orderModDate' ),
              name: 'modDate',
              align: 'left',
              classes: 'order-link',
              formatter: this.dateFormatter,
              search: false,
              index: 'modDate',
              width: 140
            }, {
              label: _.i18n( 'orderList.productCode' ),
              name: 'productCode',
              align: 'left',
              classes: 'order-link',
              formatter: this.defaultFormatter,
              search: false,
              index: 'productCode',
              width: 100
            }, {
              label: _.i18n( 'orderList.productName' ),
              name: 'productName',
              align: 'left',
              classes: 'order-link',
              formatter: this.defaultFormatter,
              search: false,
              index: 'productName',
              width: 250
            }, {
              label: ' ',
              name: 'status',
              align: 'left',
              classes: 'order-link',
              formatter: _.bind( this.statusFormatter, this),
              search: false,
              index: 'status',
              width: 70
            }, {
              label: _.i18n( 'orderList.quantity' ),
              name: 'quantity',
              align: 'right',
              classes: 'order-link',
              formatter: this.defaultFormatter,
              search: false,
              index: 'quantity',
              width: 70
            }
          ],

          serializeGridData: function ( postData ) {
            var date;

            if ( postData.status === that.selectDefault ) {
              delete postData.status;
            }

            if ( postData.modDate ) {
              date = new Date( DateUtils.toMDYFormat( postData.modDate ) );
              postData.dateFrom = date.getTime();
              postData.dateTo = Date.now();
            } else if ( !that.sorting ) {
              delete postData.dateFrom;
              delete postData.dateTo;
            }
            that.sorting = false;
            defaultsOptions.serializeGridData.apply( this, arguments );

            return postData;
          }
        };


      var token = Settings.get('token');
      if (token.configuration.displayPrice !== false ) {
        options.colModel.push({
          label: _.i18n( 'orderList.pricePerUnit' ),
          name: 'pricePerUnit',
          align: 'right',
          classes: 'order-link',
          formatter: _.bind( this.pricePerUnitFormatter, this ),
          search: false,
          index: 'pricePerUnit',
          width: 70
        });
        options.colModel.push({
          label: _.i18n( 'orderList.price' ),
          name: 'price',
          align: 'right',
          classes: 'order-link',
          formatter: _.bind( this.priceFormatter, this ),
          search: false,
          index: 'price',
          width: 70
        });
      }

      options.colModel.push({
        label: ' ',
        name: 'edit',
        align: 'right',
        classes: 'order edit-link',
        formatter: this.editIconFormatter,
        search: false,
        width: 30
      });
      options.colModel.push({
        label: ' ',
        name: 'delete',
        align: 'right',
        classes: 'order delete-link',
        formatter: this.deleteIconFormatter,
        search: false,
        width: 30
      });

      options = _.defaults( options, defaultsOptions );

      return options;
    },

    deleteIconFormatter: function(cellValue, options, model) {
      if (model.status === 'ORDERLINE_BOOKED') {
        return '';
      } else {
        return '<span class="cell-delete js-icon" ' +
            'data-row-id="' + options.rowId + '"><i class="material-icons">delete</i></span>';
      }
    },

    statusFormatter: function(cellValue, options) {
      if (cellValue === 'ORDERLINE_BOOKED') {
        return '<span class="cell-default" data-row-id="' +
            options.rowId + '">' + _.i18n( 'orderList.booked' ) + '</span>';
      } else {
        this.parent.component.displayBookInCart();
        return '';
      }
    },

    onGridFilterChange: function () {
      var that = this,
        filters = {},
        value, date;

      _.each( this.colModel, function ( column ) {
        if ( column.search ) {
          if ( column.stype === 'select' ) {
            value = that.$( 'select[name="' + column.index + '"]' ).val();
            filters[column.index] = value === that.selectDefault ? '' : value;
          } else if ( column.sorttype === 'date' ) {
            value = that.$( 'input[name="' + column.index + '"]' ).val();
            date = new Date( DateUtils.toMDYFormat( value ) );
            filters.dateFrom = date.getTime();
            filters.dateTo = Date.now();
          } else {
            value = that.$( 'input[name="' + column.index + '"]' ).val();
            filters[column.index] = value;
          }
        }
      } );

      this.filters = filters;
      this.filterChange = true;
      this.updatePagination();
    },

    onDeleteOrder: function ( e ) {
      var rowId = $( e.currentTarget ).attr( 'data-row-id' );
      this.parent.component.deleteOrder( { purchaseOrderId: rowId } );
    },

    onEditOrder: function ( e ) {
      var rowId = $( e.currentTarget ).attr( 'data-row-id' );
      this.parent.component.gotToOrderDetail( { purchaseOrderId: rowId } );
    },

    priceFormatter: function ( cellValue, options ) {
      this.data.priceRequest( [options.rowId], this ).done( function ( result ) {
        if ( !_.isEmpty( result ) ) {
          var priceObj = result[0];
          cellValue = priceObj.productPriceHT;
          cellValue = PriceUtils.toFixedPrice( cellValue, 2 ).toString().split( '.' );
          cellValue += ' ' + PriceUtils.getCurrencySymbol( Settings.get('currencyCode') );
          this.$( '.cell-price-default[data-row-id="' + options.rowId + '"]' ).html( cellValue );
        }
      } ).fail( function ( jqXHR, textStatus, errorThrown ) {
        this.parent.component.sandbox.logger.log( errorThrown );
        cellValue = _.i18n( 'common.no.price' );
        this.$( '.cell-price-default[data-row-id="' + options.rowId + '"]' ).html( cellValue );
      } );

      return this.loaderFormatter( 'cell-price-default', options );
    },

    pricePerUnitFormatter: function ( cellValue, options ) {
      this.data.priceRequest( [options.rowId], this ).done( function ( result ) {
        if ( !_.isEmpty( result ) ) {
          var priceObj = result[0];
          cellValue = priceObj.productPriceHT / priceObj.productQuantity;
          cellValue = PriceUtils.toFixedPrice( cellValue, 2 ).toString().split( '.' );
          cellValue += ' ' + PriceUtils.getCurrencySymbol(  Settings.get('currencyCode')  );
          this.$( '.cell-pricePerUnit-default[data-row-id="' + options.rowId + '"]' ).html( cellValue );
        }
      } ).fail( function ( jqXHR, textStatus, errorThrown ) {
        this.parent.component.sandbox.logger.log( errorThrown );
        cellValue = _.i18n( 'common.no.price' );
        this.$( '.cell-pricePerUnit-default[data-row-id="' + options.rowId + '"]' ).html( cellValue );
      } );
      return this.loaderFormatter( 'cell-pricePerUnit-default', options );
    },

    dateFormatter: function ( cellValue ) {
      var date = DateUtils.toDateFormatString( cellValue ),
        time = DateUtils.toTimeFormatString( cellValue );

      return '<span class="cell-date">' + date + ' ' + time + '</span>';
    }
  });

  return OrderViewTable;
});
