define('config/env',['module'
], function (module) {
  'use strict';
  
  var Env = {
    config: {
      defaults: {
        filesPath: 'fileManagement/',
        serverUrl: 'http://localhost:8084/'
      },
      development: {
        filesPath: 'fileManagement/',
        serverUrl: 'http://localhost:8084/'
      },
      minified: {
        filesPath: 'fileManagement/',
        serverUrl: 'http://localhost:8084/'
      },
      alpha: {
        filesPath: 'files/',
        serverUrl: 'http://alpha.compuzz.com/'
      },
      beta: {
        filesPath: 'files/',
        serverUrl: 'http://beta.compuzz.com/'
      },
      optv2: {
        filesPath: 'files/',
        serverUrl: 'http://optv2.compuzz.com/'
      }
    }
  };
  module.exports = Env;
});

