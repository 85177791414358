define('__component__$left-menu@default/views/filters',[
  'backbone',
  'underscore',
  'jquery',
  'core/utils/settings',
  './single-filter',
  'text!../templates/filters.html'
], function(Backbone, _, $, settings, SingleFilterView, template) {
  'use strict';

    var FiltersView = Backbone.View.extend({
      template: _.template(template),

      initialize: function (options) {
        this.options = options;
        this.parent = options.parent;
      },

      render: function() {
        var templateData = {
          filters: this.options.filters
        };

        this.$el.html(this.template(templateData));
        this.createSeparatedFilters();

        return this;
      },

      createSeparatedFilters: function(){
        var filters = this.options.filters,
        singleFilter;

        this.selectedFilters = {};

        _.each(filters, function(filter) {
          this.selectedFilters[filter.productFilterId] = [];
          singleFilter =  this.createChildView(SingleFilterView, {
            el: this.$el.find('.single-filter-' + filter.productFilterId),
            sandbox: this.parent.component.sandbox,
            filter: filter,
            personal: this.selectedFilters[filter.productFilterId]
          });
          setTimeout( _.bind(function(){
            this.closeFilterByOffset(singleFilter, filter);
          }, this), 0);
        }, this);
      },

      closeFilterByOffset: function(singleFilter, filter) {
        var offsetToClose = 600,
          top = singleFilter.$el.offset().top,
          filterId = filter.productFilterId,
          accordion = this.$el.find('.accordion-toggle-' + filterId);
          singleFilter = this.$el.find('.single-filter-' + filterId);

        if (top >= offsetToClose) {
          accordion.addClass('collapsed');
          singleFilter.removeClass('in');
        }
        if (filter.productFilterValues.length && filter.productFilterValues[0].color > -1) {
          singleFilter.addClass('color-filter');
        }
      },

      // gets selected options filters and emits notification
      filtersApply: function () {
        var selectedFilters = _.filter(this.selectedFilters, function(filter) {
          return filter.length;
        });
        this.parent.sandbox.emit('applyOptionsFilters', selectedFilters, this.options.filtersType);
      }

    });

  return FiltersView;
});
