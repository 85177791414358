define('__component__$viewport@default/main',[
  'module',
  'underscore',
  'jquery',
  'core/utils/settings',
  'core/utils/i18n',
  './views/viewport-view',
  'services/session-service',
  'services/caccount-service',
  'services/end-users-service',
  'models/enduser-item',
  'utils/url',
  'utils/color'
], function (module, _, $, Settings, i18n, ViewportView, SessionService, CAccountService, EndUsersService, EndUserItem, UrlUtil, ColorUtil) {
  'use strict';
  return {
    templates: ['tpl', 'templates/header-tpl', 'templates/footer-tpl', 'templates/change-pwd-modal-tpl'],

    View: ViewportView,

    initialize: function () {
      $(document).ajaxError(_.bind(function (e, xhr) {
        if (xhr.status === 401) {
          Settings.set('token', null, { type: 'cookie' });
          if (this.isSecureMode()) {
            this.sandbox.router.navigate('login', { trigger: true });
          } else {
            this.reloadApp();
          }
        }
      }, this));
      this.sandbox.on('viewport:update:header', _.bind(this.view.updateHeaderData, this.view));
      this.sandbox.on('viewport:show:header', _.bind(this.view.showHeader, this.view));
      this.sandbox.on('viewport:show:footer', _.bind(this.view.showFooter, this.view));
      this.sandbox.on('viewport:hide:header', _.bind(this.view.hideHeader, this.view));
      this.sandbox.on('viewport:hide:footer', _.bind(this.view.hideFooter, this.view));
      //Load translations
      i18n.getApplicationTranslations(true).success(_.bind(this.onTranslationsLoaded, this));
    },


    onTranslationsLoaded: function () {
      //All translations are loaded, start the application
      //If the login fails, no page is displayed
      this.autoLogin();
    },

    changeLanguage: function (lang) {
      Settings.set('lang', lang);
      SessionService.changeLanguage(lang, this).done(function () {
        this.reloadApp();
      });
    },

    reloadApp: function () {
      window.location.reload();
    },

    autoLogin: function () {
      //Required url parameters are u:endUserSecId
      var endUserSecId = UrlUtil.parseQueryString().u,
        cAccountCode = window.location.href.split('.')[0].replace(document.location.protocol + '//', ''),
        token = endUserSecId === 'new' ? null : Settings.get('token'),
        subDomainSlitted = window.location.href.split('/');
      subDomainSlitted.pop();



      if (Settings.get('debug') === true) {
        cAccountCode = 'demo';
        Settings.set('subDomain', '');
      } else {
        Settings.set('subDomain', subDomainSlitted.length === 4 ? subDomainSlitted[subDomainSlitted.length - 1] : '');
      }
      Settings.set('cAccountCode', cAccountCode);

      var fragment = this.sandbox.router.history.getFragment().split('/')[0];
      if (fragment === 'personalize' || fragment === 'catalogues') {
        Settings.set('originalURL', 'catalogues',
          { type: 'cookie', config: { expires: 365 } });
      } else {
        Settings.set('originalURL', 'catalogues',
          { type: 'cookie', config: { expires: 365 } });
      }

      if (endUserSecId && endUserSecId !== 'new') {
        if (endUserSecId) {
          Settings.set('autoLogin', true);
        }
        this.autoLoginFromEndUserSecId(endUserSecId);
      } else if (!token) {
        Settings.set('autoLogin', '');
        this.autoLoginFromCAccountEndUserCode(cAccountCode, this.getTemporaryEndUserCode());
      } else {
        this.autoLoginFromToken();
      }
    },

    autoLoginFromEndUserSecId: function (endUserSecId) {
      SessionService.createTokenByEndUserAndLinkToCaccount(endUserSecId, this)
        .done(function (result) {
          Settings.set('sessionId', result.sessionId);
          SessionService.changeLanguage(Settings.get('lang'), this).done(function () {
            //Retrieve the endUser
            EndUsersService.getSingle(endUserSecId, this)
              .then(function (result) {
                //Backup the endUser
                Settings.set('endUser', result);
                //get the cAccount info
                CAccountService.getSingle(this).done(function (result) {
                  Settings.set('currentCAccount', result);
                  //Login success, continue the rendering
                  this.onAutoLoginSuccess();
                });
              })
              .fail(function (xhr, err, msg) {
                this.component.sandbox.logger(msg);
                Settings.set('autoLogin', '');
              });
          }).fail(function (xhr, err, msg) {
            this.component.sandbox.logger(msg);
            Settings.set('autoLogin', '');
          });
        }).fail(function (xhr, err, msg) {
          Settings.set('token', null, { config: { path: '/' + Settings.get('subDomain') } });
          Settings.set('autoLogin', '');
          this.component.sandbox.logger(msg);
        });
    },

    autoLoginFromToken: function () {
      EndUsersService.getByTokenId(this)
        .then(function (result) {
          SessionService.changeLanguage(Settings.get('lang'), this).done(function () {
            //Backup the endUser
            Settings.set('endUser', result);
            //get the cAccount info
            CAccountService.getSingle(this).done(function (result) {
              Settings.set('currentCAccount', result);
              this.onAutoLoginSuccess();
            });
          }).fail(function (xhr, err, msg) {
            this.component.sandbox.logger(msg);
            Settings.set('autoLogin', '');
          });
        })
        .fail(function (xhr, err, msg) {
          this.sandbox.logger.log(msg);
          Settings.set('autoLogin', '');
        });
    },

    autoLoginFromCAccountEndUserCode: function (cAccountCode, endUserCode) {
      SessionService.createSessionByCAccountEnduser(cAccountCode, endUserCode, this)
        .done(_.bind(
          this.onSessionCreated, this, endUserCode))
        .fail(function (xhr) {
          if (xhr.responseJSON) {
            Settings.set('currentCAccount', xhr.responseJSON);
            this.renderComponent();
            this.sandbox.router.navigate('login', { trigger: true, replace: true });
            Settings.set('autoLogin', '');
          }
        });
    },

    onSessionCreated: function (endUserCode) {
      //create token from the session
      SessionService.createBySessionId(Settings.get('sessionId'), this)
        .done(function () {
          SessionService.changeLanguage(Settings.get('lang'), this).done(function () {
          }).done(function () {
            //Retrieve the endUser
            EndUsersService.getSingleByCode(endUserCode, this)
              .then(function (result) {
                //Backup the endUser
                Settings.set('endUser', result);
                //get the cAccount info
                CAccountService.getSingle(this).done(function (result) {
                  Settings.set('currentCAccount', result);
                  //Login success, continue the rendering
                  this.onAutoLoginSuccess();
                });
              })
              .fail(function (xhr, err, msg) {
                this.sandbox.logger.log(msg);
                Settings.set('autoLogin', '');
              });
          }).fail(function (xhr, err, msg) {
            this.sandbox.logger.log(msg);
            Settings.set('autoLogin', '');
          });
        }).fail(function (xhr, err, msg) {
          this.renderComponent();
          Settings.set('autoLogin', '');
          this.sandbox.logger.log('Error while creating the session', msg);
          this.sandbox.router.navigate('login', { trigger: true, replace: true });

        });
    },

    getTemporaryEndUserCode: function () {
      var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      },
        guid;

      guid = (S4() + S4() + '-' + S4() + '-4' + S4().substr(0, 3) + '-' + S4() + '-' + S4() + S4() + S4()).toLowerCase();

      // then to call it, plus stitch in '4' in the third group
      return guid;
    },

    onAutoLoginSuccess: function () {
      this.renderComponent();
    },

    isSecureMode: function () {
      var currentCAccount = Settings.get('currentCAccount');
      if (currentCAccount && currentCAccount.securedLogotool === true) {
        return true;
      }
      return false;
    },

    changePassword: function (password) {
      var def = $.Deferred(),
        endUserModel = new EndUserItem();

      endUserModel.fetch({
        success: function (model) {
          model.save({ 'password': password }, {
            success: function () {
              def.resolve();
            },
            error: function () {
              def.fail();
            }
          });
        }
      });

      return def.promise();
    },

    renderComponent: function () {
      this.invokeWithCallbacks('render');
    },

    render: function () {
      this.sandbox.helpers.loadCssForModule(module);
      ColorUtil.applyUserTheme();
      this.html(this.renderTemplate('tpl'));
      //Hide or render the header and footer according displayHeaderFooterLogotool parameters
      var token = Settings.get('token');
      if (!token ||
          !token.configuration ||
        !token.configuration.hasOwnProperty('displayHeaderFooterLogotool') ||
        token.configuration.displayHeaderFooterLogotool === null ||
        token.configuration.displayHeaderFooterLogotool === undefined ||
        token.configuration.displayHeaderFooterLogotool === true) {
        this.view.renderChildView();
        this.sandbox.emit('viewport:update:header');
      }
    },

    goToLogin: function () {
      this.sandbox.router.navigate('login', { trigger: true });
    }
  };
});

define('__component__$viewport@default', ['__component__$viewport@default/main'], function (main) { return main; });

