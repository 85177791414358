/**
 * Created by GSP on 24/02/2016.
 */
define('utils/price',['core/utils/settings'], function(Settings) {
  'use strict';
  var PriceUtil =  {
    getCurrencySymbol: function (currency) {
      var symbol = currency;

      if (currency !== null && currency !== undefined) {
        currency = currency.toUpperCase();
      }

      if (currency === 'USD')
        symbol = '$';
      if (currency === 'GBP')
        symbol = '&#163;';
      if (currency === 'EUR')
        symbol = '&#8364;';
      return symbol;
    },

    toFixedPrice: function (number, cptDigits) {
      //The toFixed(x) rounds the value. We cannot round prices !
      //To avoid that we have to round the number (down) to the nearest 10^cptDigits first
      //Eg.: toFixed(2) --> 2 ==> 10^2 = 100 --> number = (Math.floor(100 * number) / 100).toFixed(2)
      var pow = Math.pow(10, cptDigits);

      return (Math.floor(Math.round(pow * number)) / pow).toFixed(cptDigits);
    },

    calculateVat: function() {
      var vat = 0,
        locale = (Settings.get('token' ).locale || Settings.get('locale')).substring(2);

      if (Settings.get('token' ).configuration.displayVat === true) {
        switch(locale) {
          case '_DE':
            vat = 0.19;
            break;
          case '_FR':
            vat = 0.20;
            break;
          case '_NL':
          case '_BE':
            vat = 0.21;
            break;
          default :
            vat = 0.21;
        }
      }

      return vat;
    }

  };
  return PriceUtil;
});
