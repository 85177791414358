/**
 * Created by GSP on 29/01/2016.
 */
define('__component__$cart-page@default/views/cart-view',['jquery',
        'underscore',
        '../views/cart-view-table',
        '../views/prices-view'],
  function($, _, CartViewTable, PricesView) {
    'use strict';

    var OrdersView = {
        renderChildView: function() {
            this.cartTableView = this.createChildView(CartViewTable, {el: this.$('.cart-page__cart-table')});
            this.cartTableView.listenTo(this.cartTableView, 'countReceived', _.bind(this.onCountReceived, this));
            this.cartTableView.listenTo(this.cartTableView, 'rowCountZero', _.bind(this.rowCountZero, this));
        },

        onCountReceived: function(rowCount) {
            if (rowCount > 0) {
                this.priceView = this.createChildView(PricesView, {el: this.$('.cart-page__cart-prices')});
            } else {
                this.component.showEmptyLabel();
            }
        },

        rowCountZero: function() {
            this.cartTableView.remove();
            if (this.priceView) {
                this.priceView.remove();
            }
            this.component.showEmptyLabel();
        }
    };

    return OrdersView;
  });
