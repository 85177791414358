/**
 * Created by dev on 18-03-16.
 */
/**
 * Created by dev on 16-02-16.
 */

define('__component__$register-page@default/views/register-view',['jquery',
    'underscore',
    'utils/form'], function($, _, FormUtils){
    'use strict';
    var ContactView = {
        events: {
            'submit #register-form': 'onSubmitRegister'
        },

        onSubmitRegister : function(e){
            e.preventDefault();
        },

        formValidator: function() {
            var self = this;
            var validator = this.$('#register-form').validate({
                submitHandler: function(){
                    self.$('#btn-register').addClass('disabled');
                    var formObj = FormUtils.getObj('register-form', self.$el);
                    self.component.processRegister(formObj.email, formObj.tel, formObj.comment, formObj.company)
                        .fail(function() {
                            validator.showErrors({
                                email: ''
                            });

                            this.$('#btn-register').removeClass('disabled');
                        });
                },
                rules: {
                    email: {
                        required: true,
                        maxlength: 250
                    },
                    company: {
                        required: true,
                        maxlength: 250
                    },
                    message: {
                        required: true
                    }

                },
                messages: {
                    email: {
                        required: _.i18n('common.value.required'),
                        maxlength: _.i18n('common.value.too.long')
                    },
                    company: {
                        required: _.i18n('common.value.required'),
                        maxlength: _.i18n('common.value.too.long')
                    },
                    message: {
                        required: _.i18n('common.value.required')
                    }
                }
            });
        }
    };

    return ContactView;
});
