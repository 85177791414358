/**
 * Created by dev on 02-03-16.
 */

define('__component__$cart-success-page@default/views/view',['jquery', 'underscore'], function(){
    'use strict';
    var SuccessView = {
        events: {
            'click .return-home': 'returnHome'
        },


        returnHome: function(){
            this.component.goToHomePage();
        }
    };

    return SuccessView;
});
