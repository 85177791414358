define('core/utils/browser',['bowser', 'underscore', 'jquery'], function (bowser, _, $) {

  if (bowser.msie) {
    $('body').addClass('ie');
    $('body').addClass('ie-' + bowser.version);
  }

  if (!window.location.origin) {
    window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
  }

  var browser = {
    isIE: bowser.msie,
    isIE11: bowser.msie && bowser.version === '11.0',
    isIE10: bowser.msie && bowser.version === '10.0',
    isIE9 : bowser.msie && bowser.version === '9.0',
    getLanguage: function() {
      if (navigator) {
        return ((navigator.languages ? window.navigator.languages[0] : null) ||
                navigator.language ||
                navigator.userLanguage ||
                navigator.browserLanguage).split('-')[0];
      } else {
        return null;
      }
    },

    getLocal: function() {
      if (navigator) {
        return ((navigator.languages ? window.navigator.languages[0] : null) ||
                navigator.language ||
                navigator.userLanguage ||
                navigator.browserLanguage).replace("-", "_");
      } else {
        return null;
      }
    }
  };

  return _.extend(browser, bowser);
});

