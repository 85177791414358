define('__component__$cart-page@default/main',[
  'mixins/page-component',
  'module',
  'underscore',
  'jquery',
  'core/utils/settings',
  'services/orders-service',
  'services/prices-service',
  'services/cart-service',
  './views/cart-view'
], function(PageComponentMixin, module, _, $, Settings, OrdersService, PricesService, CartService, CartView) {
  'use strict';

  var componentConfig, res;

  componentConfig = {
    pageRoutePath: {
      'cart': 'showCart',
      'cart/success': 'onCartSuccess'
    },

    module: module,
    templates: ['tpl', 'templates/order-view-table-tpl', 'templates/prices-view-tpl'],
    View: CartView,

    showCart: function () {
      this.renderComponent();
    },

    onCartSuccess: function(){
        this.sandbox.router.navigate('cart-success', { trigger: true });
    },

    renderComponent: function () {
      this.invokeWithCallbacks('render');

      CartService.getTotalPrice(this ).done(function(result){
        this.totalCartPriceDutyFree = Number(result.value);
        this.shippingCostsCartPriceDutyFree = Number(result.shippingCosts);
        this.view.renderChildView();
      }).fail(function( jqXHR, textStatus, errorThrown){
        this.sandbox.logger.log(errorThrown);
      });
    },

    getServices: function() {
      return {
        cartService:  CartService,
        pricesService: PricesService
      };
    },

    showEmptyLabel: function() {
      this.$el.find('.empty-cart-label').removeClass('hide');
    },

    deleteOrder: function(options) {
      OrdersService.deleteOrder(options.purchaseOrderId, this).done(function(){
        CartService.getTotalPrice(this ).done(function(result) {
          this.totalCartPriceDutyFree = Number(result.value);
          this.shippingCostsCartPriceDutyFree = Number(result.shippingCosts);
          this.view.cartTableView.updatePagination();
          this.view.priceView.render();
        }).fail(function( jqXHR, textStatus, errorThrown){
          this.sandbox.logger.log(errorThrown);
        });
      } ).fail(function( jqXHR, textStatus, errorThrown){
        this.sandbox.logger.log(errorThrown);
      });
    },

    gotToDelivery: function() {
      this.sandbox.router.navigate('delivery',  { trigger: true });
    },

    book: function() {
      CartService.book(this ).done(function(result){
        if(result.value === 'booked') {
          this.sandbox.router.navigate('cart/success', { trigger: true });
          this.sandbox.logger.log('order booked');
        }
      } ).fail(function(jqXHR, textStatus, errorThrown ) {
        this.sandbox.logger.log(errorThrown);
      });
    },

    gotToOrderDetail: function ( options ) {
      this.sandbox.router.navigate( 'orders/' + options.purchaseOrderId, { trigger: true } );
    },

    goToPersonalize: function(){
        this.sandbox.router.navigate(Settings.get('originalURL') || 'personalize', { trigger: true });
    },

    displayBookInCart: function(){
        if (this.view && this.view.priceView) {
          this.view.priceView.displayCartBook();
        }
    }

  };

  res = _.extend({}, PageComponentMixin);
  res = _.extend(res, componentConfig);

  return res;
});
define('__component__$cart-page@default', ['__component__$cart-page@default/main'], function (main) { return main; });

