define('__component__$personalize-table@default/main',[
  'mixins/component',
  'module',
  'backbone',
  'underscore',
  'jquery',
  'core/utils/settings',
  'collections/product-items',
  './views/personalize-table-view',
  'eventemitter',
  'jqueryCompulogoPlugin',
  'jqueryCompuzzGalleryBoot'
], function(ComponentMixin, module, Backbone, _, $, Settings, ProductItems, PersonalizeTableView) {
  'use strict';

  var componentConfig, res;

  componentConfig = {
    templates: ['tpl', 'templates/product-loader-template', 'templates/personalize-table-item-template'],
    module: module,

    View: PersonalizeTableView,

    initialize: function() {
      this.sandbox.helpers.loadCssForModule(module);
      this.filters = {};
      this.pagingConfiguration = {
        mediator: this.mediator,
        pageSize: Settings.get('pageSize')
      };
      this.collection = ProductItems.singleInstance;
      this.initFilters();
      this.invokeWithCallbacks('render');
      this.pagingConfiguration.mediator.on('paging:page-changed', _.bind(this.onPageChanged, this));
      this.sandbox.on('applyOptionsFilters', _.bind(this.applyOptionsFilters, this));
      this.sandbox.on('personalizeTable:resize', _.bind(this.view.onResizeWindow, this.view));
    },

    initFilters: function() {
      if (this.options.productCode) {
        this.filters.productCode = this.options.productCode;
      } else if(this.options.catalogueSecId) {
        this.filters.catalogueSecId = this.options.catalogueSecId;
      }
    },

    onFilterChanged: function(filters) {
      this.filters = _.extend(this.filters, filters);
      this.pagingConfiguration.mediator.emit('paging:reset-pages');
      this.view.resetContainerProductsItemView();
      this.fetchCollection(0);
    },

    applyOptionsFilters: function(optionsFilters, filtersType) {
      this.optionsFilters = optionsFilters || '';
      this.filtersType = filtersType || '';
      this.pagingConfiguration.mediator.emit('paging:reset-pages');
      this.view.resetContainerProductsItemView();
      this.fetchCollection(0);
    },

    onPageChanged: function(currentPage) {
      this.fetchCollection(currentPage - 1);
    },

    fetchCollection: function (firstElement) {
      var urlParams = {
        token: Settings.tokenId,
        first: firstElement,
        pageSize: this.pagingConfiguration.pageSize,
        filters: this.filters,
        optionsFilters: this.optionsFilters,
        filtersType: this.filtersType,
        loadProductCodeVariation: true
      };

      this.view.inputToggle();
      this.collection.rowCount(urlParams ).done(_.bind(function (model) {
        if (model === 0) {
          this.mediator.emit('empty-state.show');
        } else {
          this.mediator.emit('empty-state.hide');
          this.collection.fetch({
            url: this.collection.url(urlParams),
            success: _.bind(function(collection){
              this.view.renderProductsItemView(collection);
              this.view.checkProductsPosition();
              this.view.calculateMargin();
              this.startCompulogoComponent(collection);
            }, this)
          });
        }
        this.pagingConfiguration.mediator.emit('paging:row-count-changed', model);
        this.view.inputToggle();
      }, this));
    },

    render: function() {
      this.html(this.renderTemplate('tpl', {
        displayUploadButton: Settings.get('token' ).configuration.enableCompulogo,
        catalogueSecId: this.options.catalogueSecId
      }
      ));
      this.initializeCompulogoComponent();
      this.startComponents();
    },

    startComponents: function() {
      var componentToStart = [{
                                name: 'paging',
                                options:
                                {
                                  el: this.$find('#paging-container'),
                                  configuration: this.pagingConfiguration
                                }
                              },{
                                name: 'empty-state',
                                options: {
                                  el: this.$find('.empty-state-container'),
                                  mediator: this.mediator
                                }
                              }];
      if(this.filters.catalogueSecId) {
       this.view.resizeProductTable();
        componentToStart.push(
          {
            name: 'left-menu',
            options: {
              el: this.$find('.left-menu-container'),
              mediator: this.pagingConfiguration.mediator,
              catalogueSecId: this.filters.catalogueSecId
            }
          });
      }
      this.sandbox.start(componentToStart);
    },

    initializeCompulogoComponent: function() {
      if(Settings.get('token' ).configuration.enableCompulogo) {
        $('#modal__compuzz-gallery').compuzzGalleryPlugin({
          logger: true,
          serverURL: Settings.get('serverURL'),
          tokenId: Settings.get('token' ).tokenId,
          URI: 'compuzz/rest/',
          endUserCode: Settings.get('endUser').code,
          lang: Settings.get('lang')
        });
      }
    },

    // parse data from advancedSearch request
    // after that - we pass it to compuLogo plugin
    parseCompulogoData: function (collection) {
      var productsList = {};

      collection.each(function (product) {
        var productCode = product.get('cCode'),
          colorOptionCategory;

        productsList[productCode] = {
          disable: product.get('numberOfVrp')===0,
          cataloguePictureUid: product.get('cataloguePictureUid'),
          options: null
        };

        // we use the first productOptionCategory with type = "color"
        // to get color options for product block
        colorOptionCategory = _.findWhere(product.get('productOptionCategories'), {type: 'color'});

        if (colorOptionCategory) {
          productsList[productCode].options = colorOptionCategory.productOptions;
        }
      });

      return productsList;
    },

    startCompulogoComponent: function (collection) {
      this.$el.find('.div-compuzz-vrp-content-block').compuLogo({
        logger: true,
        serverURL: Settings.get('serverURL'),
        URI:  'compuzz/rest/',
        tokenId: Settings.get('token' ).tokenId,
        imageViewerContainer: '.img-compuz-vrp-viewer',
        optionsContainer: '.div-compuzz-colors-options-block',
        imageViewerLoaderTpl: this.renderTemplate('templates/product-loader-template'),
        productsList: this.parseCompulogoData(collection),
        simpleMode: !Settings.get('token' ).configuration.enableCompulogo,
        onInitialized: _.bind(function(){
          if (Settings.get('token' ).configuration.enableCompulogo) {
            this.$find('#div-compuzz-vrp-upload-logo-button').compuzzGalleryPlugin('click', function(fileId) {
              $( this ).compuLogo( 'update', { fileId: fileId } );
            });
          }
        }, this)
      });
    },

    goToOrderProcessPage: function(options) {
      this.sandbox.router.navigate(options.route, {trigger: true});
    }

  };

  res = _.extend({}, ComponentMixin);
  res = _.extend(res, componentConfig);

  return res;
});
define('__component__$personalize-table@default', ['__component__$personalize-table@default/main'], function (main) { return main; });

