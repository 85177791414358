define( 'jqueryCompuzzGalleryBoot',[
    'underscore',
    'jquery',
], function ( _, $ ) {

    return {
        init: function(){
            //.................................
            // begin create jquery gallery plugin

            var initialized = false, // flag if gallery was initialize
                defaults;            // extended default parameters

            $.fn.compuzzGalleryPlugin = function ( params, callback ) {
                if ( initialized ) {
                    if ( params === 'click' ) { // you can open gallery on.click el
                        this.click( function () {
                            $( $.fn.compuzzGalleryPlugin.params.container ).compuzzGalleryPlugin.open( callback );
                        } );
                    } else {
                        console.log( '--> cgp: gallery was initialized.' );
                    }
                    return;
                }
                // first initialization
                defaults = $.extend( {}, $.fn.compuzzGalleryPlugin.params, params );
                defaults.container = this; // set container for gallery el to params
                $.fn.compuzzGalleryPlugin.params = defaults;
                initialized = true;
                var gallery = require(['jqueryCompuzzGallery']);
            };
            $.fn.compuzzGalleryPlugin.params = { // default params
                logger: false,                   // enable internal plugin logs
                sessionId: '',
                URI: 'compuzz/rest/'
            };

            // if you didn't initialize gallery first
            $.fn.compuzzGalleryPlugin.open = function () {
                console.log( '--> cgp: you must init gallery first!!!' );
            };

            // write readme in console.log
            $.fn.compuzzGalleryPlugin.help = function () {
                console.log( '--> cgp: ?help: ' );
                $.ajax( {
                    url: "../help.txt",  // you must set readme file path and name
                    dataType: "text",
                    success: function ( data ) {
                        console.log( data );
                    },
                    error: function ( e ) {
                        console.log( "--> cgp: can't load readme" );
                    }
                } );
            };
        }
    }.init();

} );
