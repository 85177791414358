/**
 * Created by GSP on 24/02/2016.
 */
define('services/prices-service',['jquery',
        'underscore',
        'core/utils/settings'], function($, _, Settings) {
  'use strict';
  var PriceService = {
    getUnitPricePerOrder: function(purchaseOrderIds, ctx) {
      if( !_.isEmpty(purchaseOrderIds)) {
        return $.ajax( {
          url: Settings.url( 'compuzz', 'prices.json/', {purchaseOrderIds: purchaseOrderIds.join(',')}),
          contentType: 'application/json',
          context: ctx
        } );
      }
    }
  };

  return PriceService;
});
