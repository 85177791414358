define('services/session-service',[
  'jquery',
  'core/utils/settings'
], function ($, Settings) {
  'use strict';

  var service = {
    createTokenByEndUserAndLinkToCaccount : function ( endUserSecId, ctx ) {
      return $.ajax( {
        url: Settings.url( 'compuzz', 'sessions.json/token/enduser/' + endUserSecId, null, true ),
        type: 'POST',
        context: ctx,
        success: function ( result ) {
          Settings.set('token', result,  { type: 'cookie', config: {expires: 365 }});
        },
        error: function ( err ) {
          console.log( 'Unable to create the token' );
          console.log( err.responseText );
        }
      } );
    },

    createSession: function ( session, ctx ) {
      return $.ajax( {
        url: Settings.url( 'compuzz', 'sessions.json', null, false),
        type: 'POST',
        context: ctx,
        data: JSON.stringify( session ),
        dataType: 'json',
        contentType: 'application/json',
        success: function ( result ) {
          Settings.set( 'sessionId', result.sessionId);
        },
        error: function () {
          console.log( 'Unable to link the token to DEMO' );
        }
      } );
    },

      createSessionByCAccountEnduser: function(caccountCode, endUserCode, ctx, data) {
          return $.ajax({
              url: Settings.url('compuzz','sessions.json/caccount/' + caccountCode + '/user/' + endUserCode, null, true),
              type: 'POST',
              context: ctx,
              data: data ? JSON.stringify(data) : null,
              dataType: 'json',
              contentType: 'application/json',
              success: function (result) {
                  Settings.set('sessionId', result.sessionId);
              },
              error: function (err) {
                  console.log( 'Unable to create the token' );
                  console.log( err.responseText );
              }
          });
      },

      register: function(caccountCode,ctx, data) {
          return $.ajax({
              url: Settings.url('compuzz','sessions.json/register/' + caccountCode , null, true),
              type: 'POST',
              context: ctx,
              data: data ? JSON.stringify(data) : null,
              dataType: 'json',
              contentType: 'application/json'
          });
      },

    createBySessionId: function(sessionId, ctx) {
      return $.ajax( {
        url: Settings.url('compuzz', 'sessions.json/token/createBySessionId/' + sessionId, null, true),
        type: 'GET',
        context: ctx,
        success: function ( result ) {
          Settings.set('token', result,  { type: 'cookie', config: {expires: 365 }});
        },
        error: function ( err ) {
          console.log( 'Unable to create the token' );
          console.log( err.responseText );
        }
      } );
    },

    validateSessionId: function ( ctx ) {
      return $.ajax( {
        url: Settings.url( 'compuzz', 'sessions.json/validateSession/'),
        type: 'GET',
        context: ctx,
        error: function ( err ) {
          console.log( 'Unable to validate the token' );
          console.log( err.responseText );
        }
      } );
    },

    changeLanguage: function(lang, ctx){
        var url = Settings.url('compuzz', 'sessions.json/token/changeLanguage', {newLanguage : lang});
        return $.ajax({
            type: 'PUT',
            url: url,
            context: ctx
        });
    }
  };

  return service;
});

