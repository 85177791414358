/**
 * Created by Matteo on 12/03/2016.
 */
/**
 * Created by Matteo on 12/03/2016.
 */
define('__component__$viewport@default/views/footer-view',['backbone',
        'jquery',
        'underscore',
        'core/utils/settings'],
  function(Backbone, $, _, Settings) {
    'use strict';
    var FooterView = Backbone.View.extend({

      initialize: function(options) {
        this.parent = options.parent;
        this.render();
      },

      render: function() {
        var currentCAccount = Settings.get('currentCAccount');
        var templateData = {
          phone: currentCAccount.phone,
          contactEmail: currentCAccount.contactEmail
        };
        this.$el.html(this.parent.component.renderTemplate('templates/footer-tpl', templateData));
      }
    });

    return FooterView;
  });
