define('core/utils/settings',[
    'jquery',
    'underscore',
    'jquerycookie',
    'config/env-handler',
    'config/protocol',
    'core/utils/browser'], function ($, _, JqueryCookie, EnvironmentHandler, Protocol, browser) {

  var env = 'optv2';

  var _default = {
    storeInMemory: {},
    urls: {
      compuzzWs: 'ws2/rest/',
      compuzz: 'compuzz/rest/',
      compuzzHtml: 'compuzz/plugin/compuzzhtml/',
      compuzzHtmlCustomization: 'htmlcompuzz/#customization/',
      compuzzPlugin: 'compuzz/plugin/index.jsf',
      compuzzProductPlugin: 'compuzz/productPlugin/index.jsf',
      compuzzOrderProcess: 'compuzz/orderprocess/',
      pdf: 'compuzz/PdfGenerator',
      x2: 'x2pdf-ws/rest/convert/',
      x2pdf: 'x2pdf-ws/rest/',
      x2png: 'x2png-ws/',
      file:  EnvironmentHandler.getValue(env,
        'filesPath') + 'getfile/'
    },
    imageSize: {
      thumbnail: 't',
      medium: 'm',
      big: 'b'
    },
    lang                   : browser.getLanguage(),
    locale                 : browser.getLocal() ,
    currencyCode           : 'EUR',
    pageSize               : 12,
    application            : 'orderprocesshtml',
    environment            : env,
    defaultLang            : 'en',    //When a translation is not found in "lang" it will be set in "defaultLang"
    token                  : null,
    currentCAccount        : null,
    endUser                : null,
    productCode            : '',
    debug                  : false,
    sessionId              : null,
    subDomain              : '',
    originalURL            :  ''
  };

  var Settings = {
    tplEngine: 'html',
    storeInCookies: [
      {name: 'token', format: 'json'},
      {name:'lang', format: 'raw'},
      {name: 'originalURL', format: 'raw'},
      {name: 'autoLogin', format: 'raw'}
    ],
    storeInMemory: {},
    numbers: ['perPage', 'pageNumber'],
    serverURL: Protocol.apply(EnvironmentHandler.getValue(env,
      'serverUrl')),
    domaine: '.com/',

    pluginUrl: function() {
      return this.serverURL + 'compuzz/plugin/index.jsf';
    },

    set: function (name, value, options) {
      var cookieObj;
      options = _.extend({type: 'property', config: {}}, options);
      if (!name) { return; }
      if (name === 'sessionId' && !value) { return; }
      switch (options.type) {
        case 'cookie':
          options = _.extend({
            config: {
              expires: 30
            }
          }, options);
          break;
      }
      if (cookieObj = _.findWhere(this.storeInCookies, {name: name})) {
        if (!_.isEmpty(value) || value) {
          if(cookieObj.format === 'json') {
            $.cookie.json = true;
            $.cookie(name + env, JSON.stringify(value), options.config);
          } else {
            $.cookie.json = false;
            $.cookie(name + env, value.toString(), options.config);
          }
          this[name] = value;
        } else {
          if(cookieObj.format === 'json') {
            $.cookie.json = true;
          } else {
            $.cookie.json = false;
          }
          $.removeCookie(name + env,  options.config);
          delete this[name];
        }
      } else {
        this[name] = value;
      }
    },

    get: function (name) {
      var option, cookieObj;
      option = this[name];
      if (cookieObj = _.findWhere(this.storeInCookies, {name: name})) {
        if(cookieObj.format === 'json') {
          $.cookie.json = true;
          option = $.cookie(name + env);
          option = option ? JSON.parse(option) : null;
        } else {
          $.cookie.json = false;
          option = $.cookie(name + env);
        }
        if (option === 'false') {option = false;}
      }
      else if (_.contains(this.numbers, name)) {
        option = parseInt(option, 10);
      }
      else if (name === 'path' && option) {
        option = option.split(',');
      }

      option = option || _default[name];
      return option;
    },

    url: function (base, relative, params, tokenNotRequired) {
      var baseUrl = '',
          baseServerUrl = '',
          indexOfServerUrl = -1,
          stringParams = '',
          ampersand = '';

      if (relative) {
        indexOfServerUrl = relative.indexOf('http://localhost:8080/');
        if (indexOfServerUrl >= 0) {
          baseServerUrl = 'http://localhost:8080/';
        } else {
          indexOfServerUrl = relative.indexOf(this.serverURL || '');
          if (indexOfServerUrl >= 0) {
            baseServerUrl = this.serverURL;
          } else {
            indexOfServerUrl = relative.indexOf(this.domaine);
            if (indexOfServerUrl >= 0) {
              baseServerUrl = relative.substring(0, indexOfServerUrl + this.domaine.length);
            }
          }
        }
        if (indexOfServerUrl >= 0){
          relative = relative.replace(baseServerUrl, '');
        }

      }
      params = params || {};

      if (params.size) {
        params.action = 'res';
        params.params = _default.imageSize[params.size];
        delete params.size;
      }

      if (base === 'base') {
        baseUrl = '';
      } else {
        baseUrl = _default.urls[base] || base;
      }

      if (relative) {
        if (relative.indexOf('?') === -1) {
          stringParams = '?';
        } else {
          stringParams = '&';
        }
      } else {
        stringParams = '?';
      }

      if(relative.indexOf('lang') < 0) {
        params.lang = this.get('lang');
      }

      _.each(params, function (value, key) {
        if ((value || value === 0) && key) {
          stringParams += (ampersand + key + '=' + value.toString());
          ampersand = '&';
        }
      });

      if (baseUrl.indexOf('getfile') < 0 && !tokenNotRequired) {
        var token = this.get('token' ) || {};
        if(token.tokenId){
            stringParams += ampersand + 'tokenId' + '=' + token.tokenId;
        }
      }

      return this.serverURL + baseUrl + relative + stringParams;
    },

    setToMemory: function(key, value) {
      value = JSON.stringify(value);

      if(key) {
        if( _.isEmpty(value) || !value) {
          delete Settings.storeInMemory[key];
        } else{
          Settings.storeInMemory[key] = value;
        }
      }
    },

    getFromMemory: function(key) {
      var value = Settings.storeInMemory[key];
      if (value) {
        value = JSON.parse(value);
      }

      return value;
    }
  };

  return Settings;
});
