/**
 * Created by Matteo on 12/03/2016.
 */
/**
 * Created by GSP on 29/01/2016.
 */
define('__component__$viewport@default/views/viewport-view',['jquery',
        'underscore',
        './header-view',
        './footer-view'],
  function($, _, HeaderView, FooterView) {
    'use strict';

    var ViewportView = {
      renderChildView: function() {
        this.headerView = this.createChildView(HeaderView, {el: this.$('#header')});

        this.headerView.listenTo(this.headerView, 'changeLanguage', _.bind(this.onChangeLanguage, this));
        this.footerView = this.createChildView(FooterView, {el: this.$('#footer')});
      },

      showHeader: function() {
        this.headerView.$el.removeClass('hide');
      },

      updateHeaderData: function() {
        this.headerView.setAccountName();
        this.headerView.setCurrentLanguage();
      },

      showFooter: function() {
        this.footerView.$el.removeClass('hide');
      },

      hideHeader: function() {
        this.headerView.$el.addClass('hide');
      },

      hideFooter: function() {
        this.footerView.$el.addClass('hide');
      },

      onChangeLanguage: function(language){
          this.component.changeLanguage(language);
      }
    };

    return ViewportView;
  });
