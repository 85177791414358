define('__component__$left-menu@default/main',[
    'mixins/component',
    'module',
    'jquery',
    'underscore',
    'services/filters-service',
    './views/left-menu-view'
], function(componentMixin, module, $, _, filtersService, LeftMenuView) {
    'use strict';

    var componentConfig,
        res;

    componentConfig = {
        templates: 'tpl',
        View: LeftMenuView,

        initialize: function() {
            this.catalogueSecId = this.options.catalogueSecId;
            this.invokeWithCallbacks('render');
            this.sandbox.helpers.loadCssForModule(module);
            this.sandbox.on('applyOptionsFiltersType', _.bind(this.view.applyOptionsFiltersType, this.view));
        },

        render: function() {
            this.html(this.renderTemplate('tpl'));
            this.view.resizeMenu();
            this.prepareFilters();
        },

        prepareFilters: function() {
            filtersService.getFilters(this.catalogueSecId).done( _.bind(function(response){
                if (response && response.length) {
                    this.$el.show();
                    $(window).resize();
                    this.view.allFilters = response;
                    this.view.createFiltersTypesView(response);
                } else {
                    this.sandbox.emit('personalizeTable:resize');
                    this.sandbox.logger.log('Can\'t get filters for catalogue.');
                }
            }, this));
        }
    };

    res = _.extend({}, componentMixin);
    res = _.extend(res, componentConfig);

    return res;
});
define('__component__$left-menu@default', ['__component__$left-menu@default/main'], function (main) { return main; });

