/**
 * Created by dev on 02-03-16.
 */
define('__component__$cart-success-page@default/main',[
    'mixins/page-component',
    'module',
    'jquery',
    'underscore',
    'core/utils/settings',
    './views/view',
], function (pageComponentMixin, module, $, _, Settings, SuccessView) {
    'use strict';

    var componentConfig, res;

    componentConfig = {
        pageRoutePath: {
            'cart-success': 'onSuccess'
        },
        View: SuccessView,
        module: module,
        templates: ['tpl'],

        onSuccess: function() {
            this.renderComponent();
        },

        renderComponent: function () {
            this.invokeWithCallbacks('render');
        },

        goToHomePage: function() {
            this.sandbox.router.navigate(Settings.get('originalURL') || 'personalize', { trigger: true });
        }
    };

    res = _.extend({}, pageComponentMixin);
    res = _.extend(res, componentConfig);

    return res;
});
define('__component__$cart-success-page@default', ['__component__$cart-success-page@default/main'], function (main) { return main; });

