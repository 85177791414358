define('__component__$delivery-page@default/main',[
  'mixins/page-component',
  'module',
  'jquery',
  'underscore',
  'core/utils/settings',
  './views/delivery-view',
  'services/cart-service',
  'models/enduser-item'
], function (pageComponentMixin, module, $, _, Settings, DeliveryView, CartService, EndUserItem) {
  'use strict';

  var componentConfig, res;

  componentConfig = {
    pageRoutePath: {
      'delivery': 'delivery'
    },
    View: DeliveryView,
    module: module,
    templates: ['tpl'],

    delivery: function() {
      var endUser = new EndUserItem();

      endUser.fetch({
        success: _.bind(this.endUserLoaded, this)
      });

      this.sandbox.helpers.loadCssForModule(this.module);
    },

    endUserLoaded: function(model) {
      this.model = model;
      if (!model.get('refDeliveryAddress')) {
        model.set('refDeliveryAddress', {});
      }
      this.invokeWithCallbacks('render');
      this.view.model =  this.model;
      this.view.onUseInvoicingAddress();
      this.view.formFilling();
      this.view.formValidator();
      this.view.checkVat();
    },

    saveData: function (data) {
      var deferred = $.Deferred();
      this.model.save({
        'vatNumber': data.vatNumber,
        'email': data.email,
        'name': data.companyName,
        'tel': data.tel,
        'addressL1': data.addressL1,
        'addressL2': data.addressL2,
        'companyNumber': data.companyNumber,
        'zip': data.zip,
        'city': data.city,
        'comment': data.comment,
        'refDeliveryAddress': data.refDeliveryAddress
      }, {
        success: _.bind(function() {
            deferred.resolve();
        }, this),
        error: _.bind(function(model, error){
            deferred.reject(error);
        }, this)
      });

      return deferred.promise();
    },

    validateCart: function () {
      CartService.validate(this ).done(function(result){
        if(result.value === 'validated') {
          this.view.showSuccessCartValidation();
          this.sandbox.logger.log('order validated');
        }
      } ).fail(function(jqXHR, textStatus, errorThrown ) {
        if (jqXHR.status === 409) {
          this.view.enableConfirm();
          alert(_.i18n( 'error.notPersonalized' ));
        } else {
          this.sandbox.logger.log(errorThrown);
        }
      });
    },

    goToHomePage: function() {
      this.sandbox.router.navigate(Settings.get('originalURL') || 'personalize', { trigger: true });
    },

    goToSuccess: function(){
        this.sandbox.router.navigate('cart/success', { trigger: true });
    },

    goToCart: function () {
      this.sandbox.router.navigate('cart', { trigger: true });
    }
  };

  res = _.extend({}, pageComponentMixin);
  res = _.extend(res, componentConfig);

  return res;
});
define('__component__$delivery-page@default', ['__component__$delivery-page@default/main'], function (main) { return main; });

