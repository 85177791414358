/**
 * Created by GSP on 29/01/2016.
 */
define('__component__$personalize-table@default/views/personalize-table-item-view',['jquery',
        'underscore',
        'backbone',
        'photoSwipeGallery',
        'photoSwipeUI',
        'utils/url'],
  function($, _, Backbone, PhotoSwipe, PhotoSwipeUIDefault, UrlUtil) {
    'use strict';
    var PersonalizeTableItemView = Backbone.View.extend({
      el: '<div class="col l2 m4 s12 min-width-for-product-item"></div>',
      events:{
        'click .card__perso': 'onPersonalize',
        'click .img-compuz-vrp-viewer': 'onZoomComposition',
        'click .color-section-ellipsis': 'showHideColors',
        'mouseenter .thumbnail-single' : 'showThumbnail',
        'mouseleave .thumbnail-hover' : 'hideThumbnail',
        // Fix added for .card__perso because in IE 10 we had a problem with hover
        'mouseenter .div-compuzz-vrp-content-block': 'cardPersoMouseEvent',
        'mouseleave .div-compuzz-vrp-content-block': 'cardPersoMouseEvent'
      },

      initialize: function(options) {
        this.model = options.model;
        this.parent = options.parent;
        this.onResizeWindow();
        $(window).on('resize', _.bind(this.onResizeWindow, this));
      },

      render: function() {
        this.$el.html(this.parent.component.renderTemplate('templates/personalize-table-item-template', this.model.toJSON()));
        this.$el.on('DOMNodeInserted', _.bind(this.onResizeWindow, this));
        return this;
      },

      onResizeWindow: function () {
        var width = this.$el.find('.card__meta').css('width'),
          colorBlocksNumber = this.$el.find('.div-compuzz-colors-options-block span').length,
          totalBlockWidth = colorBlocksNumber * 24,
          optionsBlock = this.$el.find('.div-compuzz-colors-options-block'),
          ellipsisSection = this.$el.find('.color-section-ellipsis');

        if (totalBlockWidth < parseInt(width, 10)) {
          optionsBlock.css('width', '100%');
          ellipsisSection.hide();
        } else {
          optionsBlock.css('width', '85%');
          ellipsisSection.css('display', 'inline-block');
          ellipsisSection.show();
        }
      },

      cardPersoMouseEvent: function (e) {
        if (e.type === 'mouseenter') {
          this.$el.find('.card__perso')
            .css({'position': 'absolute', 'right': '20px'})
            .show();
          this.$el.find('.card.radius.shadowDepth1')
            .css('box-shadow', '2px 2px 20px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0,0,0, 0.8)');
        } else if (e.type === 'mouseleave') {
          this.$el.find('.card__perso').hide();
          this.$el.find('.card.radius.shadowDepth1')
            .css('box-shadow', '0 1px 3px rgba(0,0,0, 0.12), 0 1px 2px rgba(0,0,0, 0.24)');
        }
      },


      checkProductsPosition: function () {
        var $productItems = this.$('.min-width-for-product-item' ),
          productsNumber = $productItems.length;

        if (productsNumber % 12 === 1 && productsNumber !== 1)  {
          var index = productsNumber - 1,
            pos = $($productItems[index]).position().top;

          $('body').animate({scrollTop: pos}, 'slow');
        }

        this.$('#paging-container').removeClass('prevent-paging');
      },

      showThumbnail: function () {
        this.$el.find('.thumbnail-hover')[0].show();
      },

      hideThumbnail: function (e) {
        var target = $(e.currentTarget);

        target.hide();
      },

      showHideColors: function (e) {
        var target = $(e.currentTarget),
          interval = 200,
          blocksPerRow = 1,
          optionsBlock = this.$el.find('.div-compuzz-colors-options-block'),
          contentBlock = this.$el.find('.div-compuzz-vrp-product-content-block'),
          ellipsisSection = this.$el.find('.color-section-ellipsis'),
          colorBlocksNumber = this.$el.find('.div-compuzz-colors-options-block span').length,
          width = optionsBlock.width(),
          height = this.$el.find('.shadowDepth1').css('height'),
          rowsNumber;

        while (blocksPerRow * 24 < parseInt(width, 10)) {
          blocksPerRow++;
        }

        rowsNumber = Math.ceil(colorBlocksNumber/(blocksPerRow - 1));

        if (!target.hasClass('hide-colors')) {
          this.$el.find('.card__meta').animate({
              height: '75px'
          }, interval/2);
          optionsBlock.css('width', '100%');
          optionsBlock.animate({
              height: rowsNumber * 26 + 'px'
          }, interval);
          contentBlock.animate({
              height: parseInt(height, 10) - 120 - (rowsNumber * 26) + 'px'
          }, interval);

           this.$el.find('.div-compuzz-vrp-content-block').removeClass('not-expanded').addClass('expanded');

           ellipsisSection.html('<i class="material-icons">close</i>').css('float', 'right');
        } else {
          optionsBlock.css('width', '85%');
          optionsBlock.animate({
            height: '25px'
          }, interval);
          contentBlock.animate({
            height: '190px'
          }, interval);
          this.$el.find('.card__meta').animate({
            height: '85%'
          }, interval);

          this.$el.find('.div-compuzz-vrp-content-block').removeClass('expanded').addClass('not-expanded');

          ellipsisSection.html('<i class="material-icons">more_vert</i>');
        }

        this.$el.find('.shadowDepth1').css('height', height);
        target.toggleClass('hide-colors');
      },

      onPersonalize: function(e) {
        e.preventDefault(); // prevent default action - hash doesn't appear in url
        $(this).parent().find( 'div' ).toggleClass( 'card__perso--active' );
        $(this).toggleClass('perso-expanded');

        var catalogSecIdParam = '';
        if(this.parent && this.parent.component && this.parent.component.options){
          catalogSecIdParam = this.parent.component.options.catalogueSecId;
        }
        if (this.model.get('code')) {
          var optionId = this.$el.find('span[data-selected=true]' ).attr('data-option-id' ),
              pathParams = optionId ? '/option/' + optionId: '',
              queryParams = UrlUtil.parseQueryString(),
              tplParam = queryParams.tpl || '' ,
              endUserSecId = queryParams.u || '' ,
              templParams = tplParam ? '?u=' + endUserSecId + '&tpl=' + tplParam : '';
              if(catalogSecIdParam){
                if(tplParam || pathParams){
                  catalogSecIdParam = '&catalogSecId=' + catalogSecIdParam;
                }
                else{
                  catalogSecIdParam = '?catalogSecId=' + catalogSecIdParam;
                }
              } else {
                catalogSecIdParam = '' ;
              }

          this.parent.component.goToOrderProcessPage({route: 'orderprocess/order/product/' + this.model.get('cCode') +
                                                             pathParams + templParams + catalogSecIdParam});
        }
      },

      onZoomComposition: function(e) {
        var target = $(e.currentTarget),
          optionId = target.attr('data-cached-option-id');
        this.initGallery(optionId);
      },

      initGallery: function (optionId) {
        var pswpElement = $('.pswp')[0],
          gallery,
          items = [],
          options,
          num = 0;

        _.each($('.div-compuzz-vrp-content-block[data-secId=' + this.model.get('secId') + '] .div-compuzz-vrp-product-content-box-gallery a'), function (item, i) {
          var src = $(item).attr('href'),
            itemObj;

          if (optionId) {
            var href = $(item).attr('href');

            if (href.indexOf(optionId) >= 0) {
              num = i;
            }
          }

          src = src.replace('params=t', 'params=b');
          itemObj = {
            src: src,
            w: 0,
            h: 0
          };

          items.push(itemObj);
        });

        options = {
          index: num
        };

        // Initializes and opens PhotoSwipe
        gallery = new PhotoSwipe( pswpElement, PhotoSwipeUIDefault, items, options);

        gallery.listen('gettingData', function(index, item) {

          if (item.w < 1 || item.h < 1 ) { // unknown size
            var img = new Image();
            img.onload = function() { // will get size after load
              item.w = this.width; // set image width
              item.h = this.height; // set image height

              gallery.invalidateCurrItems(); // reinit Items
              gallery.updateSize(); // update size of items
            };

            img.src = item.src; // let's download image
          }
        });

        $('.pswp__button--arrow--right, .pswp__button--arrow--left').click(function () {
          gallery.zoomTo(gallery.currItem.initialZoomLevel - 0.23, {x: gallery.viewportSize.x/2, y: gallery.viewportSize.y/2}, 0);
        });

        gallery.init();
      },

      remove: function() {
        this.$el.find('.div-compuzz-vrp-content-block').compuLogo('destroy');
        Backbone.View.prototype.remove.apply(this, arguments);
      }

    });

    return PersonalizeTableItemView;
  });
