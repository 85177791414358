/**
 * Created by dev on 08-03-16.
 */
define('__component__$welcome-area@default/main',[
    'mixins/component',
    'backbone',
    'module',
    'jquery',
    'underscore',
    'core/utils/settings',
    './views/welcome-view'
], function (componentMixin, Backbone, module, $, _, Settings, WelcomeView) {
    'use strict';

    var componentConfig,
        res;

    componentConfig = {
        templates: ['tpl'],
        module: module,
        View: WelcomeView,

        render: function() {
            if(Settings.get('token' ).configuration.displayWelcome === true) {
                this.html(this.renderTemplate('tpl'));
               setTimeout( _.bind(function() {
                 this.$find('.welcome-area__well' ).fadeIn(3000);
               }, this), 100);
            }
        }
    };



    res = _.extend({}, componentMixin);
    res = _.extend(res, componentConfig);

    return res;
});
define('__component__$welcome-area@default', ['__component__$welcome-area@default/main'], function (main) { return main; });

