define('__component__$personalize-page@default/main',[
  'mixins/page-component',
  'module',
  'backbone',
  'jquery',
  'underscore'
], function (PageComponentMixin, module, Backbone, $, _) {
  'use strict';

  var componentConfig, res;

  componentConfig = {
    pageRoutePath: {
      'personalize(/:productCode)': 'personalizeProduct'
    },

    module: module,
    templates: 'tpl',

    personalizeProduct1: function() {
      this.setConfig({productCode: ''});
      this.invokeWithCallbacks('render');
    },

    personalizeProduct: function(productCode) {
      this.setConfig({productCode: productCode || ''});
      this.invokeWithCallbacks('render');
    }

  };

  res = _.extend({}, PageComponentMixin);
  res = _.extend(res, componentConfig);

  return res;
});
define('__component__$personalize-page@default', ['__component__$personalize-page@default/main'], function (main) { return main; });

