/**
 * Created by GSP on 29/01/2016.
 */
define('__component__$personalize-table@default/views/personalize-table-view',['jquery',
        'underscore',
        '../views/personalize-table-item-view',
        'core/utils/browser'],
  function($, _, PersonalizeTableItemView, Browser) {
    'use strict';

    var PersonalizeTableView = {
      events:{
        'change .personalize__search': 'getFilters',
        'keypress .personalize__search': 'onKeyPressedSearchInput'
      },

      initialize: function() {
        this.onResizeWindow();
        $(window).on('resize', _.bind(this.onResizeWindow, this));
      },

      resetContainerProductsItemView: function() {
        this.removeChildView();
      },

      renderProductsItemView: function(products){
        var fragment = document.createDocumentFragment(),
          productView;
        products.each(function (model) {
          productView = this.createChildView(PersonalizeTableItemView, {model: model});
          fragment.appendChild(productView.render().el);
        }, this);
        this.$('.products-content').append($(fragment));

      },

      checkProductsPosition: function() {
        var productItem = this.$('.min-width-for-product-item'),
          productsNumber = productItem.length;

        if (productsNumber % 12 === 0 && productsNumber > 12)  {
          var index = productsNumber - 1,
            pos = $(productItem[index]).position().top;

          $('body').animate({scrollTop: pos}, 1500);
        }

        this.$('#paging-container').removeClass('prevent-paging');
      },

      onResizeWindow: function () {
        this.resizeProductTable();
        //var element = this.$el,
        //    height,
        //    userAgent;
        //
        //this.calculateMargin();
        //if ($('.left-menu-container').is(':visible')) {
        //  element.find('.products-content').css('width', element.find('.personalize-products-table').width() - 240);
        //} else {
        //  element.find('.products-content').css('width', element.find('.personalize-products-table').width());
        //}
        //
        //// set min-height for footer in forefox
        //height = $(document).height() - element.closest('.viewport-page').find('.header').height() - element.closest('.viewport-page').find('.footer').height();
        //userAgent = navigator.userAgent;
        //
        //if (userAgent.indexOf('Firefox') !== -1) {
        //  element.closest('.viewport-page').find('.container-fluid').css('min-height', height);
        //}
      },

      calculateMargin: function () {
        //var leftMenuContainer = this.$('.left-menu-container' ),
        //  filtersWidth = leftMenuContainer.width() ? leftMenuContainer.width() + 15 : 240,
        //  minMargin = 25,
        //  element = this.$('.min-width-for-product-item'),
        //  personalizeTableWidth = this.$('.personalize-products-table').width(),
        //  numberOfItems,
        //  emptySpace;
        //
        //if (leftMenuContainer.is(':hidden')) {
        //  filtersWidth = 0;
        //}
        //numberOfItems = (~~((personalizeTableWidth - filtersWidth)/(element.width()+ minMargin*2)));
        //emptySpace = (personalizeTableWidth - filtersWidth) - (numberOfItems*element.width());
        //element.css('margin', 0 + ' ' + emptySpace/(numberOfItems*2));
      },

      resizeProductTable: function() {
        if(($('.left-menu-container').is(':visible'))){
          this.$('.productsTable').removeClass('col s12' ).addClass('col s10');
        } else {
          this.$('.productsTable').removeClass('col s10' ).addClass('col s12');
        }
      },

      getFilters: function () {
        var filtersData = {};

        filtersData.productMarker = this.$el.find('[name="productMarker"]').val();
        this.component.onFilterChanged(filtersData);
      },

      onKeyPressedSearchInput: function(e) {
        if( Browser.isIE && e.which && e.which === 13) {
          $(e.currentTarget).trigger('change');
        }
      },

      inputToggle: function() {
        var submitFilter = this.$('.submit-filters');
        if(submitFilter.hasClass('disabled')) {
          this.$('.submit-filters').removeClass('disabled');
        } else {
          this.$('.submit-filters').addClass('disabled');
        }
      }
    };
    return PersonalizeTableView;
  });
