/**
 * Created by GSP on 25/02/2016.
 */
define('services/cart-service',['jquery',
        'underscore',
        'services/common-service',
        'core/utils/settings'], function($, _, CommonService, Settings) {
  'use strict';
  var CartService = {

    getCartCollectionUrl: function() {
      return Settings.url('compuzz', 'v2/cart');
    },

    getCartCountUrl: function() {
      return Settings.url('base', 'v2/cart/rowCount', null, true);
    },

    getTotalPrice: function(ctx) {
      return $.ajax({
        url: Settings.url( 'compuzz', 'v2/cart/total'),
        contentType: false,
        context: ctx
      });
    },

    validate: function(ctx) {
      return $.ajax({
        type: 'PUT',
        url: Settings.url( 'compuzz', 'v2/cart/validate' + (Settings.get('autoLogin') ? '?fromReseller=true':'')),
        contentType: false,
        context: ctx
      });
    },

    book: function(ctx) {
      return $.ajax({
        type: 'PUT',
        url: Settings.url( 'compuzz', 'v2/cart/book'),
        contentType: false,
        context: ctx
      });
    }

  };

  return _.extend(CartService, CommonService);
});
