define('services/end-users-service',[
  'jquery',
  'core/utils/settings'
], function ($, Settings) {
  'use strict';

  var endUsers = {

    getRowCount: function ( token, searchValue ) {
      return $.ajax( {
        url: Settings.url( 'compuzz', 'endusers.json/rowCount', { searchValue: searchValue } )
      } );
    },

    getList: function ( first, pageSize, searchValue ) {
      return $.ajax( {
        url: Settings.url( 'compuzz', 'endusers.json/endusers',
          {
            first:    first || 0,
            pageSize: pageSize || Settings.get('pageSize'),
            searchValue: searchValue
          } )
      } );
    },

    // Get list of end-users for selected agent
    getListByAgent: function ( agentSecId ) {
      return $.ajax( {
        url: Settings.url( 'compuzz', 'agents.json/' + agentSecId + '/endusers' )
      } );
    },

    // Get user Info
    getSingle: function ( secId, ctx ) {
      return $.ajax( {
        url: Settings.url( 'compuzz', 'endusers.json/' + secId ),
        context: ctx
      } );
    },

    // Get user Info
    getSingleByCode: function ( endUserCode, ctx ) {
      return $.ajax( {
        url: Settings.url( 'compuzz', 'endusers.json/code/' + endUserCode ),
        context: ctx
      } );
    },

    // Get user Info
    getByTokenId: function (ctx) {
        return $.ajax({
            url: Settings.url('compuzz', 'endusers.json'),
            context: ctx
        });
    },

    // Update user Info
    update: function ( data ) {
      return $.ajax( {
        type: 'PUT',
        data: data,
        dataType: 'json',
        contentType: 'application/json',
        url: Settings.url( 'compuzz', 'endusers.json' ),
        statusCode: {
          409: function () {
            bootbox.alert( '<p class="text-warning text-center"><strong>The code must be unique</strong></p>' );
          }
        }
      } );
    },

    // Create Agents Info
    create: function ( data ) {
      return $.ajax( {
        type: 'POST',
        data: data,
        dataType: 'json',
        contentType: 'application/json',
        url: Settings.url( 'compuzz', 'endusers.json' ),
        statusCode: {
          409: function () {
            bootbox.alert( '<p class="text-warning text-center"><strong>The code must be unique</strong></p>' );
          }
        }

      } );
    },

    // Delete enduser
    delete: function ( secId ) {
      return $.ajax( {
        type: 'DELETE',
        url: Settings.url( 'compuzz', 'endusers.json/' + secId )
      } );
    },

    // Post new image in Agents-Profile-Tab-Image
    addImage: function ( formdata, secId, type ) {
      return $.ajax( {
        type: 'POST',
        data: formdata,
        dataType: 'json',
        url: Settings.url( 'compuzz', 'aprofile.json/' + secId + '/upload', { type: type } ),
        processData: false,
        contentType: false
      } );
    },

    getCorporateTemplates: function ( endUserId, first, pageSize ) {
      return $.ajax( {
        url: Settings.url( 'compuzz', 'commands.json/advancedSearch', {
          first:    first || 0,
          pageSize: pageSize || Settings.get('pageSize'),
          endUserId: endUserId,
          types: 'corporateTemplate'
        } )
      } );
    },

    countCorporateTemplates: function ( endUserId, productCode ) {
      return $.ajax( {
        url: Settings.url( 'compuzz', 'commands.json/advancedSearch/rowCount?tokenId=' + {
          endUserId: endUserId,
          types: 'corporateTemplate',
          productCode: productCode
        } )
      } );
    },

    removeCorporateTemplate: function ( poi ) {
      return $.ajax( {
        url: Settings.url( 'compuzz', 'commands.json/corporate/' + poi ),
        type: 'DELETE'
      } );
    },

    getTemplates: function ( endUserId ) {
      return $.ajax( {
        url: Settings.url( 'compuzz', 'endusers.json/' + endUserId + '/templates' )
      } );
    },

    getProducts: function ( searchValue, first, pageSize ) {
      return $.ajax( {
        url: Settings.url( 'compuzz', 'products.json/advancedSearch', {
          first: first || 0,
          pageSize: pageSize || Settings.get('pageSize'),
          globalSearchField: searchValue
        } )
      } );
    },

    linkEnduserImageInToken: function ( secId ) {
      return $.ajax( {
        type: 'PUT',
        dataType: 'json',
        contentType: 'application/json',
        url: Settings.url( 'compuzz', 'endusers.json/' + secId + '/linktoken' ),
        statusCode: {
          401: function () {
            bootbox.alert( '<p class="text-warning text-center"><strong>Wrong token id/strong></p>' );
          },
          209: function () {
            bootbox.alert( '<p class="text-warning text-center"><strong>No Image availiable</strong></p>' );
          }
        }
      } );
    },

    save: function ( data ) {
      var userId = data.userId,
        property = data.property,
        value = data.value;

      return $.ajax( {
        type: 'PUT',
        dataType: 'json',
        contentType: 'application/json',
        url: Settings.url( 'compuzz', 'endusers.json/endUserId=' + userId, {
          property: property,
          value: value
        } ),
        statusCode: {
          401: function () {
            bootbox.alert( '<p class="text-warning text-center"><strong>Wrong token id/strong></p>' );
          },
          209: function () {
            bootbox.alert( '<p class="text-warning text-center"><strong>No Image availiable</strong></p>' );
          }
        }
      } );
    },

    sendContactForm: function ( formdata ) {
      return $.ajax( {
        type: 'POST',
        data: JSON.stringify( formdata ),
        dataType: 'json',
        contentType: 'application/json',
        url: Settings.url( 'compuzz', 'v2/mails/send' )
      } );
    }
  };
  return endUsers;
});

