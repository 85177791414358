/**
 * Created by gsp on 20/03/2015.
 */

define( 'jqueryCompulogoPlugin',['jquery', 'spin', 'jqueryMagnificPopup'], function ( $, Spinner ) {

  //global objects
  var settings = {},
    logoId = '',
    $currentOption,
    vrpProductCode,
    vrpOptByProductData = {},
    updatedConfigurator = 0;
  //const
  var DEV_ENV = 'http://alpha.compuzz.com/';
  var DEV_NODE_ENV = 'x2png-ws/';
  var DEV_FILE_ENV = 'files/getfile/';
  var EXP_CHARS_TO_ESCAPE = '/([\\\/\[\]{}().*+?|^$])/g',
    EXP_ESCAPE_SPACE = '/%20/g',
    EXP_NO_START = '/^([^?]+)&/';
  var VRP_THUMB_WIDTH = 1000,
    VRP_THUMB_HEIGHT = 1000;

  // public methods
  var methods = {
    init: function ( options ) {
      var sel = $( this ), d = sel.data( 'compulogo' );
      if ( d ) {
        _setOptions( d, options );
        _render( sel, d );
      } else {
        _init( sel, options )
          .done( function () {
            _render( sel, sel.data( 'compulogo' ) );
          } ).fail( function ( jqXHR, textStatus, errorThrown ) {
            options.onError( jqXHR, textStatus, errorThrown );
          } );
      }
    },

    upload: function () {
      var sel = $( this );
      _initUpload( sel );
      return this.each( function () {
        _upload( $( this ) );
      } );
    },

    update: function ( options ) {
      var sel = $( this );
      _initUpload( sel );
      return this.each( function () {
        _update( $( this ), options );
      } );
    },

    destroy: function () {
      return this.each( function () {
        _destroy( $( this ) );
      } );
    }
  };

  $.fn.compuLogo = function ( method ) {
    if ( methods[method] ) {
      return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ) );
    } else if ( typeof method === 'object' || !method ) {
      return methods.init.apply( this, arguments );
    } else {
      arguments.onError( this, 'Method ' + method + ' does not exist on jQuery.compulogo' );
    }
    return false;
  };

  /**
   * initialization entry point
   * @param sel: jquery object
   * @param options: Settings parameters object
   * @private
   */
  function _init( sel, options ) {
    var deferred = new $.Deferred(),
      data,
      settings = {
        // These are the defaults.
        logger: false,
        serverURL: options.serverURL || DEV_ENV,
        productCode: "",
        tokenId: "",
        sessionId: "",
        imageViewerLoaderClass: (options.imageViewerLoaderTpl ? '.'+$(options.imageViewerLoaderTpl ).attr('class' ).split(' ' ).join('.') : '.img-compuzz-vrp-image-loader'),
        simpleMode: false
      };

    sel.data( 'compulogo', settings );

    _setOptions( sel.data( 'compulogo' ), options );

    _checkOptions( sel )
      .done( function () {
        data = sel.data( 'compulogo' );
        if ( !logoId && data.simpleMode === false) {
          _putTokenLogoId( sel )
            .done( function ( response ) {
              logoId = response ? response.value : '';
              $.cookie( 'CPULOGOID', logoId );
              if(data){
                data.onInitialized( sel );
              }
              deferred.resolve();
            } )
            .fail( function ( jqXHR, textStatus, errorThrown ) {
              deferred.reject( sel, '_putTokenLogoId: ', errorThrown );
            } );
        } else {
          data.onInitialized( sel );
          deferred.resolve();
        }
      } )
      .fail( function ( errorThrown ) {
        deferred.reject( sel, '_checkOptions: ', errorThrown );
      } );

    return deferred.promise();
  }

  /**
   * Initialize the upload options
   * @param sel:  Jquery object
   * @private
   */
  function _initUpload( sel ) {
    sel.data( 'compulogo', settings );
  }

  /**
   * Make upload file and link the uploaded into into the token
   * @param sel: Jquery object
   * @private
   */
  function _upload( sel ) {
    var data = sel.data( 'compulogo' ),
      iUrl = data.serverURL + data.URI + 'endusers.json/image/upload',
      params;
    //initialize the upload plugin
    sel.uploadFile( {
      url: iUrl,
      formData: { name: 'file' },
      maxFileSize: 1024 * 5000, //5MB max size
      onSubmit: function ( files ) {
        //modify the post upload action before submit the form
        data = sel.data( 'compulogo' );
        params = '?clearcph=true' + '&uid=' + files[0] + '&actions=rb&tokenId=' + data.tokenId;
        this.url += params;
      },
      onSuccess: function ( files, data ) {
        //On upload success link the new last end-user image with the token Id
        _putTokenLogoId( sel )
          .done( function ( response ) {
            logoId = response.value;
            $.cookie( 'CPULOGOID', logoId );
            _resetCache( sel );

          } )
          .fail( function ( errorThrown ) {
            data.onError( sel, '_checkOptions: ', errorThrown );
          } );

      }

    } );
  }

  /**
   * Update all configurators when an image upload is done
   * @param sel; Jquery object
   * @param options: Option object which contains additional data
   * @private
   */
  function _update( sel, options ) {
    var data = sel.data( 'compulogo' );
    //On upload success link the new last end-user image with the token Id
    logoId = options.fileId;
    newLogoId = $.cookie( 'CPULOGOID' );
    $.cookie( 'CPULOGOID', logoId );
    _resetCache( sel );
  }

  /**
   * Check plugin options before processing
   * @param sel: jquery object
   * @returns {*} Promise object for asynchronous call
   * @private
   */
  function _checkOptions( sel ) {
    var deferred = new $.Deferred(),
      data = sel.data( 'compulogo' );

    if ( data && data.URI && data.URI !== '' ) {
      if ( data.tokenId ) {
        deferred.resolve();
      } else if ( data.sessionId && !data.tokenId ) {
        _getAuthentification( sel )
          .done( function ( response ) {
            data.tokenId = settings.tokenId = response.tokenId;
            sel.data( 'compulogo', data );
            deferred.resolve();
          } ).fail( function ( jqXHR, textStatus, errorThrown ) {
            deferred.reject( 'Wrong sessionId Id: ', errorThrown );
          } );
      } else {
        deferred.reject( 'Wrong token Id' );
      }
    } else {
      deferred.reject( 'Wrong server url' );
    }

    return deferred.promise();
  }

  /**
   * Render the vrp jquery component
   * @param sel: jquery object
   * @param options: jquery object settings
   * @private
   */
  function _render( sel, options ) {
    _getData( sel )
      .done( function ( response ) {
        //Plugin chain
        sel.each( function ( index, item ) {
          if ( response ) {
            //Check if the data-product code is available into compuzz data base
            vrpProductCode = $( item ).attr( 'data-product' );
            if ( response === 'initialized' ) {
              options.onError( sel,
                'Plugin chain: Product: ' + vrpProductCode + ' already initialized ' );
            } else if ( vrpOptByProductData[vrpProductCode] ) {
              //Check if the vrp jquery object is already initialized
              if ( !vrpOptByProductData[vrpProductCode].initialized ) {
                _generateBlock( sel, $( item ) );
              }
            } else {
              $( item ).find( '.img-compuzz-vrp-image-loader' ).hide();
              $( item ).addClass( 'div-compuzz-vrp-product-content-block-product-not-found' );
              options.onError( sel, 'Plugin chain: Products: ' + vrpProductCode + ' not available ' );
            }
          } else {
            options.onError( sel, 'Plugin chain: Products not available ' );
          }
        } );
        //Load only the vrp configurator not already initialized
        var vrpComponentNotInitialized = sel.not( '.div-compuzz-vrp-product-content-block-product-not-found' ).not( '[data-initialized=true]' ).find( '.div-compuzz-vrp-product-content-block' ).not('.div-compuzz-vrp-product-content-block-img-load-error');
        if ( vrpComponentNotInitialized.length > 0 ) {
          _loadConfigurators( sel, vrpComponentNotInitialized );
        }

        return sel;
      } )
      .fail( function ( jqXHR, textStatus, errorThrown ) {
        options.onError( sel, '_getData: ' + errorThrown );
      } );
  }

  /**
   * Get main data
   * @param sel: jquery object
   * @param vrpProductCode: Product code
   * @returns {*}: Promise object for asynchronous call
   * @private
   */
  function _getData( sel ) {
    var deferred = new $.Deferred(),
      productLength = null,
      vrpOptDataByProducts;

    _generateLoaders( sel );
    _getProductData( sel )
      .done( function ( response ) {
        if ( response ) {
          vrpOptDataByProducts = response;
          productLength = _processData( sel, vrpOptDataByProducts );
        } else {
          productLength = 'initialized';
        }
        deferred.resolve( productLength );
      } )
      .fail( function ( jqXHR, textStatus, errorThrown ) {
        deferred.reject( jqXHR, textStatus, errorThrown );
      } );

    return deferred.promise();
  }

  /**
   * Generate loader for each plugin selector
   * @param sel: jQuery object
   * @private
   */
  function _generateLoaders( sel ) {
    var data = sel.data( 'compulogo'),
    opts;
    if(!data.imageViewerLoaderTpl) {
      opts = {
        lines: 12, // The number of lines to draw
        length: 10, // The length of each line
        width: 4, // The line thickness
        radius: 15, // The radius of the inner circle
        corners: 1, // Corner roundness (0..1)
        rotate: 0, // The rotation offset
        direction: 1, // 1: clockwise, -1: counterclockwise
        color: '#6e7375', // #rgb or #rrggbb or array of colors
        speed: 1, // Rounds per second
        trail: 60, // Afterglow percentage
        shadow: false, // Whether to render a shadow
        hwaccel: false, // Whether to use hardware acceleration
        className: data.imageViewerLoaderClass, // The CSS class to assign to the spinner
        zIndex: 2e9, // The z-index (defaults to 2000000000)
        top: '40%', // Top position relative to parent
        left: '50%' // Left position relative to parent
      };
      sel.each( function ( index, item ) {
        //Don't generate the loader for already initialized configurator
        var spinner = new Spinner( opts ).spin();
        $( item ).not( '[data-initialized=true]' ).append( spinner.el )
          .show();
      } );
    } else {
      sel.each( function ( index, item ) {
        //Don't generate the loader for already initialized configurator
        //TODO: improve that for avoid javascript injection
        $( item ).not( '[data-initialized=true]' ).append( $(data.imageViewerLoaderTpl ))
          .show();
      } );
    }

  }

  /**
   * Remove all cached items
   * @private
   */
  function _resetCache( sel ) {
    var data = sel.data( 'compulogo' );
    //remove all cached items except the last selected
    $( data.imageViewerContainer + '[data-cached][data-selected=false]' ).remove();
    $( '.div-compuzz-vrp-product-content-block' ).attr( 'data-whiteout-option-initialize', false );
  }

  /**
   * Generate the html block component
   * @param sel: jquery object
   * @param $selector: Current jquery object selector
   * @param vrpImgSrc: Configurator URI
   * @private
   */
  function _generateBlock( sel, $selector ) {
    var data = sel.data( 'compulogo' ),
      vrpProductCode = $selector.attr( 'data-product' ),
      timer,
      $blockGalleryItem,
      vrpOptionIds = vrpOptByProductData[vrpProductCode].optionsIds;

    var $vrpProductBlock = $selector.find( ".div-compuzz-vrp-product-content-block" );

    if ( $vrpProductBlock.length === 0 ) {
      $vrpProductBlock = $selector.append( '<div class="div-compuzz-vrp-product-content-block"></div>' )
        .find( '.div-compuzz-vrp-product-content-block' )
    }

    $vrpProductBlock.attr( 'data-whiteout-option-initialize', false )
      .click( function ( e ) {
        //Check if the current element click is only the magnifier block in this case we block the click
        // propagation( event
        if ( $( e.target ).is( $( this ).find( 'div[class="div-compuzz-vrp-product-content-box-gallery"] > a:first-child' ) ) ||
             $( e.target ).is( $( this ).find( 'div[class="div-compuzz-vrp-product-content-box-gallery"]' ) ) ) {
          e.preventDefault();
          e.stopImmediatePropagation();
        }
      } );

    if (!data.simpleMode && !data.productsList[vrpProductCode].disable) {
      $vrpProductBlock.on( {
        'mouseover': function ( e ) {
          timer = setTimeout( function () {
            //apply the logo on product whiteout options
            var $vrpProductBlockParent = $( e.currentTarget ).closest( '.div-compuzz-vrp-content-block' );
            var $optionBlock = $vrpProductBlockParent.find( data.optionsContainer );
            var $productBlock = $vrpProductBlockParent.find( '.div-compuzz-vrp-product-content-block' );
            var initialized = $productBlock.attr( 'data-whiteout-option-initialize' );
            if ( $optionBlock.children().length === 0 ) {
              //check if it is already initialized don't updated the image viewer
              if ( initialized === 'false' ) {
                _updateConfigurators( sel, $productBlock );
              }
            } else {
              if (  vrpOptByProductData[$vrpProductBlockParent.attr( 'data-product' )].logoId !== logoId  ) {
                //remove the last selected image cached before update
                $vrpProductBlockParent.find( data.imageViewerContainer +
                    '[data-cached][data-selected=true]' ).remove();
                vrpOptByProductData[$vrpProductBlockParent.attr( 'data-product' )].logoId = logoId;
                //preselect the last option or the catalogue picture for update
                var $selectedOption =  $optionBlock.find('[data-selected=true]' );
                $selectedOption = $selectedOption.length ? $selectedOption : $optionBlock.find('[data-option-index=0]');
                $selectedOption = $selectedOption.length ? $selectedOption : $productBlock;
                _updateConfigurators( sel, $selectedOption );
              }
            }
          }, 150 );
        },
        'mouseout': function () {
          clearTimeout( timer );
        }
      } );
    }
    if ( !$vrpProductBlock.hasClass( 'div-compuzz-vrp-product-content-block-img-load-error' ) ) {
      $blockGalleryItem =
      $vrpProductBlock.append( '<div class="div-compuzz-vrp-product-content-box-gallery"></div>' )
        .find( '.div-compuzz-vrp-product-content-box-gallery' );
    }
    //Append the configurator img
    var $imageBlock = $selector.find( data.imageViewerContainer );
    if ( $imageBlock.length === 0 ) {
      $imageBlock =
      $vrpProductBlock.append( '<img class="' + data.imageViewerContainer.replace( '.', '' ) + '"/>' )
        .find( data.imageViewerContainer );
      $vrpProductBlock.append( $imageBlock )
        .find( data.imageViewerContainer )
        .hide();
    }

    if (!data.productsList[vrpProductCode].disable) {
      //Append  the production options
      var $vrpOptBlock = $selector.find( data.optionsContainer );
      if ( $vrpOptBlock.length === 0 ) {
        $vrpOptBlock = $selector.append( '<div class="' + data.optionsContainer.replace( '.', '' ) + '"></div>' )
            .find( data.optionsContainer )
      }
      $vrpOptBlock
          .click( function ( e ) {
            //Check if the current element click is only the color option block in this case we block the
            // click propagation event
            if ( $( e.target ).is( $( this ).find( 'span[data-option-id]' ) ) ) {
              e.preventDefault();
              e.stopImmediatePropagation();
            }
          } );

      $.each( vrpOptionIds, function ( index, option ) {
        var $optionColor = $vrpOptBlock.append( '<span  data-option-index="' + index + '" data-option-id="' +
                option.id + '"></span>' )
            .find( 'span[data-option-id="' + option.id + '"]' );
        _generateOptionColor( sel, $optionColor, option );
      } );
    }
    //Initialize the gallery component
    _initializeVrpGallery( $blockGalleryItem );
  }

  /**
   * Enable to create the items gallery into zoom
   * @param $selector:
   * @private
   */
  function _initializeVrpGallery( $selector ) {

    if ( !$selector.is( ':parent' ) ) {
      $selector.append( '<a class="a-compuzz-vrp-product-content-gallery-item" href="' +
                        vrpOptByProductData[vrpProductCode].catPicture + '"></a> ' );
    }
    //Append and initialize the magnifier plugin
    $selector.magnificPopup( {
      type: 'image',
      delegate: 'a',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>', // markup
                                                                                                          // of
                                                                                                          // an
                                                                                                          // arrow
                                                                                                          // button

        tPrev: 'Previous (Left arrow key)', // title for left button
        tNext: 'Next (Right arrow key)' // title for right button
      },
      callbacks: {
        beforeOpen: function () {
          if ( $currentOption && $currentOption.attr( 'data-option-index' ) ) {
            this.goTo( parseInt( $currentOption.attr( 'data-option-index' ) ) );
            $currentOption = null;
          }
        }
      },
      closeBtnInside: true
    } );
  }

  /**
   * Enable to generate the item gallery into gallery container
   * @param $selector: Gallery container jquery selector
   * @param vrpProductCode: current product code
   * @private
   */
  function _generateVrpItemGallery( $selector, vrpProductCode ) {
    //Append the item into the configurator gallery
    var vrpsUri = vrpOptByProductData[vrpProductCode].vrpsUri,
      optionIds = vrpOptByProductData[vrpProductCode].optionsIds,
      optionsIdsLength = vrpOptByProductData[vrpProductCode].optionsIds.length,
      tempUri;

    $selector.children().remove();
    for ( var i = 0; i < optionsIdsLength; i++ ) {
      tempUri = _modURLParam( vrpsUri[optionIds[i].id], 'lid', logoId );
      $selector.append( '<a class="a-compuzz-vrp-product-content-gallery-item" href="' +
                        tempUri + '"></a> ' );
    }
    //add the catalogue picture  into the gallery only when is available
    if ( vrpOptByProductData[vrpProductCode].catPictureWithLogo ) {
      tempUri = _modURLParam( vrpOptByProductData[vrpProductCode].catPictureWithLogo, 'lid', logoId );
      $selector.append( '<a class="a-compuzz-vrp-product-content-gallery-item" href="' +
                        tempUri + '"></a> ' );
    }
  }

  /**
   * Generate the option square colors according the number of colors
   * @param sel: jQuery object
   * @param $optionColor: Options Jquery selector
   * @param option: Json option object
   * @private
   */
  function _generateOptionColor( sel, $optionColor, option ) {
    var timer,
      data = sel.data( 'compulogo' );
    $optionColor.css( 'backgroundColor', _decimalToHex( option.color1 ) );
    if ( option.color1 === '#ffffff' ) {
      $optionColor.addClass( 'color-white' );
    } else {
      $optionColor.removeClass( 'color-white' );
    }

    if ( option.color1 !== -1 && option.color2 !== -1 && option.color3 === -1 && option.color4 === -1 ) {
      $optionColor.addClass( 'colorClass2' );
      $optionColor.css({
        'border-top-color': 'transparent',
        'border-right-color': 'transparent',
        'border-bottom-color': _decimalToHex( option.color2 ),
        'border-left-color': 'transparent'
      });
    } else if ( option.color1 !== -1 && option.color2 !== -1 && option.color3 !== -1 && option.color4 === -1 ) {
      $optionColor.addClass( 'colorClass3' );
      $optionColor.css({
        'border-top-color': 'transparent',
        'border-right-color': 'transparent',
        'border-bottom-color': _decimalToHex( option.color2 ),
        'border-left-color': _decimalToHex( option.color3 )
      });
    } else if ( option.color1 !== -1 && option.color2 !== -1 && option.color3 !== -1 && option.color4 !== -1 ) {
      $optionColor.addClass( 'colorClass4' );
      $optionColor.css({
        'border-top-color': 'transparent',
        'border-right-color': _decimalToHex( option.color2 ),
        'border-bottom-color': _decimalToHex( option.color3 ),
        'border-left-color': _decimalToHex( option.color4 )
      });
    } else if ( option.color1 !== -1 && option.color2 === -1 && option.color3 === -1 && option.color4 === -1 ) {
      $optionColor.css( 'background-color', _decimalToHex( option.color1 ) );
    }
    if (!data.simpleMode && !data.productsList[vrpProductCode].disable) {
      $optionColor.on( {
        'mouseover': function () {
          var $target = $( this );
          timer = setTimeout( function () {
            //reset the selected span options data info
            $optionColor.parent().children().attr( 'data-selected', false );
            //set the selected span option data info
            $target.attr( 'data-selected', true );
            //check if the new image is uploaded
            var $vrpProductBlockParent = $target.parents( '.div-compuzz-vrp-content-block' );
            if (  vrpOptByProductData[$vrpProductBlockParent.attr( 'data-product' )].logoId !== logoId ) {
              //remove the last selected image cached before update
              $target.parents( '.div-compuzz-vrp-content-block' ).find( data.imageViewerContainer +
                  '[data-cached][data-selected=true]' ).remove();
              vrpOptByProductData[$vrpProductBlockParent.attr( 'data-product' )].logoId = logoId;
            }
            _updateConfigurators( sel, $target );
          }, 150 );
        },
        'mouseout': function () {
          clearTimeout( timer );
        }
      } );
    }
  }
  /**
   * Load all image configurator whit the first option available
   * @param sel: jquery object
   * @param $vrpProductBlocks:  Array productBlock jquery selector
   * @private
   */
  function _loadConfigurators( sel, $vrpProductBlocks ) {
    var data = sel.data( 'compulogo' ),
      $vrpProductBlock = $( $vrpProductBlocks[updatedConfigurator] ),
      $selector = $vrpProductBlock.closest( '.div-compuzz-vrp-content-block' ),
      vrpProductCode = $selector.attr( 'data-product' ),
      vrpImgSrc = vrpOptByProductData[vrpProductCode].catPicture,
      $imgLoader = $selector.find( data.imageViewerLoaderClass ).show();

    $vrpProductBlock.find( data.imageViewerContainer )
      .attr( 'src', vrpImgSrc )
      .one( 'load', function () {
        $( this ).attr( 'data-default', true ).show();
        $vrpProductBlock.removeClass( 'div-compuzz-vrp-product-content-block-img-load-error' );
        $imgLoader.hide();
        //Used for indicate which the vrp jquery item is rendered (used for the lazy loading)
        vrpOptByProductData[vrpProductCode].initialized = true;
        $selector.attr( 'data-initialized', true );
        updatedConfigurator++;
        //check if there are configurator to loaded
        if ( updatedConfigurator < $vrpProductBlocks.length ) {
          _loadConfigurators( sel, $vrpProductBlocks );
        } else {
          updatedConfigurator = 0;
          data.onCompleted( sel, $vrpProductBlocks, 'Configurators completed:' );
        }
      } ).error( function () {
        $vrpProductBlock.addClass( 'div-compuzz-vrp-product-content-block-img-load-error' );
        $imgLoader.hide();
        $( this ).hide();
        data.onError( sel, '_generateBlock: ', 'Configurator error: ' + vrpImgSrc );
        updatedConfigurator++;
        //check if there are configurator to loaded
        if ( updatedConfigurator < $vrpProductBlocks.length ) {
          _loadConfigurators( sel, $vrpProductBlocks );
        } else {
          updatedConfigurator = 0;
          data.onCompleted( sel, $vrpProductBlocks, 'Configurators completed:' );
        }
      } );
  }

  /**
   * Update all vrp product image according the selected option
   * @param sel: jquery object
   * @param $options:  Array options jquery selector
   * @private
   */
  function _updateConfigurators( sel, $options ) {
    var data = sel.data( 'compulogo' ),
      $option = $( $options[updatedConfigurator] ),
      $selector = $option ? $option.parents( '[data-initialized]' ) : $option,
      $vrpProductBlock = $selector.find( '.div-compuzz-vrp-product-content-block' ),
      $blockGalleryItem = $vrpProductBlock.find( '.div-compuzz-vrp-product-content-box-gallery' ),
      vrpProductCode = $selector.attr( 'data-product' ),
      vrpOptionId = $option.attr( 'data-option-id' ),
      $imgLoader = $selector.find( data.imageViewerLoaderClass ).show(),
      $cachedItem = $vrpProductBlock.find( data.imageViewerContainer +
                                           '[data-cached=true][data-cached-option-id="' +
                                           vrpOptionId +
                                           '"]' );

    $currentOption = $option;
    //reset all data-selected value on the image viewer before set the new
    $selector.find( data.imageViewerContainer ).attr( 'data-selected', false );
    //update the image configurator whit the correct option selected
    _getConfiguratorURI( sel, vrpProductCode, vrpOptionId );
    if ( !$cachedItem.length ) {
      $( '<img class="' + data.imageViewerContainer.replace( '.', '' ) + '" >' )
        .attr( 'src', vrpOptByProductData[vrpProductCode].vrpUriThumb )
        .attr( 'data-cached', true )
        .attr( 'data-cached-option-id', vrpOptionId )
        .attr( 'data-selected', true )
        .one( 'load', function () {
          $vrpProductBlock.find( data.imageViewerContainer + '[data-cached=true][data-cached-option-id!="' +
                                 vrpOptionId + '"]' ).hide();
          $vrpProductBlock.removeClass( 'div-compuzz-vrp-product-content-block-img-load-error' )
            .attr( 'data-whiteout-option-initialize', true );
          var $item = $vrpProductBlock.find( data.imageViewerContainer + '[data-cached=true][data-cached-option-id="' +
                                             vrpOptionId + '"]' );
          if ($item.length) {
            $item.remove();
          }
          $vrpProductBlock.append( $( this ).show() );
          $imgLoader.hide();
          updatedConfigurator++;
          //check if there are configurator to loaded
          if ( updatedConfigurator < $options.length ) {
            _updateConfigurators( sel, $options );
          } else {
            updatedConfigurator = 0;
            data.onCompleted( sel, $options, 'Options Updated: configurator completed' );
          }
          $vrpProductBlock.find( data.imageViewerContainer + '[data-default=true]' ).hide();
          _generateVrpItemGallery( $blockGalleryItem, $selector.attr( 'data-product' ) );
        } ).error( function () {
          $vrpProductBlock.addClass( 'div-compuzz-vrp-product-content-block-img-load-error' );
          $imgLoader.hide();
          $vrpProductBlock.find( data.imageViewerContainer ).hide();
          data.onError( sel, '_generateBlock: ',
            'Configurator error: ' + vrpOptByProductData[vrpProductCode].vrpsUri );
          updatedConfigurator++;
          //check if there are configurator to loaded
          if ( updatedConfigurator < $options.length ) {
            _updateConfigurators( sel, $options );
          } else {
            updatedConfigurator = 0;
            data.onCompleted( sel, $options, 'Configurators completed:' );
          }
        } );
    } else {
      $vrpProductBlock.find( data.imageViewerContainer + '[data-default=true]' ).hide();
      $vrpProductBlock.find( data.imageViewerContainer + '[data-cached=true]').hide();
      $cachedItem.attr( 'data-selected', true ).show();
      $imgLoader.hide();
      $vrpProductBlock.find( 'a[class=a-compuzz-vrp-product-content-gallery-item]' )
        .attr( 'href', vrpOptByProductData[vrpProductCode].vrpsUri );
      _generateVrpItemGallery( $blockGalleryItem, $selector.attr( 'data-product' ) );
    }
  }

  /**
   * Get compuologo file id
   * @param sel: jquery object
   * @returns {*}: Promise object for asynchronous call
   * @private
   */
  function _putTokenLogoId( sel ) {
    var data = sel.data( 'compulogo' ),
      params = '?tokenId=' + data.tokenId;

    return $.ajax( {
      url: data.serverURL + data.URI + 'endusers.json/linklastlogototoken' + params,
      type: 'GET',
      dataType: 'json',
      processData: false,
      contentType: 'text/plain'
    } );
  }

  /**
   * Get the authentication access from the session id  if it is provide
   * @param sel: jquery object
   * @returns {*}: Promise object for asynchronous call
   * @private
   */
  function _getAuthentification( sel ) {
    var data = sel.data( 'compulogo' );

    return $.ajax( {
      url: data.serverURL + data.URI + 'sessions.json/token/createBySessionId/' + data.sessionId,
      type: 'GET',
      dataType: 'json',
      contentType: 'text/plain'
    } );
  }

  /**
   * Index the option by product code
   * @param sel: jQuery Object
   * @param vrpOptDataByProducts: server response option indexed by the product code
   * @returns {number}: Number of product to processed
   * @private
   */
  function _processData( sel, vrpOptDataByProducts ) {
    var data = sel.data( 'compulogo' ),
      productIds = Object.keys( vrpOptByProductData ),
      realProductIds = Object.keys( vrpOptDataByProducts ),
      productId = '',
      options,
      tempUri = '',
      firstItem,
      productIdsLength = productIds.length,
      optionIdsLength = 0,
      productFound = false,
      realProductIdsLength = realProductIds.length,
      params =
        'lid=' + logoId +
        '&mw=' + 800 +
        '&mh=' + 600 +
        '&tokenId=' + data.tokenId,
      vrpOptByProductDataTemp;

    //remove not found compuzz product
    while ( productIdsLength-- ) {
      realProductIdsLength = realProductIds.length;
      productFound = false;
      while ( realProductIdsLength-- && !productFound ) {
        if ( productIds[productIdsLength].toUpperCase() ===
             realProductIds[realProductIdsLength].toUpperCase() ) {
          productFound = true;
        }
      }
      if ( !productFound && vrpOptByProductData[productIds[productIdsLength]].initialized === false ) {
        productFound = false;
        delete  vrpOptByProductData[productIds[productIdsLength]];
        productIds.splice( productIdsLength, 1 );

      }
    }

    productIdsLength = productIds.length;

    //Create the custom object dictionary with the real product
    while ( productIdsLength-- ) {
      productId = productIds[productIdsLength];
      vrpOptByProductDataTemp = vrpOptByProductData[productId];
      if ( vrpOptByProductDataTemp && vrpOptByProductDataTemp.initialized === false ) {
        options = vrpOptDataByProducts[productId].options || [];
        vrpOptByProductDataTemp.optionsIds = options;
        vrpOptByProductDataTemp.catPicture =
        data.serverURL + DEV_FILE_ENV + vrpOptDataByProducts[productId].cataloguePictureUid + '?action=res&params=t';
        optionIdsLength = options.length;
        tempUri = data.serverURL + DEV_NODE_ENV + 'product/' + productId + '/compuLogo?options=';
        vrpOptByProductDataTemp.catPictureWithLogo = tempUri + params;
        vrpOptByProductDataTemp.vrpsUri = {};
        for ( var i = 0; i < optionIdsLength; i++ ) {
          vrpOptByProductDataTemp.vrpsUri[options[i].id] = tempUri + options[i].id + '&' + params;
          if ( i === 0 ) {
            firstItem = vrpOptByProductDataTemp.vrpsUri[options[i].id];
          }
        }

        if ( !optionIdsLength ) {
          vrpOptByProductDataTemp.vrpUriThumb = vrpOptByProductDataTemp.catPicture;
        } else {
          vrpOptByProductDataTemp.vrpUriThumb = firstItem;
        }
        vrpOptByProductDataTemp.vrpUriThumb =
        _modURLParam( vrpOptByProductDataTemp.vrpUriThumb, 'mw', VRP_THUMB_WIDTH );
        vrpOptByProductDataTemp.vrpUriThumb =
        _modURLParam( vrpOptByProductDataTemp.vrpUriThumb, 'mh', VRP_THUMB_HEIGHT );
        vrpOptByProductDataTemp.logoId = null;
      }
    }
    return productIds.length;
  }

  /**
   * Get the product configurator uri.
   * @param sel: jquery object
   * @param vrpProductCode: Product code
   * @param productOptions: Product options
   * @returns {string} Configurator URI
   * @private
   */
  function _getConfiguratorURI( sel, vrpProductCode, vrpOptionToApply ) {
    var vrpUri = '', data;
    data = vrpOptByProductData[vrpProductCode];

    if(data) {
      if ( vrpOptionToApply ) {
        vrpUri = data.vrpsUri[vrpOptionToApply];
      } else {
        vrpUri = data.catPictureWithLogo;
      }
      vrpUri = _modURLParam( vrpUri, 'lid', logoId );

      vrpUri = _modURLParam( vrpUri, 'mw', VRP_THUMB_WIDTH );
      vrpUri = _modURLParam( vrpUri, 'mh', VRP_THUMB_HEIGHT );
      data.vrpUriThumb = vrpUri;
    }
    return vrpUri;
  }

  /**
   * Get the product data
   * @param sel: jquery object
   * @returns {*} Promise object for asynchronous call
   * @private
   */
  function _getProductData( sel ) {
    var deferred = new $.Deferred(),
      data = sel.data( 'compulogo' ),
      vrpProductCodes = '',
      vrpProductCode = '',
      params = '',
      vrpOptByProductDataTemp,
      initialized = false;

    sel.each( function ( index, item ) {
      vrpProductCode = $( item ).attr( 'data-product' );
      if ( vrpProductCode ) {
        initialized = $( item ).attr( 'data-initialized' ) || false;
        vrpOptByProductDataTemp = vrpOptByProductData[vrpProductCode] || {};
        if ( vrpOptByProductDataTemp ) {
          vrpOptByProductDataTemp.initialized = initialized;
          $( item ).attr( 'data-initialized', initialized );
          vrpOptByProductData[vrpProductCode] = vrpOptByProductDataTemp;
        }
        //asked via the rest full api only the product opition for  the uninitialized jquery vrp component
        if ( !initialized ) {
          vrpProductCodes += '&codes=' + vrpProductCode;
        }
      }
    } );

    if ( vrpProductCodes !== '' && !data.productsList) {
      params = '?type=color' + vrpProductCodes + '&tokenId=' + data.tokenId;
      return $.ajax( {
        type: "GET",
        url: data.serverURL + data.URI + 'products.json/compulogodata' + params,
        contentType: 'text/plain'
      } );
    } else {
      deferred.resolve(data.productsList);
    }

    return deferred.promise();
  }

  /**
   * Merge Jquery data object and settings plugin
   * @param data: jquery data object
   * @param options: settings plugin
   * @private
   */
  function _setOptions( data, options ) {
    if ( !options ) {
      options = {};
      options.logger = true;
      options.onInitialized = _onInitialized;
      options.onCompleted = _onCompleted;
      options.onError = _debug;
      options.productsList = null;
      options.onScroll = false;
    } else {
      options.imageViewerContainer = options.imageViewerContainer || '.img-compuz-vrp-viewer';
      options.optionsContainer = options.optionsContainer || '.div-compuzz-colors-options-block';
      options.onScroll = options.onScroll || false;
      options.onInitialized = options.onInitialized || _onInitialized;
      options.onCompleted = options.onCompleted || _onCompleted;
      options.onError = options.onError || _debug;
    }
    settings = $.extend( settings, options );
    logoId = $.cookie( 'CPULOGOID' );
    data = $.extend( data, settings );
  }

  /**
   * Enable to remove component and unbind the event
   * @param sel: RootElement plugin
   * @private
   */
  function _destroy( sel ) {
    if ( sel ) {
      var data = $.extend(sel.data( 'compulogo' ), settings);
      sel.find( '.div-compuzz-vrp-product-content-block' ).off().remove();
      sel.find( data.optionsContainer + '> span' ).off().remove();
      sel.find( data.optionsContainer ).off().remove();
      $( window ).off( 'unload', 'window', _onWindowUnload );
      if( data.onScroll === true) {
        $( window ).off( 'scroll', 'window', _onWindowScroll );
      }
    }
  }

  /**
   * Get hexa value from decimal value
   * @param d: Decimal color value
   * @returns {string}: Hexa value converted
   * @private
   */
  function _decimalToHex( d ) {
    var hex = Number( d ).toString( 16 );

    hex = "000000".substr( 0, 6 - hex.length ) + hex;
    return "#" + hex;
  }

  /**
   * Set the new query parama into the url
   * @param url: Source url
   * @param paramName: Query param name
   * @param paramValue: Query param value
   * @returns {*}: New url updated
   * @private
   */
  function _modURLParam( url, paramName, paramValue ) {
    paramValue =
    paramValue !== undefined ? encodeURIComponent( paramValue ).replace( EXP_ESCAPE_SPACE, '+' ) : paramValue;
    var pattern = new RegExp( '([?&]' + paramName.replace( EXP_CHARS_TO_ESCAPE, '\\$1' ) + '=)[^&]*' );
    if ( pattern.test( url ) ) {
      return url.replace(
        pattern,
        function ( $0, $1 ) {
          return paramValue !== undefined ? $1 + paramValue : '';
        }
      ).replace( EXP_NO_START, '$1?' );
    }
    else if ( paramValue !== undefined ) {
      return url + (url.indexOf( '?' ) + 1 ? '&' : '?') + paramName + '=' + paramValue;
    }
    else {
      return url;
    }
  }

  /**
   * Get the specific query param value from the url
   * @param name: Param name
   * @returns {string|null}: Value of the query param
   * @private
   */
  function _getURLParameter( urlToParse, name ) {
    var url = decodeURIComponent( (new RegExp( '[?|&]' + name + '=' +
                                               '([^&;]+?)(&|#|;|$)' ).exec( urlToParse ) ||
                                   ["", ""])[1].replace( /\+/g, '%20' ) );
    return url || null;
  }

  /**
   * Called when the plugin is initialized (options for rest full api ar set)
   * @param sel: jquery object
   * @private
   */
  function _onInitialized( sel ) {
    _debug( sel, 'compuLogo plugin Initialized: ' + sel.data( 'compulogo' ) );
  }

  /**
   * Called when all jquery vrp component found are completed rendering
   * @param sel: jquery vrp component
   * @param $selectors: jquery object updated
   * @param msg: optional message trace
   * @private
   */
  function _onCompleted( sel ) {
    var data = sel.data( 'compulogo'), $vrpSelectors;

    $( window ).on( 'unload', _onWindowUnload );
    if(data && data.onScroll === true) {
      $( window ).on( 'scroll', _onWindowScroll(sel));
    }
  }

  function _onWindowUnload() {
    $.removeCookie( 'CPULOGOID', null, { path: '/' } );
  }

  function _onWindowScroll(sel) {
    var $vrpSelectors = $( sel.selector ).not( sel );
    if ( $vrpSelectors.length ) {
      $vrpSelectors.compuLogo();
    }
  }

  /**
   * Enable to trace into the plugin
   * @param sel: jquery object
   * @param obj: Message to print
   * @private
   */
  function _debug( sel, msg, thrownError ) {
    var data = sel.data( 'compulogo' );

    if ( data && data.debug ) {
      if ( window.console && window.console.log ) {
        if ( thrownError ) {
          window.console.error( thrownError );
        } else {
          window.console.log( msg );
        }
      } else {
        if ( thrownError ) {
          throw thrownError;
        }
      }
    }
  }
} );
