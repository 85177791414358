/**
 * Created by GSP on 16/02/2016.
 */
define('jqgridBoot',[
  'jquery',
  'underscore',
  // grid
  'jqgrid/grid.base',
  'jqgrid/grid.celledit',
  'jqgrid/grid.common',
  'jqgrid/grid.filter',
  'jqgrid/grid.formedit',
  'jqgrid/grid.grouping',
  'jqgrid/grid.import',
  'jqgrid/grid.inlinedit',
  'jqgrid/grid.jqueryui',
  'jqgrid/grid.pivot',
  'jqgrid/grid.subgrid',
  'jqgrid/grid.treegrid',
  'jqgrid/jqDnR',
  'jqgrid/jqModal',
  'jqgrid/jquery.fmatter',
  'jqgrid/grid.utils',
  'jqgrid/addons/ui.multiselect'

], function ($, _) {
  'use strict';
  console.log('jqGrid loaded!');
});
