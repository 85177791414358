define('core/utils/page-configurator',['jquery'], function ($) {
    var config,
        configurator = {
            setConfig: function (data) {
                config = data;
            },
            getConfig: function () {
                return config;
            }
        };

    return configurator;
});

