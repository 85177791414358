define('extensions/router',['backbone'], function (Backbone) {
    "use strict";
    var Router,
        router;

    Router = Backbone.Router.extend({});

    router = new Router();
    Backbone.history.start();

    return {
        initialize: function (app) {
          app.sandbox.router = router;
          app.sandbox.router.history = {};
          _.extend(app.sandbox.router.history, Backbone.history);
        }

    };
});
