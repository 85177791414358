define('__component__$orders-page@default/main',[
  'mixins/page-component',
  'backbone',
  'underscore',
  'jquery',
  'module',
  'core/utils/settings',
  './views/orders-view',
  'services/session-service',
  'services/end-users-service',
  'services/orders-service',
  'models/product-item',
  'utils/url',
  'utils/color'
], function (PageComponentMixin, Backbone, _, $, module, Settings, OrdersView, SessionService, EndUsersService, OrdersService, ProductItem, UrlUtil, ColorUtil) {
  'use strict';

  var componentConfig, res;

  componentConfig = {
    pageRoutePath: {
      'orders(/)(:poi)(/validated)': 'orderDetail'
    },

    module: module,
    templates: ['tpl', 'templates/success-modal-tpl'],
    View: OrdersView,

    orderDetail: function(poi) {
      this.productModel = new ProductItem();
      this.getOverviewDetails(poi);
    },

    fetchModel: function (result) {
      var that = this,
        productPictureSecIds;
      if (result) {
        this.productModel.set('code', result.productCode);
        this.productModel.fetch({
          success: function (model) {
            productPictureSecIds = model.get('productPictureSecIds');
            that.renderComponent(result, that.parseImagesByVariant(model, productPictureSecIds, result.variant));
          }
        });
      }
    },

    parseImagesByVariant: function (model, productPictureSecIds, variant) {
      var productPictures = model.get('productPictures'),
        filteredPictures = [];

      if (!variant) {
        return productPictureSecIds;
      } else {
        _.each(productPictureSecIds, function(item){
          var picture = _.findWhere(productPictures, {secId: item});
          if (picture.variant === variant || !picture.variant || !variant) {
            filteredPictures.push(item);
          }
        });
      }

      return filteredPictures;
    },

    renderComponent: function(result, productPictureSecIds) {
      if (result) {
        this.productCode = result.productCode;
        this.setConfig(
          {
            item: result,
            isPersonalized : result.compositions.length > 0,
            images: this.createGallery(productPictureSecIds),
            proofSheetLink: Settings.serverURL + 'compuzz/PdfGenerator?poi=' + this.purchaseOrderId + '&docType=prf&lang=' +
                            Settings.get('lang'),
            util: ColorUtil,
            settings: Settings});
        this.invokeWithCallbacks('render');
        this.view.initGallery();
      }
    },

    getOverviewDetails: function (poi) {
      var that = this;

      this.purchaseOrderId = poi || UrlUtil.parseQueryString().purchaseOrderId;

      if (this.purchaseOrderId) {
        OrdersService.getSingle(this.purchaseOrderId)
          .then(function (result) {
            that.fetchModel(result);
          });
      }
    },

    createGallery: function (productPictureSecIds) {
      var that = this,
        itemOptions = [];

      if (productPictureSecIds.length !== 0) {
        _.each(productPictureSecIds, function(secId) {
          itemOptions.push({
            url: Settings.url('x2png', 'orders/' + that.purchaseOrderId + '/products/' + that.productCode +
            '/configurator?productPictureSecId=' + secId)
          });
        });
      }
      return itemOptions;
    },

    sendContactForm: function(message, to) {
      var that = this,
        data = {};

      data.message = message;
      data.subject = Settings.url('compuzzHtmlCustomization', 'oloSessionId/' + Settings.get('token').sessionId + '/productCode/' + this.productCode + '/purchaseOrderId/' +
      this.purchaseOrderId);
      data.to = to;

      EndUsersService.sendContactForm(data).then(function () { that.view.showSuccessEmailSendModal(); });
    },

    goToHomePage: function() {
      this.sandbox.router.navigate(
        Settings.get('originalURL') || 'personalize', {trigger: true});
    },

    goToOrderProcessPage: function() {
      var sessionId = Settings.get('token').sessionId,
        productCode = this.productCode,
        purchaseOrderId = this.purchaseOrderId;

      SessionService.validateSessionId(sessionId, this ).done( _.bind(function(){
        this.sandbox.router.navigate('orderprocess/order/' +  purchaseOrderId + '/product/' + productCode + '/session/' + sessionId, {trigger: true, replace: true});
      }, this));
    }
  };

  res = _.extend({}, PageComponentMixin);
  res = _.extend(res, componentConfig);

  return res;
});
define('__component__$orders-page@default', ['__component__$orders-page@default/main'], function (main) { return main; });

