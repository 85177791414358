/**
 * Created by dev on 16-02-16.
 */

define('__component__$login-page@default/views/login-view',['jquery',
        'underscore',
        'utils/form'], function($, _, FormUtils){
  'use strict';
  var ContactView = {
    events: {
      'submit .login-form': 'onSubmitLogin'
    },

    onSubmitLogin : function(e){
      e.preventDefault();
    },

    formValidator: function() {
      var self = this;
      var validator = this.$('#login-form').validate({
        submitHandler: function(){
          var formObj = FormUtils.getObj('login-form', self.$el);
          self.component.processLogin(formObj.login, formObj.password)
            .fail(function() {
              validator.showErrors({
                login: '',
                password: _.i18n('login.wrongId')
              });
            });
        },
        rules: {
          password: {
            required: true,
            maxlength: 250
          },
          login: {
            required: true,
            maxlength: 250
          }
        },
        messages: {
          login: {
            required: _.i18n('common.value.required'),
            maxlength: _.i18n('common.value.too.long')
          },
          password: {
            required: _.i18n('common.value.required'),
            maxlength: _.i18n('common.value.too.long')
          }
        }
      });
    }
  };

  return ContactView;
});
