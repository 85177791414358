define('__component__$register-page@default/main',[
    'mixins/page-component',
    'backbone',
    'module',
    'underscore',
    './views/register-view',
    'jquery',
    'core/utils/settings',
    'services/session-service',
    'services/caccount-service',
    'services/end-users-service',
    'core/utils/i18n',
    'utils/color'
], function (componentMixin, Backbone, module, _, RegisterView, $, Settings, SessionService) {
    'use strict';


    var componentConfig, res;

    componentConfig = {
        pageRoutePath: {
            'register': 'onShowRegister'
        },
        singleView: true,
        module: module,
        templates: ['tpl', 'templates/register-complete'],
        View: RegisterView,

        onShowRegister : function(){
          this.sandbox.helpers.loadCssForModule(this.module);
          this.invokeWithCallbacks('render');
          this.view.formValidator();
        },

        processRegister : function(email, tel, msg, company){
            var data = {email : email, tel : tel, message : msg, companyName: company};
            return SessionService.register(Settings.get('cAccountCode'), this, data)
                .done(_.bind(function(){
                    this.html(this.renderTemplate('templates/register-complete'));
                }, this)
            ).error(function(xhr, err, msg){
                    this.sandbox.logger.log('Error on register', msg);
                });
        }

    };

    res = _.extend({}, componentMixin);
    res = _.extend(res, componentConfig);

    return res;
});
define('__component__$register-page@default', ['__component__$register-page@default/main'], function (main) { return main; });

