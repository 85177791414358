/**
 * Created by GSP on 16/02/2016.
 */
define('services/orders-service',[
  'jquery',
  'underscore',
  'core/utils/settings',
  'services/common-service'
], function ($, _, Settings, CommonService) {
  'use strict';

  var Orders = {
    getOrderCollectionUrl: function() {
      return Settings.url('compuzz', 'commands.json/session/advancedSearch');
    },

    getOrderCountUrl: function() {
      return Settings.url('base', 'commands.json/session/advancedSearch/rowCount', null, true);
    },

    getStatuses: function(ctx) {
      return $.ajax({
        url: Settings.url( 'compuzz', 'commands.json/command/status'),
        contentType: false,
        dataType: 'json',
        context: ctx
      });
    },

    getSingle: function ( purchaseOrderId, ctx ) {
      return $.ajax( {
        type: 'GET',
        url: Settings.url( 'compuzz', 'commands.json/' + purchaseOrderId ),
        contentType: false,
        context: ctx
      } );
    },

    deleteOrder: function(purchaseOrderId, ctx) {
      return $.ajax({
        type: 'DELETE',
        url: Settings.url( 'compuzz', 'commands.json/' + purchaseOrderId),
        contentType: false,
        context: ctx
      });
    }

  };

  return _.extend(Orders, CommonService);
});
