/**
 * Created by GSP on 3/03/2016.
 */
define('utils/form',['jquery', 'underscore'], function($, _){
  'use strict';
  var FormUtil = {
    getObj: function(formId, $el) {
      var formObj = {};
      if(formId && $el) {
        var inputs = $el.find('#'+formId).serializeArray();
        $.each(inputs, function (i, input) {
          formObj[input.name] = input.value;
        });
      }
      return formObj;
    },

    fill: function(formId, $el, option) {
      if(formId && $el) {
        var $form = $el.find('#'+formId);
        option = option || { 'preFillClassName': '', 'model': {}};
        if(typeof option.preFillClassName === 'string' && option.model) {
          _.each(option.model, function(value, key){
            if(value && typeof value === 'string' || value === 'number') {
              $form.find('textarea[name="' + key + '"]' ).val(value);
              $form.find('input[name="' + key + '"]' ).val(value);
              $form.find('label[for ="' + key + '"]' ).addClass(option.preFillClassName);
            } else {
              $form.find('textarea[name="' + key + '"]' ).val('');
              $form.find('input[name="' + key+ '"]' ).val('');
              $form.find('label[for ="' + key+ '"]' ).removeClass(option.preFillClassName);
            }
          });
        }
      }
    }
  };

  return FormUtil;
});
