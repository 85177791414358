define('extensions/aura-components',{
  initialize: function(app) {
    var Emitter = require('eventemitter');
    var $ = require('jquery');
    var _ = require('underscore');
    var smoothScroll =  require('smooth-scroll');
    app.components.before('initialize', function() {
      this.mediator = new Emitter({
        wildcard: true,
        delimiter: '.',
        newListener: true,
        maxListeners: 20
      });
      this.remove = function(view) {
        var view = view || this.view;
        if(view) {
          view.remove();
        }
        if(this.mediator) {
          this.mediator.removeAllListeners();
        }
        this.sandbox.logger.log('stop');
      }
    });


    app.components.after('render', function() {
      //Enable Smooth scroll
      smoothScroll.init();
      //Enable materialize button waves
      Waves.displayEffect();
      //Enable materialize dropdown
      this.$find('.dropdown-button').dropdown({
        inDuration: 300,
        outDuration: 225,
        constrain_width: false, // Does not change width of dropdown to that of the activator
        hover: false, // Activate on hover
        gutter: 0, // Spacing from edge
        belowOrigin: false, // Displays dropdown below the button
        alignment: 'left' // Displays dropdown with edge aligned to the left of button
      });
      //jquery validator configuration
      //Set default validation config
      $.validator.setDefaults({
        errorClass: 'invalid',
        validClass: "valid",
        errorPlacement: function (error, element) {
          $(element)
            .closest("form")
            .find("label[for='" + element.attr("id") + "']")
            .attr('data-error', error.text());
        }
      });
      $.validator.addMethod(
        "regex",
        function(value, element, regexp) {
          if (regexp.constructor != RegExp)
            regexp = new RegExp(regexp);
          else if (regexp.global)
            regexp.lastIndex = 0;
          return this.optional(element) || regexp.test(value);
        },
        _.i18n( 'common.value.invalid' )
      );
      $.validator.addMethod("notEqualTo", function(value, element, param) {
        return this.optional(element) || value != $(param).val();
      }, _.i18n( 'common.value.notEqual' ));

      $.validator.addMethod("EqualTo", function(value, element, param) {
            return this.optional(element) || value == $(param).val();
      }, _.i18n( 'common.value.shouldBeEqual' ));

      $.validator.addMethod("maxLengthNullable", function(value, element, param) {
            return value ? value.length <= param : true
      }, _.i18n( 'common.value.too.long' ));
    });
  }
});


