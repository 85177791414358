define('__component__$orderprocess-page@default/main',[
  'underscore',
  'jquery',
  'mixins/page-component',
  'module',
  'core/utils/settings',
  'utils/url',
  'services/session-service'
], function(_, $, pageComponentMixin, module, Settings, UrlUtil, SessionService) {
  'use strict';

  var componentConfig, res;

  componentConfig = {
    pageRoutePath: {
      'orderprocess/order/:poi/product/:productCode(/session/:sessionId)': 'editOrder', //edit order for end-user
      'orderprocess/order/product/:productCode(/option/:option)': 'newOrder'
    },

    module: module,
    templates: 'tpl',

    newOrder: function(productCode, option) {
      this.title = _.i18n('common.orderProcess') + ': ' + '<b>' + productCode + '</b>';
      var queryParams = UrlUtil.parseQueryString();
      var catalogSecId = queryParams.catalogSecId;
      this.iframeUrl = Settings.url('compuzzHtmlCustomization',
          'oloSessionId/productCode/' + productCode + '?addtobasket=false&compuLogo=true');
      if(option) {
        this.iframeUrl += '&options=' + option + 'addtobasket=false&compuLogo=true';
      }

      this.createSession(
          {
            returnUrl:queryParams.ret, catalogSecId: catalogSecId
        });
    },

    editOrder: function(poi, productCode) {
      this.iframeUrl = Settings.url('compuzzHtmlCustomization', 'oloSessionId/productCode/' + productCode + '/purchaseOrderId/' + poi +  '?addtobasket=false&compuLogo=true');
      var queryParams = UrlUtil.parseQueryString();
      this.createSession( { returnUrl:queryParams.ret});
    },

    createSession: function(options) {
      options = options || {};
      var userCode = Settings.get('endUser') ? Settings.get('endUser').code : '',
      currentCAccount = Settings.get('currentCAccount'), returnUrl;

      if(currentCAccount && currentCAccount.securedLogotool === true) {
        returnUrl = location.origin + location.pathname + '#cart';
      } else {
        returnUrl = options.returnUrl || (location.origin + location.pathname + '#orders');
      }

      var session = {
            ttc : false,
            languageCode : Settings.get('lang'),
            webServiceUrl : 'random',
            userCode : userCode,
            clientCode : userCode,
            returnUrl : returnUrl,
            catalogSecId :  options.catalogSecId || ''
        },
        additionalQuery = '&parentUrl=' + encodeURIComponent(window.location.host + window.location.pathname);

      SessionService.createSession(session, this)
        .done(function (result) {
          this.iframeUrl = this.iframeUrl.replace('oloSessionId/', 'oloSessionId/' + result.sessionId + '/');
          this.iframeUrl = this.iframeUrl.replace('oloSessionId=', 'oloSessionId=' + result.sessionId);
          this.iframeUrl += additionalQuery;
          this.renderComponent();
        })
        .fail(function (xhr, err, msg) {
          this.sandbox.logger.log('Error while creating the session', msg);
        });
    },

    renderComponent: function() {
      this.setConfig({url: this.iframeUrl, title: this.title});
      this.invokeWithCallbacks('render');
      $( _.bind(function(){
        iFrameResize({
          log                     : false,
          checkOrigin             : false,
          scrolling               : false
        }, this.$el.find('#frameElement')[0]);
      }, this));
    }
  };

  res = _.extend({}, pageComponentMixin);
  res = _.extend(res, componentConfig);

  return res;
});
define('__component__$orderprocess-page@default', ['__component__$orderprocess-page@default/main'], function (main) { return main; });

