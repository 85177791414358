define('mixins/component',[
	'backbone',
	'underscore'
], function (Backbone, _) {
	return {
		initialize: function() {
			this.sandbox.helpers.loadCssForModule(this.module);
      this.invokeWithCallbacks('render');
		},

		render: function() {
			this.html(this.renderTemplate('tpl'));
		}
	};
});
