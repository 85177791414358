/**
 * Created by Vasyl 21012016
 */
define('services/filters-service',[
    'jquery',
    'core/utils/settings'
], function ($, settings) {
    'use strict';

    var filters = {
      getFilters: function (CatSecId) {
        return $.ajax({
          url: settings.url('compuzz', 'filters.json/catalogue/'+ CatSecId),
          contentType: 'application/json',
          type: 'GET',
          error: function () {
            console.log('Unable to get the filters for cataogue');
          }
        });
      }
    };
    return filters;
});
