define('extensions/aura-backbones',{
  initialize: function(app) {
    var Backbone = require('backbone');
    var _ = require('underscore');
    var $ = require('jquery');
    app.core.mvc =  Backbone;

    var Views = {};

    // Injecting a Backbone view in the Component just before initialization.
    // This View's class will be built and cached this first time the component is included.
    app.components.before('initialize', function(options) {
      var View = Views[options.ref];
      if (!View) {
        Views[options.ref] = View = Backbone.View.extend(this.View);
      }
      this.view = new View({ el: this.$el });

      Backbone.View.prototype.createChildView = function(ViewInstance, options) {
        if(!this.childrenView) {
          this.childrenView = [];
        }
        if( _.isArray(this.childrenView)) {
          var view = new ViewInstance(_.extend(options, {parent: this}));
          this.childrenView.push(view);
          return view;
        }
      };
      Backbone.View.prototype.remove = function(options) {
        var view,  maintainViewContainer = false;
        if(options) {
          view = options['view'] || this;
          maintainViewContainer = options['maintainViewContainer'] || false;
        } else {
          view = this;
        }
        if(view) {
          if(view.childrenView && _.isArray(view.childrenView) && !_.isEmpty(view.childrenView)) {
            this.removeChildView(view);
          } else {
            if(maintainViewContainer === false) {
              view.undelegateEvents();
              view.$el.removeData();
              view.stopListening();
              view.$el.remove();
            } else {
              view.$el.empty();
            }
            if(view.parent && view.parent.component) {
              view.parent.component.sandbox.logger.log('View', view.cid, 'removed');
            } else {
              app.logger.log('View', view.cid, 'removed');
            }
          }
        }
      };
      Backbone.View.prototype.removeChildView = function(view) {
        var view = view || this,
          childView = {};
        while ( view.childrenView.length ) {
          childView = view.childrenView.pop();
          childView.remove();
        }
      };

      this.view.sandbox = this.sandbox;
      this.view.component = this;
    });
  }
});

