/*global define*/

define('collections/product-items',[
  'underscore',
  'jquery',
  'backbone',
  'core/utils/settings',
  'models/product-item',
  'models/product-row-count'
], function (_, $, Backbone, Settings, ProductItem, ProductRowCountModel) {
  'use strict';

  var ProductItems = Backbone.Collection.extend({
    model: ProductItem,

    initialize: function() {
      this.rowCountModel = new ProductRowCountModel();
    },
    url: function (urlParams) {
      var first = urlParams.first,
        pageSize = urlParams.pageSize,
        productMarker = urlParams.filters.productMarker || urlParams.filters.productCode,
        language = urlParams.filters.language,
        catalogueSecId = urlParams.filters.catalogueSecId,
        loadProductCodeVariation = urlParams.loadProductCodeVariation,
        loadOptionCategories = urlParams.loadOptionCategories,
        filtersQuery = this.getFiltersQuery(urlParams);

      return  Settings.url('compuzz', 'products.json/advancedSearch',
        { globalSearchField: productMarker,
          first: (first * pageSize) || 0,
          pageSize: pageSize || Settings.get('pageSize'),
          loadProductCodeVariation: loadProductCodeVariation,
          loadOptionCategories: loadOptionCategories,
          lang: language || Settings.get('lang'),
          status: 0,
          catalogSecId: catalogueSecId}) + filtersQuery;
    },

    rowCount: function(urlParams) {
      return this.rowCountModel.fetch({
        url: this.rowCountModel.url(urlParams)
      });
    },

    //left menu options filters
    getFiltersQuery: function(urlParams) {
      var query = '',
        optionsFilters = urlParams.optionsFilters,
        filtersType = urlParams.filtersType;

      //options filters query
      if (typeof optionsFilters === 'object') {
        _.each(optionsFilters, function(filter){
          query += '&lstProductFiltersIds=' + filter.join(',');
        });
      }

      //filters type query
      if (filtersType) {
        query += '&productFilterTypeSecId=' + filtersType;
      }

      return query;
    }
  });

  ProductItems.singleInstance = new ProductItems();

  return ProductItems;
});
