/**
 * Created by GSP on 29/01/2016.
 */
define('__component__$delivery-page@default/views/delivery-view',['jquery',
        'underscore',
        'utils/form'], function($, _, FormUtil){
  'use strict';
  var DeliveryView = {
    events: {
      'click .delivery__button-back': 'backToPrev',
      'change #delivery__form__useInvoicingAddress': 'onUseInvoicingAddress',
      'submit .delivery__button-submit': 'onSubmitDeliveryData',
      'change #subject-to-vat': 'onChangeSubjectToVat'
    },

    onUseInvoicingAddress: function(e) {
      var self = this,
       $target = e ? $(e.currentTarget ) : this.$('#delivery__form__useInvoicingAddress'),
        $deliveryBlock =  this.$('.delivery__form.delivery__form__delivery' ),
        $invoicingBlock =  this.$('.delivery__form.delivery__form__invoicing' );
      if($target.is(':checked')) {
        $deliveryBlock.fadeOut('slow', 'linear', function(){
          $invoicingBlock.removeClass('m6' ).addClass('m12');
        });
        self.useInvocingAdress = true;
      } else {
        $invoicingBlock.fadeIn('slow', 'linear', function() {
          $invoicingBlock.removeClass('m12' ).addClass('m6');
          $deliveryBlock.fadeIn('slow', 'linear');
        });
        self.useInvocingAdress = false;
      }
    },

    /*jshint camelcase: false */
    formFilling: function() {
      FormUtil.fill('delivery__form', this.$el, {
        preFillClassName: 'active',
        model: {
          email: this.model.get('email'),
          subjectToVat: this.model.get('subjectToVat'),
          tva: this.model.get('vatNumber'),
          phone: this.model.get('tel'),
          tel: this.model.get('vatNumber'),
          company_name: this.model.get('name'),
          addressL1: this.model.get('addressL1'),
          addressL2: this.model.get('addressL2'),
          companyNumber: this.model.get('companyNumber'),
          zip: this.model.get('zip'),
          city: this.model.get('city'),
          comment: this.model.get('comment'),
          delivery_company_name: this.model.get('refDeliveryAddress').name,
          delivery_addressL1: this.model.get('refDeliveryAddress').addressL1,
          delivery_addressL2: this.model.get('refDeliveryAddress').addressL2,
          delivery_zip: this.model.get('refDeliveryAddress').zip,
          delivery_city: this.model.get('refDeliveryAddress').city
        }
      }) ;
    },

    checkVat: function(){
        var subjectToVat = this.model.get('subjectToVat') || false;
        this.$el.find('.vat-area').toggleClass('hidden',!subjectToVat);
        this.$el.find('#subject-to-vat').prop('checked', subjectToVat);
    },

    /*jshint camelcase: false */
    formValidator: function() {
      var self = this;
      var validator = this.$('#delivery__form').validate({
        submitHandler: function(){
          var formObj = FormUtil.getObj('delivery__form', self.$el ),
          invoicingAddress = null;
          self.$el.find('.delivery__button-submit').addClass('disabled');
          if(self.useInvocingAdress === true) {
            invoicingAddress = {
              name: formObj.company_name,
              addressL1: formObj.addressL1,
              addressL2: formObj.addressL2,
              zip: formObj.zip,
              city: formObj.city
            };
          }
          invoicingAddress = invoicingAddress || {
            name: formObj.delivery_company_name,
            addressL1: formObj.delivery_addressL1,
            addressL2: formObj.delivery_addressL2,
            zip: formObj.delivery_zip,
            city: formObj.delivery_city
          };
          self.component.saveData({
            vatNumber: formObj.tva,
            companyName: formObj.company_name,
            email: formObj.email,
            tel: formObj.phone,
            addressL1: formObj.addressL1,
            addressL2: formObj.addressL2,
            companyNumber: formObj.companyNumber,
            zip: formObj.zip,
            city: formObj.city,
            comment: formObj.comment,
            refDeliveryAddress: invoicingAddress
          }).done(
              function(){
                  self.component.validateCart();
              }
          ).fail(
              function(error){
                  self.enableConfirm();
                  validator.showErrors({
                      tva: error.responseJSON.label
                  });
              }
          );
        },
        rules: {
          email: {
            regex:/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            required: true,
            maxlength: 250
          },
          phone: {
            regex:/^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i,
            maxlength: 250
          },
          company_name:{
            required: true,
            maxlength: 250
          },
          tva: {
              maxlength: 250
          },
          addressL1: {
            required: true,
            maxlength: 250
          },
          addressL2:{
              maxLengthNullable: 250
          },
          zip:{
            required: true,
            maxlength: 250
          },
          city:{
            required: true,
            maxlength: 250
          },
          comment:{
              maxLengthNullable: 250
          },
          delivery_company_name:{
            required: true,
            maxlength: 250
          },
          delivery_addressL2:{
              maxLengthNullable: 250
          },
          delivery_zip:{
            required: true,
            maxlength: 250
          },
          delivery_city:{
            required: true,
            maxlength: 250
          },
          companyNumber: {
             maxLengthNullable: 250
          }
        },
        messages: {
          email: {
            required: _.i18n( 'common.value.required' ),
            maxlength: _.i18n( 'common.value.too.long' )
          },
          tva: {
            maxlength: _.i18n( 'common.value.too.long' )
          },
          phone: {
            maxlength: _.i18n( 'common.value.too.long' )
          },
          company_name: {
            required: _.i18n( 'common.value.required' ),
            maxlength: _.i18n( 'common.value.too.long' )
          },
          addressL1: {
            required: _.i18n( 'common.value.required' ),
            maxlength: _.i18n( 'common.value.too.long' )
          },
          addressL2: {
              maxLengthNullable: _.i18n( 'common.value.too.long' )
          },
          zip: {
            required: _.i18n( 'common.value.required' ),
            maxlength: _.i18n( 'common.value.too.long' )
          },
          city: {
            required: _.i18n( 'common.value.required' ),
            maxlength: _.i18n( 'common.value.too.long' )
          },
          comment: {
            maxLengthNullable: _.i18n( 'common.value.too.long' )
          },
          delivery_company_name: {
            required: _.i18n( 'common.value.required' ),
            maxlength: _.i18n( 'common.value.too.long' )
          },
          delivery_addressL2: {
            maxLengthNullable: _.i18n( 'common.value.too.long' )
          },
          delivery_zip: {
            required: _.i18n( 'common.value.required' ),
            maxlength: _.i18n( 'common.value.too.long' )
          },
          delivery_city: {
            required: _.i18n( 'common.value.required' ),
            maxlength: _.i18n( 'common.value.too.long' )
          },
          companyNumber: {
            maxLengthNullable: _.i18n( 'common.value.too.long' )
          }
        }
      });
    },

    onSubmitDeliveryData: function(e) {
      e.preventDefault();
    },

    onChangeSubjectToVat: function(e) {
        e.preventDefault();
        var $subjectToVat = this.$el.find('#subject-to-vat');
        this.model.set('subjectToVat',$subjectToVat.is(':checked'));
        this.$el.find('.vat-area').toggleClass('hidden',!($subjectToVat.is(':checked')));
    },

    enableConfirm: function(){
        this.$el.find('.delivery__button-submit').removeClass('disabled');
    },

    backToPrev: function () {
      this.component.goToCart();
    },

    showSuccessCartValidation: function () {
        this.component.goToSuccess();
    }


  };

  return DeliveryView;
});
