/**
 * Created by Matteo on 12/03/2016.
 */
define('__component__$viewport@default/views/header-view',['backbone',
        'jquery',
        'underscore',
        'utils/form',
        'core/utils/settings'],
  function(Backbone, $, _, FormUtil, Settings) {
    'use strict';
    var HeaderView = Backbone.View.extend({
      events: {
        'click #language-dropdown > li': 'onClickLanguage',
        'click #logout': 'onLogout',
        'click #change-password-link': 'onChangePasswordModal'
      },

      initialize: function(options) {
        this.parent = options.parent;
        this.render();
      },

      render: function() {
        var currentCAccount = Settings.get('currentCAccount'),
            languages;

        if (currentCAccount.refMainCAccountJson) {
          languages = currentCAccount.refMainCAccountJson.languageSelectionJson.languages || ['en'];
        } else {
          languages = currentCAccount.languageSelectionJson.languages || ['en'];
        }

        var templateData = {
          logoUrl:   currentCAccount.logo ? Settings.url('file', currentCAccount.logo) : 'images/no-image.png',
          securedLogoTool: currentCAccount.securedLogotool,
          homeUrl:  '#' + (Settings.get('originalURL') || 'personalize'),
          languages: languages
        };
        this.$el.html(this.parent.component.renderTemplate('templates/header-tpl', templateData));
        this.$('#button-collapse').sideNav({closeOnClick: true});
      },

      onClickLanguage: function(e) {
        var $selectedItem = $(e.currentTarget).find('span' );
        this.trigger('changeLanguage',$selectedItem.attr('lang'));
      },


      /*jshint camelcase: false */
      onChangePasswordModal: function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        var self = this;
        var $modal = $( '#modal__change-password' );
        $modal.html( this.parent.component.renderTemplate( 'templates/change-pwd-modal-tpl' ) );
        $modal.openModal({
          dismissible: true,
          opacity: 0.5,
          in_duration: 300,
          out_duration: 200,
          ready: function() {
            $modal.find('.change-password-confirm' ).off('click' ).on('click', function() {
              $modal.find('#change-password__form').submit();
            });
            $modal.find('#change-password__form' ).off('submit' ).on('submit', function(e) {
              e.preventDefault();
            });
            $modal.find('.change-password-cancel' ).off('click' ).on('click', function() {
              self.closeModalChangePassword();
            });
            self.formValidator();
          }
        });
      },

      onLogout : function(e){
        e.preventDefault();
        Settings.set('token', null);
        this.parent.component.goToLogin();
      },

      formValidator: function() {
        var self = this;
        $('#change-password__form').validate({
          submitHandler: function() {
            var formObj = FormUtil.getObj('change-password__form', $('body'));
            self.parent.component.changePassword( formObj['change-password__confirm-password'] ).done( function () {
              self.closeModalChangePassword();
            } );
          },
          rules: {
            'change-password__password': {
              required: true,
              maxlength: 250
            },
            'change-password__confirm-password': {
              required: true,
              maxlength: 250,
              EqualTo: '#change-password__password'
            }
          },
          messages: {
            'change-password__password': {
              required: _.i18n('common.value.required'),
              maxlength: _.i18n('common.value.too.long')
            },
            'change-password__confirm-password': {
              required: _.i18n('common.value.required'),
              maxlength: _.i18n('common.value.too.long')
            }
          }
        });
      },

      closeModalChangePassword: function() {
        $('#modal__change-password').closeModal();
      },

      setAccountName: function() {
        this.$('#my-account-link-content').text(Settings.get('endUser' ).login);
      },

      setCurrentLanguage: function() {
        var language = Settings.get('lang') || 'en';
        $('#language-dropdown-active' ).attr('lang', language).text(language);
      }


    });

    return HeaderView;
  });
