define('utils/color',[
  'core/utils/settings'
], function (Settings) {
  'use strict';

  var ColorUtil = {
    applyUserTheme: function () {
      var currentCAccount = Settings.get('currentCAccount'),
          colors;
      if ( !currentCAccount || !currentCAccount.colorSettings ) {
        colors = {
          generalColor: '#01BFA5',
          secondColor: '#FF8200',
          thirdColor: '#a71680'
        };
      } else {
        colors = currentCAccount.colorSettings;
      }

      var btnLabelColor = this.isLightColor(colors.secondColor) ? ' {color: #000000 !important; opacity: 0.87;}' : '{color: #FFFFFF !important} ',
          fontColor = this.isLightColor(colors.generalColor) ? ' {color: #000000 !important; opacity: 0.87;}' : '{color: #FFFFFF !important} ',
          fontThirdColor = this.isLightColor(colors.thirdColor) ? ' {color: #000000 !important; opacity: 0.87;}' : '{color: #FFFFFF !important} ',
          borderColor = '1px solid ' + colors.secondColor,
          boxShadow   = '0 1px 0 0 ' + colors.secondColor,
          checkboxStyle, buttonStyle, buttonLabelStyle, buttonFlatStyle,
          buttonFloatingStyle, dropDownStyle,
          linkStyle, linkThirdStyle, spanThirdStyle, spanStyle, slickStyle, generalBgStyle,titleStyle,
          waveEffect, inputStyle,
          loaderStyle, galleryStyle, fontStyle, footerStyle;

      checkboxStyle = '.checkbox-mt[type="checkbox"].filled-in:checked + label:after ' +
                      '{  border: 2px solid '+  colors.secondColor  + '!important ; background-color:' +  colors.secondColor  + '!important ;}';
      buttonStyle =  '.btn {background-color: ' + colors.secondColor + '!important}' +
                     '.btn:hover { background-color: ' + this.colorLuminance(colors.secondColor, 0.24) + '!important}';

      buttonLabelStyle = '.btn-label' + btnLabelColor;


      buttonFlatStyle = '.btn-flat  { background-color: inherit; border: inherit !important; text-decoration: inherit !important;}';

      buttonFloatingStyle = '.btn-floating {background-color: ' + colors.secondColor + '!important}' +
                            '.btn-floating:hover { background-color: ' + this.colorLuminance(colors.secondColor, 0.24) + '!important}';

      linkStyle = '.link-action' + fontColor + '.link-action:hover { opacity:  0.8 !important}';

      spanStyle = '.span-label' + fontColor;

      titleStyle = '.title-text {color: ' + (this.isLightColor(colors.thirdColor) ? '#000000' : colors.thirdColor)  + '}';

      dropDownStyle = '.dropdown-link {color: #000000 !important; opacity: 0.87 !important}';

      fontStyle = '.gn-text {color: #000000 !important; opacity: 0.87 !important}';

      // style for slider at step 3
      slickStyle = '.overview-container .img-container-smaller span.active {color: ' + colors.generalColor + '  }';

      // style for buttons at step 1 and 3 and header
      generalBgStyle =
      '.general-bg, .btn-grand, .progress {background-color: ' + colors.generalColor + ' !important }';

      // style for footer background color
      footerStyle = '.footer-dark {background-color: ' + colors.thirdColor + ' !important}';

      linkThirdStyle =  '.link-action-third' + fontThirdColor;

      spanThirdStyle =  '.span-label-third' + fontThirdColor;

      // style for loader at page 1
      loaderStyle = '.spinner-blue-only {border-color:' + colors.secondColor + '!important}';


      // style for buttons animation effect color and product block
      waveEffect = ' .waves-effect .waves-ripple {background-color: ' + colors.secondColor + '!important }';

      // style for input focus
      inputStyle =
      '.input-field .prefix.active {color: ' + colors.secondColor + '}' +
      'input[type=text]:focus:not([readonly]), input[type=password]:focus:not([readonly]), input[type=email]:focus:not([readonly]), ' +
      'textarea.materialize-textarea:focus {border-bottom: ' +
      borderColor + ' !important; box-shadow: ' + boxShadow +
      ' !important } input[type=text]:focus:not([readonly]) + label.active, input[type=email]:focus:not([readonly]) + label.active, ' +
      'input[type=password]:focus:not([readonly])  + label.active, ' +
      'textarea.materialize-textarea:focus + label.active {color: ' + colors.secondColor + '!important}';

      // styles for gallery tabs
      galleryStyle =
      '._compuzz.modal-upload-gallery.modal .upload-btn' + btnLabelColor +
      '.albums-tab.tab-name.nav-tab-link.active, .menu-tab.tab-name.nav-tab-link.active { border-bottom: 4px solid ' +
      colors.secondColor + ' !important }' +
      '.obj-name-item.nav-tab-link.obj-name.active { border-left: 4px solid ' + colors.secondColor + ' !important}' +
      '._compuzz.modal-upload-gallery.modal input[type="radio"] { border: 1px solid ' + colors.secondColor +
      ' !important }' +
      '._compuzz.modal-upload-gallery.modal input[type="radio"]:checked:before { background: ' + colors.secondColor +
      '!important }' +
      '._compuzz.modal-upload-gallery.modal input[type="radio"]:hover { box-shadow: 0 0 5px 0px ' +
      colors.secondColor + ' inset !important }' +
      '._compuzz.modal-upload-gallery.modal .delete-btns, ._compuzz.modal-upload-gallery.modal .save-changes {color: ' +
      colors.secondColor + ' !important }' +
      '._compuzz.modal-upload-gallery.modal .modal-content .progress-container .progress-bar { background: ' +
      colors.secondColor + '!important }';

      // here style tag is appended to body
      if ( $('#styleContainer').length ) {
        $('#styleContainer').html('&shy;<style>' + checkboxStyle + buttonStyle + buttonLabelStyle + buttonFlatStyle +
                                  buttonFloatingStyle + linkStyle + spanStyle + waveEffect + titleStyle +
                                  dropDownStyle + slickStyle + footerStyle + linkThirdStyle + spanThirdStyle + generalBgStyle +
                                  inputStyle + loaderStyle + galleryStyle + fontStyle + '</style>');
      } else {
        $('body').append('<div id="styleContainer">&shy;<style>' + checkboxStyle + buttonStyle + buttonLabelStyle +  buttonFlatStyle +
                         buttonFloatingStyle + linkStyle + spanStyle + waveEffect + titleStyle +
                         dropDownStyle + slickStyle + footerStyle + linkThirdStyle + spanThirdStyle + generalBgStyle +
                         inputStyle + loaderStyle + galleryStyle + fontStyle + '</style></div>');
      }
    },


    colorLuminance: function(hex, lum) {

      // validate hex string
      hex = String(hex).replace(/[^0-9a-f]/gi, '');
      if (hex.length < 6) {
        hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
      }
      lum = lum || 0;

      // convert to decimal and change luminosity
      var rgb = '#', c, i;
      for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i*2,2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
        rgb += ('00'+c).substr(c.length);
      }

      return rgb;
    },



    isLightColor: function (color) {

      var r,g,b,brightness,
        colour = color;

      if (colour.match(/^rgb/)) {
        colour = colour.match(/rgba?\(([^)]+)\)/)[1];
        colour = colour.split(/ *, */).map(Number);
        r = colour[0];
        g = colour[1];
        b = colour[2];
      } else if ('#' === colour[0] && 7 === colour.length) {
        r = parseInt(colour.slice(1, 3), 16);
        g = parseInt(colour.slice(3, 5), 16);
        b = parseInt(colour.slice(5, 7), 16);
      } else if ('#' === colour[0] && 4 === colour.length) {
        r = parseInt(colour[1] + colour[1], 16);
        g = parseInt(colour[2] + colour[2], 16);
        b = parseInt(colour[3] + colour[3], 16);
      }

      brightness = (r * 299 + g * 587 + b * 114) / 1000;

      if (brightness < 125) {
        return false;
      } else {
        return true;
      }
      return this;
    },

    decimalToHex : function (d) {
      var hex = Number(d).toString(16);
      hex = '000000'.substr(0, 6 - hex.length) + hex;
      return '#' + hex;
    }
  };

  return ColorUtil;
});

