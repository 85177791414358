/**
 * Created by dev on 08-03-16.
 */
/**
 * Created by BFR on 26/02/2016.
 */
define('__component__$welcome-area@default/views/welcome-view',['jquery',
    'underscore',
    'utils/form',
    'core/utils/settings'], function() {
    'use strict';
    var WelcomeView = { };
    return WelcomeView;
});
