define('mixins/page-component',[
  'underscore',
  'jquery',
  'core/utils/settings',
  'core/utils/page-configurator'
], function(_, $, settings, pageConfigurator) {
  var componentConfig,
      currentComponent, res;

  componentConfig = {
    templates: 'tpl',

    initialize: function() {
      this.sandbox.helpers.loadCssForModule(this.module);
      this.initPage();
    },

    render: function() {
      this.setCurrentPage();
      this.html(this.renderTemplate('tpl', this.getConfig()));
      this.$el.show();
      if(!this.singleView) {
        this.sandbox.emit('viewport:show:header');
        this.sandbox.emit('viewport:show:footer');
      } else {
        this.sandbox.emit('viewport:hide:header');
        this.sandbox.emit('viewport:hide:footer');
      }
    },

    initPage: function () {
      var currentRouteHandler;
      _.each(this.pageRoutePath, function(val, key){
        this.sandbox.router.route(key, val);
        this.sandbox.router.on('route:' + val, this[val], this);
      }, this);

      // this forces router to trigger history event
      currentRouteHandler = this.loadUrl(this.sandbox.router.history.getFragment());
      _.each(this.pageRoutePath, function(val, key){
        if (currentRouteHandler && (this.sandbox.router._routeToRegExp(key).toString() === currentRouteHandler.route.toString())) {
          currentRouteHandler.callback(this.sandbox.router.history.getFragment());
        }
      }, this);
    },

    loadUrl: function(fragmentOverride) {
      var fragment = Backbone.history.getFragment(fragmentOverride);

      return _.find(Backbone.history.handlers, function(handler) {
        return handler.route.test(fragment);
      });
    },

    setCurrentPage: function() {
      $('.viewport-page').hide();
      if(!currentComponent) {
        currentComponent = this;
      } else {
        _.each(currentComponent.sandbox._children, function(child){
          child.stop();
        });
        if(currentComponent.view) {
          currentComponent.view.remove({maintainViewContainer: true});
        }
        currentComponent = this;
      }
    }


  };

  res = _.extend({}, pageConfigurator);
  res = _.extend(res, componentConfig);

  return res;
});
