define('models/enduser-item',[
    'underscore',
    'backbone',
    'core/utils/settings'
], function (_, Backbone, Settings) {
    'use strict';

    var EndUserItem = Backbone.Model.extend({
        idAttribute: 'secId',
        url: function() {
            return Settings.url('compuzz','endusers.json');
        }
    });
    return EndUserItem;
});


