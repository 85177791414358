/**
 * Created by GSP on 24/02/2016.
 */
define('__component__$cart-page@default/views/prices-view',['jquery',
        'backbone',
        'core/utils/settings',
        'utils/price'], function($, Backbone, Settings, PriceUtil){
  'use strict';
  var PriceView = Backbone.View.extend({
    events: {
      'click .cart__continue': 'onCartContinue',
      'click .cart__book': 'onCartBook',
      'click .return-product-list': 'onBackToProductList'
    },

    initialize: function(options) {
      this.parent = options.parent;
      this.render();
    },

    render: function() {
      var vat = PriceUtil.calculateVat(),
      vatP = vat * 100,
      totalCartPriceDutyFree = this.parent.component.totalCartPriceDutyFree,
      shippingCostsCartPriceDutyFree = this.parent.component.shippingCostsCartPriceDutyFree,
      vatAmount = (totalCartPriceDutyFree + shippingCostsCartPriceDutyFree) * vat,
      totalPriceIVAT = (totalCartPriceDutyFree + shippingCostsCartPriceDutyFree) + vatAmount;
      this.$el.html(this.parent.component.renderTemplate('templates/prices-view-tpl', {
        displayTva: Settings.get('token' ).configuration.displayVat,
        displayBookInCart: Settings.get('token' ).configuration.displayBookInCart,
        totalPriceDutyFree: PriceUtil.toFixedPrice( totalCartPriceDutyFree, 2 ).toString().split( '.' ) + ' ' + PriceUtil.getCurrencySymbol( 'EUR' ),
        displayShippingCosts: shippingCostsCartPriceDutyFree > 0,
        shippingCostsCartPriceDutyFree: PriceUtil.toFixedPrice( shippingCostsCartPriceDutyFree, 2 ).toString().split( '.' ) + ' ' + PriceUtil.getCurrencySymbol( 'EUR' ),
        vatAmount: PriceUtil.toFixedPrice( vatAmount, 2 ).toString().split( '.' ) + ' ' + PriceUtil.getCurrencySymbol( 'EUR' ),
        vat: vatP,
        totalPriceIVAT: PriceUtil.toFixedPrice( totalPriceIVAT, 2 ).toString().split( '.' ) + ' ' + PriceUtil.getCurrencySymbol( 'EUR' ),
        displayPrice: Settings.get('token' ).configuration.displayPrice
      }));
    },

    displayCartBook: function() {
      this.$el.find('.cart__book').removeClass('disabled');
    },

    onCartContinue: function() {
      this.parent.component.gotToDelivery();
    },

    onCartBook: function() {
      this.parent.component.book();
    },

    onBackToProductList: function(){
        this.parent.component.goToPersonalize();
    }

  });

  return PriceView;
});
