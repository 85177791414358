/**
 * Created by GSP on 2/02/2016.
 */
/**
 * Created by GSP on 29/01/2016.
 */
define('views/grid-view',['jquery',
        'underscore',
        'backbone',
        'core/utils/settings',
        'utils/date',
        'utils/image',
        'utils/price',
        'jqgrid-w-addons'],
  function($, _, Backbone, Settings, DateUtils, ImageUtils, PriceUtils, JqGrid) {
    'use strict';
    var GridView = Backbone.View.extend({

      GRID_MIN_WIDTH: 800,
      events: {
        'click .ui-search-input select' : 'onGridSelectClick',
        'change .ui-search-input input'  : 'onGridFilterChange',
        'click  .ui-search-clear'  : 'onGridFilterChange'
      },

      initialize: function() {
        JqGrid.translate();
        this.selectDefault = _.i18n('common.all');
        this.pagination = {
          first: 0,
          rowCount: 0,
          pageSize: Settings.get('pageSize'),
          currentPage: 1
        };
        this.dataProcessing = {
          filtering: true,
          sorting: true
        };
      },

      preselectRow: function(e, rowid) {
        this.hidePopover(rowid);
        this.trigger('preselectRow', rowid);
      },

      hidePopover: function(rowid) {
        var $row = this.$('#' + rowid),
          $image = $row.find('.jq-picture');
        $image.popover('hide');
      },

      renderGrid: function(data) {
        data = data || {};
        this.pagination = _.extend(this.pagination, data.pagination);
        this.dataProcessing = _.extend(this.dataProcessing, data.dataProcessing);
        this.responsive = data.responsive;
        if (this.getSelectValues) {
          this.getSelectValues();
        }
        this.$('.grid' ).on('jqGridSelectRow', _.bind(this.preselectRow, this));
        if(this.responsive === true) {
          $(window).on('resize', _.bind(function () {
            this.renderResponsive();
          }, this));
        }
        this.displayGrid(data);
      },


      displayGrid: function(data) {
        var that = this,
          storedFilters = Settings.getFromMemory(this.filtersName),
          urlParams = {
            rowCountUrl: data.rowCountUrl,
            filters:  storedFilters  || this.defaultFilters
          };
        this.rowCountUrl = data.rowCountUrl;
        this.data = data;

        this.data.rowCountRequest(urlParams)
          .done(function(rowCount) {
            //display grid only if you have make a search or if you have records
            that.gridOptionsObj = that.gridOptions(data);
            that.onRowCountDone(data, rowCount);
          });
      },

      renderResponsive: function() {
        var $grid = this.$('.grid' ),
            newWidth = $grid.closest('#main').width();
        if(newWidth > this.GRID_MIN_WIDTH) {
          if(this.$el.hasClass('scroll-x')) {
            this.$el.removeClass('scroll-x');
          }
          newWidth = $grid.closest(this.$el).width();
          $grid.jqGrid('setGridWidth', newWidth-20, true);
        } else {
          if(!this.$el.hasClass('scroll-x')) {
            this.$el.addClass('scroll-x');
          }
          $grid.closest('.ui-jqgrid').parent().width(newWidth);
          $grid.jqGrid('setGridWidth', this.GRID_MIN_WIDTH, true);
        }
      },

      onRowCountDone: function(data, rowCount) {
        var that = this;

        data.rowCount = rowCount;
        this.pagination.rowCount = rowCount;
        this.colModel = that.gridOptionsObj.colModel;
        that.$('.grid' ).jqGrid(that.gridOptionsObj);

        _.each(this.colModel, function(col) {
          that.$('.grid' ).jqGrid('setLabel',col.name,'',{'text-align': col.align});
        });

        if(this.dataProcessing.filtering === true) {
          // activate the toolbar searching
          that.$('.grid' ).jqGrid('filterToolbar', {autosearch: false});
        }
        if(this.dataProcessing.sorting === true) {
          that.$('.ui-jqgrid-sortable').click(function() {
            that.sorting = true;
          });
        }
        _.each(Settings.getFromMemory(this.filtersName), function(value, key) {
          this.$('input[name=' + key + ']').val(value);
        });

        /*jshint camelcase: false */
        this.$('select').material_select();

        var pagerName = that.$('.grid' ).jqGrid('getGridParam', 'pager');
        this.$(pagerName + '_left').css('width', 'auto');
        this.$(pagerName + '_center').css('width', 'auto');
        this.$(pagerName + '_right').css('width', 'auto');

        this.renderResponsive();

      },

      updatePagination: function() {
        var that = this,
          urlParams = {
            rowCountUrl: this.rowCountUrl,
            filters: this.filters
          };
        if (that.$('.grid' )[0].grid) {
          this.data.rowCountRequest(urlParams)
            .done(function(options) {
              if (options === 0) {
                that.trigger('rowCountZero');
              }
              that.pagination.rowCount = options;
              that.$('.grid').setGridParam({
                jsonReader: {
                  root: 'entity',
                  page: function() {
                    return that.pagination.currentPage;
                  },
                  total: function() {
                    return Math.ceil(options.entity/that.pagination.pageSize);
                  },
                  records: function() {
                    return options.entity;
                  }
                }
              });
              if (that.filterChange === true && that.dataProcessing.filtering === true) {
                that.$('.grid')[0].triggerToolbar();
              } else {
                that.$('.grid').trigger('reloadGrid', [{current: that.pagination.currentPage}]);
              }
            });
        } else {
          this.displayGrid(this.data);
        }
      },

      onGridSelectClick: function(e) {
        var target = $(e.currentTarget),
          name = target.attr('name');


        if (!this.selectValue) {
          this.selectValue = {};
          this.selectValue[name] = this.selectDefault;
        }

        if (target.val() !== this.selectValue[name]) {
          this.selectValue[name] = target.val();
          this.onGridFilterChange(e);
        }
      },

      onGridFilterChange: function(e) {
        var that = this,
          filters = this.defaultFilters,
          value;

        this.triggerClear(e);

        _.each(this.colModel, function(column) {
          if (column.search) {
            if (column.stype === 'select') {
              value = that.$('select[name="' + column.index + '"]').val();
              filters[column.index] = value === that.selectDefault ? '' : value;
            } else {
              value = that.$('input[name="' + column.index + '"]').val();
              filters[column.index] = value;
            }
          }
        });

        this.filters = filters;
        this.filterChange = true;
        this.updatePagination();
      },

      gridInitOptions: function(data) {
        var that = this,
          postData;

        this.sidx = this.sidx || '';
        this.sord = this.sord || 'asc';
        this.displayRowToolTip = this.gridOptions.displayRowToolTip || false;
        postData = Settings.getFromMemory( this.filtersName );
        if ( postData ) {
          this.sidx = postData.sidx;
          this.sord = postData.sord;
          this.pagination.currentPage = postData.page;
        }

        return {
          regional: Settings.get( 'lang' ),
          url: data.url,
          mtype: 'GET',
          datatype: 'json',
          colModel: [],
          viewrecords: true,
          autowidth: true,
          height: '100%',
          rowNum: that.pagination.pageSize,
          page: that.pagination.currentPage,
          jsonReader: {
            page: function ( obj ) {
              if ( obj.length === 0 ) {
                data.rowCount = 1;
                return 1;
              }
            },
            total: function () {
              return Math.ceil( data.rowCount / that.pagination.pageSize );
            },
            records: function () {
              return data.rowCount;
            }
          },
          sortname: this.sidx,
          sortorder: this.sord,
          postData: postData || {
            pageSize: that.pagination.pageSize,
            first: that.pagination.first
          },
          pager: that.$('.grid-pager'),

          onPaging: function ( pgButton ) {
            var jqGridPostData = that.$('.grid').getPostData(),
              currentPage = that.pagination.currentPage,
              pageSize = that.pagination.pageSize,
              rowCount = that.pagination.rowCount;

            switch ( pgButton ) {
              case 'first':
                currentPage = 1;
                break;
              case 'next':
                currentPage++;
                break;
              case 'prev':
                currentPage--;
                break;
              case 'last':
                currentPage = Math.ceil( rowCount / pageSize );
                break;
            }

            that.pagination.currentPage = currentPage;
            jqGridPostData.first = ((currentPage - 1) * pageSize);
            that.$('.grid').setPostData( jqGridPostData );
          },

          serializeGridData: function ( postData ) {
            var currentPage = Math.ceil( that.pagination.rowCount / that.pagination.pageSize );
            if ( that.filterChange === true ) {
              postData.first = 0;
              that.pagination.currentPage = 1;
              postData.page = 1;
              that.filterChange = false;
            } else if ( that.pagination.currentPage > currentPage ) {
              that.pagination.currentPage = currentPage ? currentPage : 1;
              postData.page = that.pagination.currentPage;
              postData.first = ((that.pagination.currentPage - 1) * that.pagination.pageSize);
            }

            if ( that.sidx !== postData.sidx || that.sord !== postData.sord ) {
              postData.first = 0;
              that.pagination.currentPage = 1;
              postData.page = 1;
            }

            that.sidx = postData.sidx;
            that.sord = postData.sord;

            Settings.setToMemory( that.filtersName, postData );

            return postData;
          },

          gridComplete: function() {
            that.onGridComplete();
            that.attachPopover();
            if (that.displayRowToolTip === true) {
              that.attachTooltip();
            }
          },

          loadComplete: function(data) {
            that.trigger('loadComplete', data);
          }
        };
      },

      defaultFormatter: function(cellValue, options) {
        if (cellValue === null || cellValue === undefined) { cellValue = ''; }

        return '<span class="cell-default" data-row-id="' +
               options.rowId + '">' + cellValue + '</span>';
      },


      deleteIconFormatter: function(cellValue, options) {
        return '<span class="cell-delete js-icon" ' +
               'data-row-id="' + options.rowId + '"><i class="material-icons">delete</i></span>';
      },

      editIconFormatter: function(cellValue, options) {
        return '<span class="cell-edit" ' +
               'data-row-id="' + options.rowId + '"><i class="material-icons">mode_edit</i></span>';
      },

      dateFormatter: function(cellValue) {
        var date = DateUtils.toDateFormatString(cellValue);

        return '<span class="cell-date">'+ date + '</span>';
      },

      priceFormatter: function(cellValue, options) {
        if (cellValue === null || cellValue === undefined) { cellValue = ''; }

        cellValue = PriceUtils.toFixedPrice(cellValue, 2).toString().split('.');
        cellValue += ' ' + PriceUtils.getCurrencySymbol('EUR');

        return '<span class="cell-price-default" data-row-id="' +
               options.rowId + '">' + cellValue + '</span>';
      },

      loaderFormatter: function(className, options) {
        return '<span class="' + className + '"  data-row-id="' + options.rowId + '"><div class="preloader-wrapper small active">  ' +
               '<div class="spinner-layer small spinner-blue-only">' +
               '<div class="circle-clipper left">' +
               '<div class="circle"></div> ' + '</div>' +
               '<div class="gap-patch">' +
               '<div class="circle"></div>' +
               '</div>' +
               '<div class="circle-clipper right">       ' +
               '<div class="circle"></div>      ' +
               '</div>    ' +
               '</div>  </div></span>';
      },

      localeDateFormatter: function(cellValue) {
        var date = DateUtils.toDateFormatString(cellValue);

        return '<span class="cell-date">'+ date + '</span>';
      },

      pictureUidFormatter:  function(cellValue, colModel, row) {
        var pay = row.itemKey ? row.itemKey.substring(0, 3) : null;

        if (pay === 'PAY') {
          return '<div class="jq-picture pay-pal-logo"></div>';
        } else {
          cellValue = GridView.prototype.getImageCellValue.apply(GridView, arguments);
          return '<span><img data-link="' + cellValue + '"' +
                 'class="jq-picture js-image-cell" src="' + cellValue + '"/></span>';
        }
      },

      pictureUidFormatterTime:  function(cellValue) {
        cellValue = GridView.prototype.getImageCellValueTime.apply(GridView, arguments);
          return '<span><img data-link="' + cellValue + '"' +
            'class="jq-picture js-image-cell" src="' + cellValue + '"/></span>';
      },

      attachPopover: function() {
        var that = this,
          images = this.$('.js-image-cell'),
          link;

        _.each(images, function(image) {
          link = $(image).data('link');
          that.showImageZoomPopover(link, $(image));
        });
      },

      attachTooltip: function() {
        this.$('td[role=gridcell]:not(.image-cell)').tooltip({
          track: true,
          content: function() {
            return _.i18n('common.click.here.for.detail');
          }
        } );
      },

      onGridComplete: function() {
        var that = this;
        if (this.$('.jq-picture').length > 0) {
          this.$('.jq-picture').error(function(e) {
            var target = $(e.currentTarget),
              fileLink = that.checkFileName(target) || Settings.get('notFoundImage');
            target.attr('src', fileLink);
            target.popover('destroy');
          });
        }
      },

      checkFileName: function(target) {
        var name = target.data('name'),
          nameAsArray,
          ext;
        if (!name) return;
        nameAsArray = name.split('.');
        if (nameAsArray.length > 1) {
          ext = nameAsArray[1].substr(0,4);
        } else {
          ext = name.substr(0,3);
        }
        return ImageUtils.getPreview(ext);
      },

      showImageZoomPopover: function(fileLink, target) {
        var url = Settings.url('base', fileLink),
          content = '<div class="img-responsive">' +
                    '<img class="img-popover" src="' + url + '"/></div>';

        target.attr('data-content', content);
        target.popover({
          trigger: 'hover',
          placement: 'right',
          html: 'true',
          container: this.imagePopoverContainer ? this.imagePopoverContainer : $('#main')
        }).data('bs.popover').tip().addClass('jqgrid-popover');
      },

      getImageCellValue: function(cellValue) {
        if (!cellValue) {
          return Settings.get('notFoundImage');
        } else {
          return Settings.url('file', cellValue, {size: 'thumbnail'});
        }
      },

      getImageCellValueTime: function(cellValue) {
        if (!cellValue) {
          return Settings.get('notFoundImage');
        } else {
          return Settings.url('file', cellValue, {size: 'thumbnail', d: new Date().getTime()});
        }
      },

      remove: function() {
        if (this.$('td[role=gridcell]:not(.image-cell)').length > 0 && this.displayRowToolTip === true) {
          this.$('td[role=gridcell]:not(.image-cell)').tooltip('destroy');
        }
        if(this.responsive === true) {
          $(window).off('resize');
        }
        $.jgrid.gridDestroy(this.$('.grid').attr('id'));
        Backbone.View.prototype.remove.apply(this, arguments);
      }
    });

    return GridView;
  });
