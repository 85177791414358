define('config/env-handler',[
  'module',
  'jquery',
  'config/env'
], function (module, $, Environment) {
  'use strict';
  
  var EnvHandler = {
    getValue: function (env, key) {
      if (!env || env === ' ') {
        switch (this._getHostName()) {
        case 'localhost':
        case '127.0.0.1':
          if (this._getHref().indexOf('dist') !== -1) {
            env = 'minified';
          } else {
            env = 'defaults';
          }
          break;
        case 'alpha.compuzz.com':
          env = 'alpha';
          break;
        case 'beta.compuzz.com':
          env = 'beta';
          break;
        default:
          throw('Unknown environment: ' + this._getHostName());
        }
      }
      return Environment.config[env][key];
    },

    _getHostName: function() {
      return window.location.hostname;
    },

    _getHref: function() {
      return window.location.href;
    }
  };

  module.exports = EnvHandler;
});

