/**
 * Created by GSP on 2/02/2016.
 */
/**
 * Created by GSP on 29/01/2016.
 */
define('__component__$paging@default/views/paging-view',['jquery',
        'underscore',
        'backbone'],
  function($, _) {
    'use strict';
    var PagingView = {

      events: {
        'click .pages a.more': 'showMore'
      },

      showMore: function (e) {
        e.stopImmediatePropagation();
        var $target = $(e.currentTarget),
        $targetPagingContainer = $target.closest('#paging-container');
        if ($targetPagingContainer.hasClass('prevent-paging')) {
          return;
        }

        if ($target.hasClass('move-to-top')) {
          $('body').animate({scrollTop:0}, 'slow');

          return;
        }
        this.component.pagination.currentPage = this.component.pagination.currentPage + 1;

        $targetPagingContainer.addClass('prevent-paging');
        //Caught by the owner of the paging component
        this.component.mediator.emit('paging:page-changed', this.component.pagination.currentPage);
        this.updatePages();
      },

      updatePages: function () {
        var lastPage = Math.ceil(this.component.pagination.rowCount / this.component.pagination.pageSize),
          $showMoreButton = this.$el.find('.pages .more'),
          totalPages = Math.ceil(this.component.pagination.rowCount / this.component.pagination.pageSize);

        this.$el.find('.pages').css('display', 'inline');

        if(!totalPages || totalPages === 1 || this.component.pagination.currentPage === lastPage) {
          $showMoreButton.addClass('move-to-top').html('<span class="vt-align">'+ _.i18n('common.top') + '</span><i  class="material-icons vt-align">arrow_upward</i>');
        } else {
            $showMoreButton.replaceWith('<a class="more waves-effect waves-teal btn-flat vt-align" data-page="more">'+ _.i18n('common.more') + '</a>');
        }
      }
    };

    return PagingView;
  });
