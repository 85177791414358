define('extensions/helpers',{
    initialize: function (app) {
        var loadedCssFiles = {};

        function loadCssForModule (module) {
            if(module){
                var moduleUri = module.uri,
                    moduleUriParts,
                    folderUri,
                    cssFileUri;

                moduleUriParts = moduleUri.split('/');
                moduleUriParts = _.filter(moduleUriParts, function (item) { return item !== '.'; });
                moduleUriParts.pop();
                folderUri = moduleUriParts.join('/');
                cssFileUri = folderUri + '/main.css';

                if (loadedCssFiles[cssFileUri]) {
                    return;
                }
                loadedCssFiles[cssFileUri] = true;

                var link = document.createElement("link");
                link.type = "text/css";
                link.rel = "stylesheet";
                link.href = cssFileUri;
                document.getElementsByTagName("head")[0].appendChild(link);
            }
        }

		function clone(srcInstance) {
			/*Si l'instance source n'est pas un objet ou qu'elle ne vaut rien c'est une feuille donc on la retourne*/
			if ( typeof (srcInstance) != 'object' || srcInstance == null) {
				return srcInstance;
			}
			/*On appel le constructeur de l'instance source pour crée une nouvelle instance de la même classe*/
			var newInstance = srcInstance.constructor();
			/*On parcourt les propriétés de l'objet et on les recopies dans la nouvelle instance*/
			for (var i in srcInstance) {
				newInstance[i] = clone(srcInstance[i]);
			}
			/*On retourne la nouvelle instance*/
			return newInstance;
		}

        app.sandbox.helpers = {
            loadCssForModule: loadCssForModule,
            clone: clone
        };

    }
});
