define('core/utils/i18n',[
    'underscore',
    'jquery',
    'core/utils/settings',
    'polyglot',
    'utils/url',
], function (_, $, Settings, Polyglot, UrlUtil) {
    if (!window.App.polyglot)
      window.App.polyglot = new Polyglot();

    function setTranslation (tObject) {
        var obj = {}, code;

        //Remove the application name from all keys, to be able to use them without writting the application name in all keys
        if(tObject.code.substring(Settings.get('application').length + 1)){
            code = tObject.code.substring(Settings.get('application').length + 1);
        }
        else{
            code = tObject.code;
        }

        obj[code] = tObject.text.localized[Settings.get('lang')] || tObject.text.localized[Settings.get('defaultLang')];

      window.App.polyglot.extend(obj);
    }
    _.i18n = _.bind(window.App.polyglot.t, window.App.polyglot );


    var i18n = {};
    i18n.getApplicationTranslations = function (addToken) {
      window.App.polyglot.prefix = Settings.get('application');
      var queryParams = UrlUtil.parseQueryString();
      if (queryParams && queryParams.hasOwnProperty('lang')) {
        Settings.set('lang', queryParams.lang);
      }
        return $.ajax({
            url: Settings.url('compuzz', 'v2/texts/app/' + Settings.get('application'), null, !addToken),
            success: function(result) {
                _.each(result, setTranslation);
            }
        });
    };

    // use _.i18n function in your templates: <%= _.i18n("dashboard.helloWorld") %>, <%= _.i18n("dashboard.compuzz") %>
    return i18n;
});
