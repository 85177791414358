define('__component__$left-menu@default/views/single-filter',[
    'backbone',
    'underscore',
    'jquery',
    'core/utils/settings',
    'utils/color',
    'text!../templates/single-filter.html'
], function(Backbone, _, $, settings, Color, template) {
    'use strict';

    var FiltersView = Backbone.View.extend({
    	template: _.template(template),

        events: {
            'click .filter-option-checkbox' : 'filterOptionChanged',
            'click .show-more-options' : 'showAllOptions'
        },
        
    	initialize: function (options) {
    		this.options = options;
        this.personal = this.options.personal;
    		this.render();
    	},

    	render: function() {
            var templateData = this.options.filter;
    		this.$el.append(this.template( _.extend(templateData, {decimalToHex: Color.decimalToHex})));
            if (templateData.productFilterValues.length > 5) {
                this.$el.find('.show-more-options').css('display', 'table-row');
                _.each(this.$el.find('.filter-option'), function (element, index) {
                    if (index > 4) {
                        $(element).hide();
                    }
                });
            }
    	},

        showAllOptions: function () {
            this.$el.find('.show-more-options').hide();
            _.each(this.$el.find('.filter-option'), function (element, index) {
                if (index > 4) {
                    $(element).show();
                }
            });
        },

        filterOptionChanged: function(e) {
            var target = $(e.currentTarget),
                checked = target.is(':checked'),
                optionId = target.val();
            
            if (checked) {
                this.personal.push(optionId);
                target.parent().addClass('checked');
            } else {
                var index = this.personal.indexOf(optionId);
                this.personal.splice(index, 1);
                target.parent().removeClass('checked');
            }
            this.options.sandbox.emit('filters:option-changed');
        }

    });

    return FiltersView;
});
