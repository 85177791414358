/**
 * Created by GSP on 24/07/2015.
 */
define('config/protocol',[
  'module'
], function(module) {
  'use strict';
  
  var Protocol = {
    apply: function(url) {
      if (document.location.protocol === 'https:') {
        return url.replace('http:', 'https:');
      }
      return url;
    }
  };
  module.exports = Protocol;
});
